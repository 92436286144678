// import {inject} from 'aurelia-framework';
// import {SoundService} from '../../common/services/sound-service';

import { QuizItemBase } from '../quiz-item-base';

// @inject(SoundService)
export class MultipleChoiceTest extends QuizItemBase {
    autoCommit = true; // Since we are dealing with multiple choices where only 1 is correct.
    status = ''; // correct | wrong | timeout

    // constructor(soundService, ...rest) {
    //   super(...rest);
    // }

    selectionChanged() {
        if (this.model.choices.find(c => c.selected && c.isCorrect)) {
            this.model.score = 1;
        } else {
            this.model.score = 0;
        }

        if (this.autoCommit) {
            this.itemSession.commit();
        }
    }

    showFeedback() {
        return this.interactionVm.showFeedback(this.itemSession);
    }

    animateEnterInteraction() {
        return this.interactionVm.animateEnter();
    }

    animateLeaveInteraction() {
        return this.interactionVm.animateLeave();
    }
}
