import { inject } from 'aurelia-framework';
import { getLogger } from 'aurelia-logging';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';

import { RepetitionCreatedOrUpdatedEvent } from '../events';
import store from '@/store';
const logger = getLogger('repetition-service');

@inject(EventAggregator, BindingSignaler)
export class RepetitionService {
    pollInterval = 600 * 1000;
    repetitions = new Map();
    _didInitialLoad = false;

    constructor(ea, signaler) {
        this.ea = ea;
        this.signaler = signaler;

        setInterval(this.poll.bind(this), this.pollInterval);
        setInterval(() => {
            this._levelJustChangedOnRepetition = false;
            this.repetitions.forEach(repetition => this._updateRepetitionState(repetition));
            this.signaler.signal('repetition-clock');
            if (this._levelJustChangedOnRepetition) {
                this.ea.publish('repetitionService:levelJustChanged');
            }
        }, 1000 * 60);

        this.initialLoadPromise = new Promise(resolve => (this.resolveInitialLoadPromise = resolve));
    }

    ensureInitialLoad(bundleName) {
        if (!this._didInitialLoad) {
            this.poll();
        }
        return this.initialLoadPromise;
    }

    createOrUpdateRepetition(context, repetitionId, repetitionName, limit, creationDate, link) {
        return this.ensureInitialLoad().then(() => {
            let localRepetition = this.findRepetition(context, repetitionId);

            if (localRepetition && localRepetition.level !== -1) {
                return this.updateRepetition(
                    localRepetition.id,
                    localRepetition.app,
                    localRepetition.list_id,
                    repetitionName
                );
            } else {
                return this.createRepetition(context, repetitionId, repetitionName, limit, creationDate, link);
            }
        });
    }

    poll() {
        logger.info('Polling for repetitions');
        return store.dispatch('moduleMemolanguage/getRepetitions').then(repetitions => {
            this._updateRepetitions(repetitions);
            this._didInitialLoad = true;
            this.resolveInitialLoadPromise(); // NOTE has no effect after the first time it is resolved
            this.ea.publish(new RepetitionCreatedOrUpdatedEvent(repetitions, false));
            return repetitions;
        });
    }

    _updateRepetitionState(repetition, sendSignal) {
        if (repetition.level !== -1) {
            let didMarkActive = false;
            repetition.repeats.forEach((repeat, repeatIndex) => {
                if (repeat.done) {
                    repeat.level = 0;
                    delete repeat.active;
                } else {
                    if (didMarkActive) {
                        repeat.level = 0;
                        delete repeat.active;
                    } else {
                        // First not-done repeat will be marked as active
                        repeat.active = true;
                        didMarkActive = true;

                        let dueTime = new Date(repeat.when);
                        let now = Date.now();

                        if (now < dueTime) {
                            repeat.level = 0;
                            repetition.fractionBeforeDue = 1 - (dueTime - now) / 86400000;
                        } else {
                            repeat.level = Math.min(2, 1 + Math.floor((now - dueTime) / 86400000)); // one day in ms
                            repetition.fractionBeforeDue = 0;
                        }

                        repetition.step = repeatIndex;
                        if (repetition.level !== repeat.level) {
                            this._levelJustChangedOnRepetition = true;
                        }
                        repetition.level = repeat.level;
                        repetition.when = repeat.when;
                        repetition.active = repeat.active;
                    }
                }
            });
            if (isNaN(parseInt(repetition.level, 10))) {
                // Possible when all repeats are done. level still a color string
                repetition.level = 0;
            }
            if (repetition.step === undefined) {
                repetition.step = -1;
            }

            if (sendSignal) {
                this.signaler.signal('repetition-clock');
            }
        }

        return repetition;
    }

    _updateRepetitions(remoteRepetitions) {
        remoteRepetitions.forEach(remoteRepetition => {
            let localRepetition = this.repetitions.get(`${remoteRepetition.app}:${remoteRepetition.list_id}`);
            if (localRepetition) {
                Object.assign(localRepetition, remoteRepetition);
                this._updateRepetitionState(localRepetition);
            } else {
                this._updateRepetitionState(remoteRepetition);
                this.repetitions.set(`${remoteRepetition.app}:${remoteRepetition.list_id}`, remoteRepetition);
            }
        });
        this.signaler.signal('repetition-clock');
    }

    createRepetition(app, listId, name, limit, creationDate, link) {
        logger.info('Creating repetition', app, listId, name, limit, creationDate, link);
        let data = {
            app: app,
            list_id: listId,
            name: name
        };
        if (limit) {
            data.limit = limit;
        }
        if (creationDate) {
            data.created = new Date(creationDate).toISOString();
        }
        if (link) {
            data.url = link;
        }

        return store.dispatch('moduleMemolanguage/createRepetition', { data: data }).then(remoteRepetition => {
            logger.info('Repetition created');
            let localRepetition = this.repetitions.get(`${remoteRepetition.app}:${remoteRepetition.list_id}`);
            if (localRepetition) {
                // Probably just a placeholder
                Object.assign(localRepetition, remoteRepetition);
            } else {
                localRepetition = remoteRepetition;
                this.repetitions.set(`${remoteRepetition.app}:${remoteRepetition.list_id}`, localRepetition);
            }
            this._updateRepetitionState(localRepetition, true);
            this.ea.publish(new RepetitionCreatedOrUpdatedEvent([localRepetition], true));
        });
        // return this.repClient.fetch('/repetitions/', {method: 'post', body: json(data)})
        // .then(response => {
        //   if (response.ok) {
        //     return response.json().then(remoteRepetition => {
        //       logger.info('Repetition created');
        //       let localRepetition = this.repetitions.get(`${remoteRepetition.app}:${remoteRepetition.list_id}`);
        //       if (localRepetition) { // Probably just a placeholder
        //         Object.assign(localRepetition, remoteRepetition);
        //       } else {
        //         localRepetition = remoteRepetition;
        //         this.repetitions.set(`${remoteRepetition.app}:${remoteRepetition.list_id}`, localRepetition);
        //       }
        //       this._updateRepetitionState(localRepetition, true);
        //       this.ea.publish(new RepetitionCreatedOrUpdatedEvent([localRepetition], true));
        //     });
        //   } else if (response.status === 400) {
        //     // The repetition probably exists serverside allready!
        //     return this.poll();
        //   }
        // });
    }

    updateRepetition(repetitionId, app, listId, name) {
        let data = {
            app: app,
            list_id: listId,
            name: name
        };
        return store
            .dispatch('moduleMemolanguage/updateRepetition', { id: repetitionId, data: data })
            .then(remoteRepetition => {
                logger.info('Repetition updated', repetitionId);
                let localRepetition = this.findRepetition(app, listId);
                store.commit('repetition/update', remoteRepetition);
                if (localRepetition) {
                    Object.assign(localRepetition, remoteRepetition);
                    this._updateRepetitionState(localRepetition, true);
                    this.ea.publish(new RepetitionCreatedOrUpdatedEvent([localRepetition], false));
                }
            });
    }

    findRepetition(app, listId) {
        let repetition = this.repetitions.get(`${app}:${listId}`);
        if (repetition && repetition.level === -1) {
            repetition = null;
        }
        return repetition;
    }

    getRepetition(app, listId) {
        let repetition = this.repetitions.get(`${app}:${listId}`);
        if (!repetition) {
            repetition = {
                level: -1
            };
            this.repetitions.set(`${app}:${listId}`, repetition);
        }
        return repetition;
    }

    getRepetitions(app, availableListTest) {
        if (app) {
            return Array.from(this.repetitions.values()).filter(rep => {
                if (rep.app === app) {
                    return availableListTest ? availableListTest(rep.list_id) : true;
                }
                return false;
            });
        } else {
            return Array.from(this.repetitions.values());
        }
    }

    repetitionIsDue(repetition) {
        // date is today or in the past
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let repeat = repetition.repeats.find(rep => {
            let repDate = new Date(rep.when);
            repDate.setHours(0, 0, 0, 0);
            return today >= repDate;
        });
        if (repeat) {
            return true;
        }
        return false;
    }
}
