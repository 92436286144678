import { bindable, bindingMode, inject } from 'aurelia-framework';

@inject(Element)
export class CarouselNavWithLabels {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) activeItemIndex;
    @bindable options = { centeredActiveItem: true };

    constructor(element) {
        this.element = element;
    }

    activeItemIndexChanged(newActiveItemIndex, oldActiveItemIndex) {
        if (oldActiveItemIndex === null) {
            return;
        }

        this.itemsContainerElem.children[oldActiveItemIndex].classList.remove('is-active');
        this._update();
    }

    _update() {
        let itemElem = this.itemsContainerElem.children[this.activeItemIndex];
        if (!itemElem) {
            return; // NOTE: can happen if we have no children
        }
        let itemsContainerWidth = this.itemsContainerElem.clientWidth;
        let itemWidth = itemElem.getBoundingClientRect().width;
        let itemLocalX = itemElem.getBoundingClientRect().left - this.itemsContainerElem.getBoundingClientRect().left;

        itemElem.classList.add('is-active');
        this.activeItemIndicatorElem.style.width = `${(itemWidth / itemsContainerWidth) * 100}%`;

        if (this.options.centeredActiveItem) {
            this.itemsContainerElem.style.transform = `translate3d(${
                50 - ((itemLocalX + itemWidth / 2) / itemsContainerWidth) * 100
            }%, 0, 0)`;
        } else {
            this.activeItemIndicatorElem.style.left = `${((itemLocalX + itemWidth / 2) / itemsContainerWidth) * 100}%`;
        }
    }

    attached() {
        this.element.onclick = event => {
            //let itemIndex = [...this.itemsContainerElem.children].indexOf(event.target); // Does not work. Maybe upgrade babel?
            let itemIndex = Array.from(this.itemsContainerElem.children).indexOf(event.target);
            if (itemIndex !== -1) {
                this.activeItemIndex = itemIndex;
            }
        };

        this.element.classList.add('state-initialDisplay');
        this._update();
        setTimeout(() => {
            this.element.classList.remove('state-initialDisplay');
        }, 500);
    }

    detached() {}
}
