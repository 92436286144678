/* global PLATFORM */
import { inject } from 'aurelia-framework';
import { Router, Redirect } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { DialogService } from 'aurelia-dialog';

import { ListCompletedPopup } from '../modals/list-completed-popup';
import { QuizResultPopup } from '../modals/quiz-result-popup';
import { UnlockedPhrasesPopup } from '../modals/unlocked-phrases-popup';

import { DebugService } from '../services/debug-service';
import { RepetitionService } from '../services/repetition-service';
import { SidebarService } from '../services/sidebar-service';
import { TooltipService } from '../services/tooltip-service';

import { DataService } from '../services/data-service';
import { Auth } from '../auth';

@inject(DataService, Router, DebugService, RepetitionService, DialogService, SidebarService, TooltipService, I18N, Auth)
export class ListView {
    hidden = false;
    list = null;
    activeTabIndex = 0;

    processingPrimaryAction = false; // Used for giving visual feedback on the clicked button
    processingSecondaryAction = false;

    constructor(
        dataService,
        router,
        debugService,
        repetitionService,
        dialogService,
        sidebarService,
        tooltipService,
        i18n,
        auth
    ) {
        this.dataService = dataService;
        this.router = router;
        this.debugService = debugService;
        this.repetitionService = repetitionService;
        this.dialogService = dialogService;
        this.sidebarService = sidebarService;
        this.tooltipService = tooltipService;
        this.i18n = i18n;
        this.auth = auth;
    }

    canActivate(params, routeConfig, navigationInstruction) {
        return this.dataService
            .ensureLanguageLoaded()
            .then(() => this.dataService.getList(params.listId))
            .then(list => {
                if (!list) {
                    return new Redirect(`${this.router.baseUrl}not-found`);
                } else {
                    return true;
                }
            });
    }

    activate(params) {
        this.listId = params.listId;
        this._registerDebugCommands();

        return this.repetitionService
            .ensureInitialLoad()
            .then(() => this.dataService.getList(this.listId))
            .then(list => {
                this.list = list;

                if (this.list.onboarding) {
                    // NOTE: set to true for onboarding list in onboarding-view
                    delete this.list.onboarding;
                }

                let quizState = this.list.getQuizState();

                this.repetitionPhase = ['none', 'pending', 'due', 'due'][this.list.repetition.level + 1];
                this.quizEvaluation = quizState.evaluation;
                if (this.repetitionPhase === 'none' && this.quizEvaluation === 'clean' && !this.list.allItemsSeen) {
                    this.quizEvaluation = 'initial';
                }

                if (this.repetitionPhase === 'pending' && quizState.completionFraction === 1 && quizState.justUpdated) {
                    this.quizEvaluation = 'justCompleted';
                }

                if (this.list.itemsOfType === 'phrase') {
                    this.includedWordsCount = 0;
                    this.knownWordsCount = 0;
                    let start = Date.now();
                    this.list.getIncludedWords().then(words => {
                        this.includedWordsCount = words.length;
                        this.knownWordsCount = words.filter(w => w.learnStatus === 1).length;
                        if (Date.now() - start > 300) {
                            this.animateWordsInPhrasesInfo = true;
                        }
                    });
                }
            });
    }

    _registerDebugCommands() {
        this.debugService.add(
            'createOrUpdateRepetition',
            () => {
                this.list.ensureItemsLoaded().then(() => {
                    this.list.items.forEach(targetItem => {
                        targetItem.markAsLearned();
                    });
                });
                this.repetitionService.createOrUpdateRepetition(
                    this.dataService.activePackageName,
                    this.listId,
                    this.list.name
                );
            },
            this
        );

        this.debugService.add(
            'createOrUpdateRepetition (not mark learned)',
            () => {
                this.repetitionService.createOrUpdateRepetition(
                    this.dataService.activePackageName,
                    this.listId,
                    this.list.name
                );
            },
            this
        );

        this.debugService.add(
            'showListCompletedPopup',
            () => {
                this.showListCompletedPopup();
            },
            this
        );

        this.debugService.add(
            'showQuizResultPopup',
            () => {
                this.hidden = true;
                this.dialogService.open({ viewModel: QuizResultPopup, model: this.list }).whenClosed(response => {
                    this.hidden = false;
                });
            },
            this
        );

        this.debugService.add(
            'showTooltip',
            () => {
                this.showTooltip();
            },
            this
        );

        this.debugService.add(
            'showUnlockedPhrasesPopup',
            () => {
                this.dataService._newlyUnlockedPhraseIds = new Set(this.dataService._unlockedPhraseIds);
                this.dataService._unlockedPhraseIdsSnapshot = new Set();
                this.showUnlockedPhrasesPopup();
            },
            this
        );

        this.debugService.add(
            'dataToConsole',
            () => {
                this.list.ensureItemsLoaded().then(() => {
                    let fragments = [];
                    fragments.push('--------------------------------');
                    fragments.push(`[${this.list.id}] "${this.list.name}"`);
                    fragments.push('\n');
                    this.list.items.forEach(targetItem => {
                        fragments.push(
                            `[${targetItem.id}] "${targetItem.title}" : [${targetItem.translations
                                .map(t => t.id)
                                .join(',')}] "${targetItem.translationString}"`
                        );
                    });
                    fragments.push('--------------------------------');
                    console.log(fragments.join('\n')); // eslint-disable-line no-console
                });
            },
            this
        );
    }

    deactivate() {
        this.debugService.remove(this);
        this.tooltipService.clear();
    }

    attached() {
        let quizState = this.list.getQuizState();

        // Shows completion popup if applicable:
        if (quizState.firstTimeCompleted) {
            quizState.firstTimeCompleted = false;
            if (!this.dataService.memolanguageSettings.completedTooltips.repetitionTooltip) {
                this.showTooltip();
            } else if (this.list.itemsOfType === 'word') {
                // NOTE: To simplify, we do not show unlocked phrases if showTooltip() is applicable
                this.showUnlockedPhrasesPopup();
            }
        }
        quizState.justUpdated = false;
    }

    showTooltip() {
        this.tooltipService.add({
            focusDelay: 1000,
            title: 'Husk for alltid',
            text: 'Bli påminnet på å repetere før hjernen statistisk sett begynner å glemme.',
            target: '.memolanguage-ListView-LearningProgress',
            ignoreDimming: ['.memolanguage-ListView-LearningProgress'],
            actions: [
                {
                    type: 'next',
                    pulse: true,
                    text: this.i18n.tr('onboardingTooltips.word3.tooltip1Action1'),
                    icon: 'arrow-right',
                    placement: {
                        target: '.memolanguage-ListView',
                        css: 'position: absolute; bottom: 2em; right: 2em;'
                    },
                    onHide: () => {
                        this.dataService.memolanguageSettings.markTooltipAsSeen('repetitionTooltip');
                    }
                }
            ]
        });
        this.tooltipService.start();
    }

    showListCompletedPopup() {
        this.hidden = true;
        return this.dialogService
            .open({ viewModel: ListCompletedPopup, model: this.list })
            .whenClosed(response => {
                if (!response.wasCancelled && response.output === 'upgraded') {
                    //
                }
            })
            .then(() => {
                this.hidden = false;
            });
    }

    showUnlockedPhrasesPopup() {
        this.dataService.getNewlyUnlockedPhrases(3).then(newlyUnlockedPhrases => {
            if (newlyUnlockedPhrases.length) {
                this.hideBottomPart = true;
                this.dialogService
                    .open({ viewModel: UnlockedPhrasesPopup, model: newlyUnlockedPhrases })
                    .whenClosed(response => {
                        if (!response.wasCancelled) {
                            let phrase = response.output;
                            let currentInstruction = this.router.currentInstruction;
                            let list = phrase.lists[0];
                            this.dataService
                                .createTempList('temp', {
                                    items: [phrase],
                                    image: list.image,
                                    source: { name: currentInstruction.config.name, params: currentInstruction.params },
                                    requestUpgradeOnCompletion: true
                                })
                                .then(customList => {
                                    return this.router.navigateToRoute('memorize-loading', { listId: customList.id });
                                });
                        } else {
                            this.hideBottomPart = false;
                        }
                    });
            }
        });
    }

    showSidebar() {
        let viewModelPath = {
            word: PLATFORM.moduleName('../components/word-list-sidebar'),
            phrase: PLATFORM.moduleName('../components/phrase-list-sidebar'),
            pronunciation: PLATFORM.moduleName('../components/pronunciation-list-sidebar')
        }[this.list.itemsOfType];

        if (viewModelPath) {
            this.sidebarService
                .open({ viewModel: viewModelPath, model: this.list, placement: 'right' })
                .then(response => {
                    if (!response.wasCancelled) {
                        //
                    }
                });
        }
    }

    doPrimaryAction() {
        this._doAction('primary');
    }

    doSecondaryAction() {
        this._doAction('secondary');
    }

    _doAction(actionType) {
        actionType === 'primary' ? (this.processingPrimaryAction = true) : (this.processingSecondaryAction = true);
        let key = `${this.repetitionPhase}:${this.quizEvaluation}`;
        const quizCondition = new Set(['none:clean', 'none:good', 'pending:good', 'due:clean', 'due:good']);

        if (this.quizEvaluation === 'justCompleted') {
            if (actionType === 'primary') {
                this.navigateBack();
            } else {
                this.startLearning();
            }
        } else if (
            (quizCondition.has(key) && actionType === 'primary') ||
            (!quizCondition.has(key) && actionType === 'secondary')
        ) {
            this.startQuiz();
        } else {
            this.startLearning();
        }
    }

    navigateBack() {
        return this.router.navigateToRoute('category', { categoryId: this.list.parent.id });
    }

    startQuiz() {
        return this.router.navigateToRoute('quiz-loading', { listId: this.list.id });
    }

    startLearning() {
        return this.router.navigateToRoute('memorize-loading', { listId: this.listId });
    }
}

export class XxxValueConverter {
    toView(value) {
        const timeTable = ['24H', '7D', '1M', '3M', '6M', '1Y', '2Y'];
        if (!value) {
            value = [{}, {}, {}, {}, {}, {}, {}];
        }
        return value.map((rep, i) => {
            let level = -1;
            if (rep.done) {
                level = 0;
            }
            if (rep.active) {
                level = rep.level;
            }

            return {
                level: level,
                active: rep.active,
                done: rep.done,
                time: timeTable[i]
            };
        });
    }
}
