export class CategoryModel {
    static idPrefix = 'c-';
    isLoaded = true;
    learnedItemCount = 0;
    parent = null; // NOTE: Populated from parent model constructor

    constructor(services, fixture) {
        this.services = services;
        Object.assign(this, fixture);

        this.children.forEach(child => (child.parent = this));

        this.itemCount = this._getAllUniqueItems().size;
    }

    get prefixedId() {
        return `c-${this.id}`;
    }

    isFinished() {
        return this.learnedItemCount === this.itemCount || this.children.length === this.finishedListsCount;
    }

    get finishedListsCount() {
        return this.children.filter(child => child.isFinished()).length;
    }

    updateLearnedItemCount() {
        if (this.parent) {
            this.parent.updateLearnedItemCount();
        }
        clearTimeout(this._calcLearnedItemCountTimeoutId);
        this._calcLearnedItemCountTimeoutId = setTimeout(() => this._calcLearnedItemCount(), 1);
    }

    _calcLearnedItemCount() {
        this.learnedItemCount = Array.from(this._getAllUniqueItems()).filter(item => item.learnStatus === 1).length;
    }

    _getAllUniqueItems() {
        let uniqueItems = new Set();
        let _xxx = cat => {
            if (cat.items) {
                cat.items.forEach(item => {
                    uniqueItems.add(item);
                });
            }
            if (cat.children) {
                cat.children.forEach(child => {
                    _xxx(child);
                });
            }
        };
        _xxx(this);

        return uniqueItems;
    }

    get fractionCompleted() {
        return this.finishedListsCount / this.children.length;
    }

    set activeChildIndex(value) {
        // NOTE: should consider to save in pouch (in proper language-pair-context)
        sessionStorage.setItem(`${this.services.dataService.activePackageName}-${this.id}`, value);
    }

    get activeChildIndex() {
        return parseInt(sessionStorage.getItem(`${this.services.dataService.activePackageName}-${this.id}`) || 0, 10);
    }
}
