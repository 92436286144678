import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Auth } from '../auth';

import { RouteTransitionHelper } from '../route-transition-helper';
import { RepetitionService } from '../services/repetition-service';
import { SidebarService } from '../services/sidebar-service';

import { RepetitionCreatedOrUpdatedEvent } from '../events';
import { DataService } from '../services/data-service';

import { SpacedRepetitionPopup } from '../modals/spaced-repetition-popup';

@inject(
    Router,
    SidebarService,
    RouteTransitionHelper,
    RepetitionService,
    EventAggregator,
    DataService,
    DialogService,
    Auth
)
export class NotificationSidebar {
    dueRepetitionCount = 0;
    listsToRepeat = [];
    notifications = [];
    unlockedPhrases = [];

    showingAllUnlockedPhrases = false;
    showingAllRepetitions = false;

    maxRepetitions = 2;
    maxUnlockedPhrases = 2;

    constructor(
        router,
        sidebarService,
        routeTransitionHelper,
        repetitionService,
        ea,
        dataService,
        dialogService,
        auth
    ) {
        this.router = router;
        this.sidebarService = sidebarService;
        this.routeTransitionHelper = routeTransitionHelper;
        this.repetitionService = repetitionService;
        this.ea = ea;
        this.dataService = dataService;
        this.dialogService = dialogService;
        this.auth = auth;
    }

    activate() {
        return Promise.all([
            this.repetitionService.poll().then(this._update.bind(this)),

            this.dataService.getUnlockedPhrases(this.maxUnlockedPhrases).then(phrases => {
                this.unlockedPhrases = phrases;
            })
        ]);
    }

    bind() {
        this._repEventSubscription = this.ea.subscribe(RepetitionCreatedOrUpdatedEvent, payload => {
            this._update();
        });
    }

    detached() {
        this._repEventSubscription.dispose();
    }

    _update() {
        let repetitions = this.repetitionService.getRepetitions(this.dataService.activePackageName, listId =>
            this.dataService.isList(listId)
        );

        repetitions = repetitions.filter(rep => rep.level > 0);
        repetitions.sort((a, b) => b.level - a.level);
        this.dueRepetitionCount = repetitions.length;

        if (!this.showingAllRepetitions) {
            repetitions = repetitions.slice(0, this.maxRepetitions);
        }
        // Get fresh list image for each rep:
        Promise.all(repetitions.map(rep => this.dataService.getList(rep.list_id))).then(lists => {
            repetitions.forEach((rep, i) => {
                rep.icon = lists[i].image;
                rep.name = lists[i].name;
            });

            this.listsToRepeat = lists;
        });
    }

    showSpacedRepetitionPopup() {
        this.dialogService.open({ viewModel: SpacedRepetitionPopup });
    }

    navigateToList(list) {
        this.routeTransitionHelper.setTransitionHint({ direction: 'x' });
        return Promise.resolve()
            .then(() => this.router.navigateToRoute('list', { listId: list.id }))
            .then(() => {
                this.sidebarService.ok();
            });
    }

    learnPhrase(phrase) {
        let currentInstruction = this.router.currentInstruction;
        let list = phrase.lists[0];
        this.dataService
            .createTempList('temp', {
                items: [phrase],
                image: list.image,
                source: { name: currentInstruction.config.name, params: currentInstruction.params },
                requestUpgradeOnCompletion: true
            })
            .then(customList => {
                this.routeTransitionHelper.setTransitionHint({ direction: 'x' });
                return this.router.navigateToRoute('memorize-loading', { listId: customList.id });
            })
            .then(() => {
                this.sidebarService.ok();
            });
    }

    viewAllRepetitions(sectionName) {
        this.showingAllRepetitions = true;
        this._update();
    }

    viewAllUnlockedPhrases() {
        this.showingAllUnlockedPhrases = true;

        this.dataService.getUnlockedPhrases().then(phrases => {
            this.unlockedPhrases = phrases;
        });
    }
}
