import { bindable, inject } from 'aurelia-framework';
import { Auth } from '../auth';
import { I18N } from 'aurelia-i18n';
import Storage from '../storage';
import moment from 'moment';

@inject(Auth, I18N, Storage)
export class LanguageSwitch {
    @bindable language;

    constructor(auth, i18n, storage) {
        this.auth = auth;
        this.i18n = i18n;
        this.storage = storage;
        this.language = this.auth.getLanguage();
    }

    languageChanged(newValue, oldValue) {
        this.i18n.setLocale(this.language);
        moment.locale(this.language);
        this.storage.setItem('lang', this.language);
        if (this.auth.isAuthenticated()) {
            if (this.language !== this.auth.getLanguage()) {
                this.auth.updateMe({ language: this.language }).then(() => {
                    window.location.reload();
                });
            }
        }
    }
}
