import { inject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';

import { Auth } from '../auth';
import { TweenMax, Power0 } from 'gsap';

import { FlytogetEmailSignupPopup } from '../modals/flytoget-email-signup-popup';

@inject(DialogController, Element, Auth, DialogService)
export class FlytogetSignupPopup {
    waitingFacebookLogin = false;

    constructor(controller, element, auth, dialogService) {
        this.controller = controller;
        this.element = element;
        this.auth = auth;
        this.dialogService = dialogService;
    }

    activate() {}

    attached() {
        let cls = 'memolanguage-FlytogetSignupPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);

        let cloudElements = Array.from(this.element.querySelectorAll(`.${cls}__cloud`));
        let tweens = [];
        let cloudSettings = [
            { speed: 20, delay: 8, size: 11, extra: '6em' },
            { speed: 8, delay: 6, size: 16, extra: '10em' },
            { speed: 15, delay: 4, size: 13, extra: '6em' },
            { speed: 7, delay: 2, size: 16, extra: '10em' }
        ];
        cloudElements.forEach((cloudElem, i) => {
            let cloudSetting = cloudSettings[i];
            let tween = TweenMax.fromTo(
                cloudElem,
                cloudSetting.speed,
                {
                    xPercent: -100,
                    top: `${25 * i}%`,
                    height: `${cloudSetting.size}%`,
                    width: `calc(100% + ${cloudSetting.extra})`
                },
                { xPercent: 0, ease: Power0.easeNone, delay: cloudSetting.delay, repeat: -1 }
            );
            tweens.push(tween);
        });
        this.tweens = tweens;
    }

    detached() {
        this.tweens.forEach(tween => tween.kill());
    }

    onFacebookSignup() {
        this.controller.ok();
    }

    signupWithEmail() {
        this.tweens.forEach(tween => tween.pause());

        this.dialogService.open({ viewModel: FlytogetEmailSignupPopup }).whenClosed(response => {
            if (response.wasCancelled) {
                this.tweens.forEach(tween => tween.play());
            } else {
                this.controller.ok();
            }
        });
    }

    cancel() {
        this.controller.cancel();
    }
}
