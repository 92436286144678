// import {getLogger} from 'aurelia-logging';

import { CategoryModel } from './models/category-model';
import { ListModel } from './models/list-model';
import { TempListModel } from './models/temp-list-model';
import { WordModel } from './models/word-model';
import { PhraseModel } from './models/phrase-model';
import { PronunciationModel } from './models/pronunciation-model';

// const logger = getLogger('parsers');

export function parseCategory(
    category,
    parent,
    fixtures,
    depth,
    activeSourceLanguage,
    activeTargetLanguage,
    services,
    instancesMap
) {
    let children = category.children.map(child => {
        if (child.children.length) {
            return parseCategory(
                child,
                category.id,
                fixtures,
                depth + 1,
                activeSourceLanguage,
                activeTargetLanguage,
                services,
                instancesMap
            );
        } else {
            return parseList(
                child,
                category.id,
                fixtures,
                depth + 1,
                activeSourceLanguage,
                activeTargetLanguage,
                services,
                instancesMap
            );
        }
    });

    let itemsOfType = 'mixed';
    if (children.length) {
        itemsOfType = children
            .map(ch => ch.itemsOfType)
            .reduce((a, b) => {
                return a === b ? a : 'mixed';
            });
    }

    let fixture = {
        id: category.id,
        model: 'CategoryModel',
        image: category.image,
        icon: category.icon,
        name: category.name,
        depth: depth,
        itemsOfType: itemsOfType,
        imageFormat: category.imageFormat,
        avoidCategoryView: category.avoidCategoryView,
        children: children
    };

    let instance = new CategoryModel(services, fixture);
    instancesMap.set(instance.prefixedId, instance);
    return instance;
}

function parseList(
    category,
    parent,
    fixtures,
    depth,
    activeSourceLanguage,
    activeTargetLanguage,
    services,
    instancesMap
) {
    let itemsOfType = category.has_phrases ? 'phrase' : 'word';
    if (parent === 470) {
        itemsOfType = 'pronunciation';
    }

    let ItemModelClass = {
        word: WordModel,
        phrase: PhraseModel,
        pronunciation: PronunciationModel
    }[itemsOfType];

    let fixture = {
        id: category.id,
        model: 'ListModel',
        image: category.image,
        icon: category.icon,
        name: category.name,
        items: category.items.map(itemInfo => {
            let id = (ItemModelClass.idPrefix || '') + itemInfo.id;
            let instance = instancesMap.get(id);
            if (instance) {
                // NOTE: possibly updatede instance
            } else {
                instance = new ItemModelClass(services, { id: itemInfo.id });
                instancesMap.set(id, instance);
            }
            return instance;
        }),
        access: category.access,
        itemsOfType: itemsOfType,
        depth: depth
    };
    if (category.useGrandparentInListCompletedPopup) {
        fixture.useGrandparentInListCompletedPopup = category.useGrandparentInListCompletedPopup;
    }

    let instance = new ListModel(services, fixture);
    instancesMap.set(instance.prefixedId, instance);
    return instance;
}

export function parseTempList(fixture, services, instancesMap) {
    fixture.items = fixture.itemIds.map(itemId => instancesMap.get('' + itemId));
    delete fixture.itemIds;

    let itemsOfType = fixture.items.length
        ? fixture.items
              .map(ch => ch.type)
              .reduce((a, b) => {
                  return a === b ? a : 'mixed';
              })
        : 'mixed';

    if (!fixture.image) {
        fixture.image = {
            word: '/memolanguage/images/word-placeholder.png',
            pronunciation: '/memolanguage/images/pronunciation-placeholder-square.png',
            phrase: '/memolanguage/images/phrase-placeholder.png'
        }[itemsOfType];
    }
    if (!fixture.name) {
        fixture.name = services.i18n.tr(`memolanguage:tempListDefaultName.itemsOfType.${itemsOfType}`);
    }

    let inst = new TempListModel(services, fixture);
    instancesMap.set(inst.prefixedId, inst);
    return inst;
}
