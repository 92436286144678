import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { DataService } from '../services/data-service';
import { Auth } from '../auth';
import { FlytogetSignupPopup } from '../modals/flytoget-signup-popup';

@inject(DataService, Auth, DialogService)
export class FantasyPlaceholder {
    mustSignup = false;
    fantasyId = null;

    constructor(dataService, auth, dialogService) {
        this.dataService = dataService;
        this.auth = auth;
        this.dialogService = dialogService;
    }

    activate(model) {
        this.wordId = model.wordId;

        this._update();
    }

    _update() {
        let roles = this.auth.profile.roles;
        this.mustSignup = roles.indexOf('flytoget') !== -1 && roles.indexOf('tempuser') !== -1;
    }

    showSignupPopup(event) {
        if (event.target.classList.contains('signupLink')) {
            this.dialogService.open({ viewModel: FlytogetSignupPopup }).whenClosed(response => {
                this._update();
            });
        }
    }
}
