export class ExperimentsService {
    constructor() {
        this.localExperiments = {
            'forced-login'() {
                // Return 0 or 1
                return Date.now() % 2;
            },
            'flytoget-signup-popup'() {
                // Return 0 or 1
                return Date.now() % 2;
            }
        };
    }

    getVariation(experimentName) {
        if (this.localExperiments[experimentName]) {
            return this.localExperiments[experimentName]();
        } else {
            return 0;
        }
    }
}

const experimentsService = new ExperimentsService();
export default experimentsService;
