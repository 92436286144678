import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { RouteTransitionHelper } from '../route-transition-helper';
import { SidebarService } from '../services/sidebar-service';

import { DataService } from '../services/data-service';

@inject(Router, SidebarService, RouteTransitionHelper, DataService)
export class PhraseListSidebar {
    words = []; // Phrases
    remainingWordsCount = 0;
    relatedWordsCount = 0;
    relatedWordsCompletedCount = 0;
    loaded = false;

    constructor(router, sidebarService, routeTransitionHelper, dataService) {
        this.router = router;
        this.sidebarService = sidebarService;
        this.routeTransitionHelper = routeTransitionHelper;
        this.dataService = dataService;
    }

    activate(list) {
        this.list = list;

        this.list.getIncludedWords().then(includedWords => {
            this.words = list.items;

            let words = includedWords.filter(word => !word.learnStatus);
            this.relatedWordsCount = includedWords.length;
            this.remainingWordsCount = words.length;
            this.relatedWordsCompletedCount = this.relatedWordsCount - this.remainingWordsCount;

            this.loaded = true;
        });
    }

    detached() {}

    learnUnknownWords() {
        this.list.getIncludedWords().then(includedWords => {
            let words = includedWords.filter(word => !word.learnStatus);
            if (words.length === 0) {
                return;
            }
            this.dataService
                .createTempList('temp', {
                    items: words,
                    source: { name: 'list', params: { listId: this.list.id } }
                })
                .then(customList => {
                    this.routeTransitionHelper.setTransitionHint({ direction: 'x' });
                    return this.router.navigateToRoute('memorize', { listId: customList.id });
                })
                .then(() => {
                    this.sidebarService.ok();
                });
        });
    }
}
