import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Auth } from '../auth';

@inject(DialogController, Element, Auth)
export class QuizResultPopup {
    constructor(controller, element, auth) {
        this.controller = controller;
        this.element = element;
        this.auth = auth;
    }

    activate(list) {
        this.list = list;
        let quizState = list.getQuizState();
        this.numberOfItems = this.list.items.length;
        this.numberOfCompletedItems = quizState.completedItemIds.length;
        this.completionFraction = quizState.completionFraction;
        this.quizProgress = Math.round(quizState.completionFraction * 100);
        this.totalTimeUsed = quizState.totalTimeUsed;
        this.recomendedActivity = quizState.completionFraction <= 0.5 ? 'learning' : 'testing';
        this.variant = quizState.completionFraction <= 0.5 ? 'bad' : 'good';

        if (quizState.completionFraction === 1) {
            this.recomendedActivity = 'continue';
            this.variant = 'perfect';
        }
    }

    attached() {
        let cls = 'memolanguage-QuizResultPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);

        let adjustment = 4;
        setTimeout(() => {
            this.svgCircleProgressElem.style.strokeDashoffset =
                '' + (315 - (315 - adjustment) * this.completionFraction);
        }, 500);
    }

    continueLearning() {
        this.controller.ok('continueLearning');
    }

    continueTesting() {
        this.controller.ok('continueTesting');
    }

    continue() {
        this.controller.cancel();
    }
}
