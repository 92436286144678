export class TargetItemBase {
    static idPrefix = '';
    isLoaded = false;
    lists = []; // NOTE: Filled from ListModel constructor

    constructor(services, fixture) {
        this.services = services;
        Object.assign(this, fixture);
    }

    get prefixedId() {
        return this.id;
    }

    activate() {
        return Promise.resolve();
    }

    setContent(fixture) {
        if (this.isLoaded) {
            return;
        }
        Object.assign(this, fixture);
        this.isLoaded = true;

        return this.activate().then(() => {
            this._resolveEnsureLoadedPromise(this);
        });
    }

    ensureLoaded() {
        if (!this._ensureLoadedPromise) {
            this._ensureLoadedPromise = new Promise(resolve => {
                this._resolveEnsureLoadedPromise = resolve;
                this.services.dataService.loadItemContent(this);
            });
        }
        return this._ensureLoadedPromise;
    }

    unload() {}
}
