import { inject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { I18N } from 'aurelia-i18n';

import { Auth } from '../auth';

import { FlytogetEmailSignupPopup } from '../modals/flytoget-email-signup-popup';

@inject(DialogController, Element, Auth, I18N, DialogService)
export class FlytogetSignup2Popup {
    waitingFacebookLogin = false;

    constructor(controller, element, auth, i18n, dialogService) {
        this.controller = controller;
        this.element = element;
        this.auth = auth;
        this.i18n = i18n;
        this.dialogService = dialogService;
    }

    activate() {}

    attached() {
        // window.ga('send', 'event', 'Registration', 'FlytogetSignup', 'Popup');
        let cls = 'memolanguage-FlytogetSignupPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }

    onFacebookSignup() {
        this.controller.ok();
    }

    signupWithEmail() {
        this.dialogService.open({ viewModel: FlytogetEmailSignupPopup }).whenClosed(response => {
            if (response.wasCancelled) {
                // this.tweens.forEach(tween => tween.play());
            } else {
                this.controller.ok();
            }
        });
    }

    cancel() {
        this.controller.cancel();
    }
}
