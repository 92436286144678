import { TargetItemBase } from './target-item-base';

import { LearnMixin } from './learn-mixin';
import { mix } from '../util';

export class PhraseModel extends mix(TargetItemBase).with(LearnMixin) {
    type = 'phrase';

    constructor(services, fixture) {
        super(services, fixture);
    }

    unload() {
        if (this.isLoaded) {
            super.unload();
        }
    }

    get translationString() {
        return this.translations.map(translation => translation.word).join(', ');
    }

    get relatedWordIds() {
        return this.relatedWords.map(relWordInfo => relWordInfo.word.id);
    }

    getIncludedWords() {
        return this.ensureLoaded().then(() => {
            return Promise.resolve(this.relatedWordIds.map(wordId => this.services.dataService.getSync(wordId)));
        });
    }

    getPhraseWithMarkup(selectedWordIndex) {
        return this.getIncludedWords().then(includedWords => {
            let frags = [];
            let factText = this.factText;

            let m = {};
            this.relatedWords.forEach((relWordInfo, relWordIndex) => {
                let word = includedWords.find(w => w.id === relWordInfo.word.id);
                if (word) {
                    let learnStatus = word.learnStatus;
                    for (let i = relWordInfo.start; i < relWordInfo.end; i++) {
                        m[i] = [learnStatus, relWordIndex];
                    }
                }
            });

            let i = 0;
            let learnStatus;
            let prevLearnStatus;
            let relWordIndex;
            for (let ch of factText) {
                [learnStatus, relWordIndex] = m[i] || [undefined, -1];
                if (learnStatus !== prevLearnStatus) {
                    if (prevLearnStatus !== undefined) {
                        frags.push('</span>');
                    }
                    if (learnStatus !== undefined) {
                        frags.push(
                            `<span class="learnStatus-${learnStatus} ${
                                relWordIndex === selectedWordIndex ? 'is-selected' : ''
                            }">`
                        );
                    }
                    frags.push(ch);
                } else {
                    frags.push(ch);
                }
                i += 1;
                prevLearnStatus = learnStatus;
            }
            if (learnStatus !== undefined) {
                frags.push('</span>');
            }

            return frags.join('');
        });
    }

    startUrl() {
        return `${this.type}/${this.id}`;
    }
}
