import { inject, TaskQueue } from 'aurelia-framework';

import { Auth } from '../auth';
import { DataService } from '../services/data-service';

@inject(TaskQueue, Auth, DataService)
export class FantasyDisplay {
    editable = false;
    createdByMe = false;

    constructor(taskQueue, auth, dataService) {
        this.taskQueue = taskQueue;
        this.auth = auth;
        this.dataService = dataService;
    }

    activate(fantasy) {
        this.fantasy = fantasy;
        this.createdByMe = this.auth.profile.username === this.fantasy.username;
        this.editable = this.createdByMe;

        this.videoUrl = this.fantasy.videoUrl;
        this.showVideo = this.videoUrl ? true : false;
        this.videoPosterUrl = '';

        if (this.showVideo && this.fantasy.videoPosterUrl) {
            this.videoPosterUrl = this.fantasy.videoPosterUrl || '';
        }

        return this.dataService.get(this.fantasy.wordId).then(word => {
            this.word = word;
        });
    }

    toggleLike() {
        if (this.word.currentFantasyId === this.fantasy.id) {
            this.word.setCurrentFantasy(null);
        } else {
            this.word.setCurrentFantasy(this.fantasy.id);
        }
    }

    showAndPlayVideo() {
        this.showVideo = true;
        this.taskQueue.queueMicroTask(() => {
            this.playVideo();
        });
    }

    playVideo() {
        let videoElem = this.videoElem;
        if (videoElem.paused) {
            videoElem.parentElement.classList.add('is-playing');
            videoElem.play();
            videoElem.onended = () => {
                this.playingVideoElem.parentElement.classList.remove('is-playing');
                this.playingVideoElem = null;
                this.showVideo = false;
                this.videoPosterUrl = '';
                videoElem.load(); // To reset video
            };
            this.playingVideoElem = videoElem;
        }
    }

    _pauseVideo(videoElem) {
        if (this.playingVideoElem) {
            this.playingVideoElem.parentElement.classList.remove('is-playing');
            this.playingVideoElem.pause();
            this.playingVideoElem = null;
        }
    }
}
