import { inject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';

import { Auth } from '../auth';
import vueRouter from '@/router';

// import {SignupPopup} from '../modals/signup-popup';
// import {CheckoutPopup} from '../modals/checkout-popup';
import { FlytogetEmailSignupPopup } from '../modals/flytoget-email-signup-popup';

@inject(DialogController, DialogService, Element, Router, Auth)
export class UpgradePopup {
    activeSlideIndex = 0;
    slideCount = 5;

    constructor(controller, dialogService, element, router, auth) {
        this.controller = controller;
        this.element = element;
        this.router = router;
        this.dialogService = dialogService;
        this.auth = auth;
    }

    activate() {}

    attached() {
        let cls = 'memolanguage-UpgradePopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }

    upgrade() {
        if (this.auth.isTempUser) {
            return this.dialogService.open({ viewModel: FlytogetEmailSignupPopup }).whenClosed(response => {
                if (!response.wasCancelled) {
                    // NOTE: If user signed up with facebook, it can happen that the user is an existing member.
                    if (!this.auth.isSubscribed()) {
                        // this.dialogService.open({viewModel: CheckoutPopup});
                        //
                        vueRouter.push({
                            name: 'AccountCheckoutView',
                            params: { plan: 'month' },
                            query: { next: location.pathname }
                        });
                    } else {
                        this.controller.cancel();
                    }
                }
            });
        } else {
            // this.dialogService.open({viewModel: CheckoutPopup});
            vueRouter.push({
                name: 'AccountCheckoutView',
                params: { plan: 'month' },
                query: { next: location.pathname }
            });
        }
    }
}
