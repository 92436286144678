import { inject, bindable } from 'aurelia-framework';
import { TimelineMax, Power2 } from 'gsap';

@inject(Element)
export class MultipleChoiceInteraction {
    @bindable choices = [];

    multipleSelection = false; // Not implemented
    allowDeselect = false; // Not implemented

    showingFeedback = false;

    constructor(element) {
        this.element = element;
    }

    detached() {
        if (this._tl) {
            this._tl.kill();
        }
    }

    select(choice) {
        this.choices.forEach(c => {
            c.selected = false;
        });
        choice.selected = true;

        let event = new CustomEvent('change', {
            detail: 'foobar',
            bubbles: true
        });

        this.element.dispatchEvent(event);
    }

    showFeedback(itemSession) {
        this.showingFeedback = true;

        if (itemSession.timeout) {
            return this._showFeedbackForTimeout();
        } else if (itemSession.model.score === 1) {
            return this._showFeedbackForCorrectAnswer(itemSession.model.scoreEvents);
        } else {
            return this._showFeedbackForWrongAnswer();
        }
    }

    _showFeedbackForTimeout() {
        this.status = 'timeout';
        return new Promise(resolve => {
            setTimeout(() => {
                let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
                let elems = this.element.querySelectorAll('.fasit-wrong');
                tl.to(elems, 0.5, { opacity: 0.1 });
                tl.to(this.element, 1.5, {});
            }, 0);
        });
    }

    _showFeedbackForCorrectAnswer(scoreEvents) {
        this.status = 'correct';
        return new Promise(resolve => {
            setTimeout(() => {
                let selectedChoiceElem = this.element.querySelector('.is-selected');
                let otherElems = this.element.querySelectorAll('.fasit-wrong');

                let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElem, 0.2, {
                    scale: 1.1
                });
                tl.to(selectedChoiceElem, 0.2, {
                    scale: 1
                });
                tl.to(selectedChoiceElem, 1 + scoreEvents.length * 1, {});
                tl.to(otherElems, 0.5, { opacity: 0.1 }, 0);
            }, 0);
        });
    }

    _showFeedbackForWrongAnswer() {
        this.status = 'wrong';

        return new Promise(resolve => {
            setTimeout(() => {
                let shakeSpeed = 0.1;
                let selectedChoiceElem = this.element.querySelector('.is-selected');
                let wrongElems = this.element.querySelectorAll('.fasit-wrong');

                let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(selectedChoiceElem, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElem, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(selectedChoiceElem, shakeSpeed, {
                    x: 0
                });
                tl.to(selectedChoiceElem, 2, {});
                tl.to(wrongElems, 0.5, { opacity: 0.1 }, 1);
            });
        }, 0);
    }

    animateEnter() {
        return new Promise(resolve => {
            let choiceElems = this.element.querySelectorAll('.memolanguageQuiz-MultipleChoiceInteraction-Choice');
            let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
            tl.staggerFrom(choiceElems, 0.4, { opacity: 0, y: 20 }, 0.1);
        });
    }

    animateLeave() {
        return new Promise(resolve => {
            let choiceElems = Array.from(
                this.element.querySelectorAll('.memolanguageQuiz-MultipleChoiceInteraction-Choice')
            );
            choiceElems.reverse();
            let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
            tl.staggerTo(choiceElems, 0.4, { opacity: 0, y: 20 }, 0.1);
        });
    }
}
