import Vue from 'vue';
import { inject, containerless, bindable } from 'aurelia-framework';

@containerless()
@inject(Element)
export class SvgIcon {
    @bindable icon = 'swipe';
    @bindable class = '';
    constructor(element) {
        this.element = element;
    }

    attached() {
        const realElement = getRealElement(this.element);
        realElement.setAttribute('class', this.class);
        realElement.setAttribute('icon', this.icon);

        this.vueInst = new Vue({
            el: realElement
        });
    }

    detached() {
        this.vueInst.$destroy();
    }
}

/**
 * workaround for @containerless subclasses (custom elements) - waiting for https://github.com/aurelia/templating/issues/140
 * @return previousElementSibling when given element is comment node (as it is in case of @containerless custom elements) or given element otherwise
 */
function getRealElement(element) {
    if (element.nodeType === 8) {
        element = getPreviusElementSibling(element);
    }

    return element;
}

function getPreviusElementSibling(e) {
    if (e.previousElementSibling) {
        return e.previousElementSibling;
    }

    let prev = e.previousSibling;
    while (prev) {
        if (prev.nodeType === 1) {
            return prev;
        }
        prev = prev.previousSibling;
    }

    return null;
}
