export class TooltipService {
    active = false;
    hasFocus = false;
    dimmedElements = [];
    elementsWithFocus = [];
    elementsWithFocusedDescendant = [];

    queue = [];
    actionQueue = [];

    tooltips = [];
    actions = [];

    activeTooltip = null;
    activeTooltipAction = null;

    constructor() {
        this.onResize_bound = this.onResize.bind(this);
        window.addEventListener('resize', this.onResize_bound);
    }

    onResize() {
        this.tooltips.forEach(tooltip => updateTooltipPosition(tooltip));
        this.actions.forEach(tooltipAction => updateTooltipActionPosition(tooltipAction));
    }

    add(tooltip) {
        this.queue.push(new Tooltip(tooltip, this));
    }

    clear() {
        this.queue = [];
        this.tooltips = [];
        this.actionQueue = [];
        this.actions = [];
        this.activeTooltip = null;
        this.activeTooltipAction = null;
        this.clearFocus();
        setTimeout(() => {
            // NOTE: We must delay removal of .is-active to give time for aurelia to clean up its rendering
            if (!this.active && this.hostElem) {
                this.hostElem.classList.remove('is-active');
            }
        }, 2000);
        this.active = false;
    }

    start(doneFunc) {
        this.doneFunc = doneFunc;
        this.hostElem = document.querySelector('tooltip-renderer');
        this.hostElem.classList.add('is-active');
        this.active = true;
        this.next();
    }

    finish() {
        // this.clear();
        setTimeout(() => {
            // NOTE: We must delay removal of .is-active to give time for aurelia to clean up its rendering
            if (!this.active && this.hostElem) {
                this.hostElem.classList.remove('is-active');
            }
        }, 2000);
        this.active = false;

        if (this.doneFunc) {
            this.doneFunc();
        }
    }

    next() {
        if (this.activeTooltip) {
            if (this.activeTooltip.nextAction() === null) {
                this.activeTooltip.hide();
                this.activeTooltip = null;
            }
        }

        if (!this.activeTooltip) {
            if (this.queue.length) {
                this.activeTooltip = this.queue.shift();
                this.activeTooltip.show();
            } else {
                this.finish();
                // setTimeout(() => {
                //   this.hostElem.classList.remove('is-active');
                //   this.active = false;
                // }, 1000); // NOTE: give content some time to fade out
            }
        }
    }

    setFocus(elements, delay) {
        this.clearFocus();
        let elemsToDim = [];
        let elementsWithFocusedDescendant = [];
        iterateTree(buildTree(elements), node => {
            if (node.children.size) {
                elemsToDim = elemsToDim.concat(
                    Array.from(node.elem.children).filter(childElem => {
                        return !node.children.has(childElem);
                    })
                );
                elementsWithFocusedDescendant.push(node.elem);
            }
        });

        let _apply = () => {
            elementsWithFocusedDescendant.forEach(elem => elem.classList.add('tooltip-has-focused-descentant'));
            elemsToDim.forEach(elem => elem.classList.add('tooltip-dimmed'));
            elements.forEach(elem => elem.classList.add('tooltip-focused'));
            this.dimmedElements = elemsToDim;
            this.elementsWithFocus = elements;
            this.elementsWithFocusedDescendant = elementsWithFocusedDescendant;
            this.hasFocus = true;
        };

        if (delay) {
            setTimeout(() => _apply(), delay);
        } else {
            _apply();
        }
    }

    clearFocus() {
        let dimmedElements = this.dimmedElements;

        this.elementsWithFocus.forEach(elem => elem.classList.remove('tooltip-focused'));
        this.elementsWithFocusedDescendant.forEach(elem => elem.classList.remove('tooltip-has-focused-descentant'));

        dimmedElements.forEach(elem => elem.classList.remove('tooltip-dimmed'));
        dimmedElements.forEach(elem => elem.classList.add('tooltip-dimmed-remove'));
        setTimeout(() => {
            dimmedElements.forEach(elem => elem.classList.remove('tooltip-dimmed-remove'));
        }, 2000);
        this.dimmedElements = [];
        this.elementsWithFocus = [];
        this.elementsWithFocusedDescendant = [];
        this.hasFocus = false;
    }
}

class Tooltip {
    constructor(options, tooltipService) {
        this.tooltipService = tooltipService;
        this.currentAction = null;
        this.actionQueue = options.actions.map(action => {
            return new TooltipAction(action, this, tooltipService);
        });

        Object.assign(this, options);
        if (!this.selectorRoot) {
            this.selectorRoot = document.body;
        }
    }

    nextAction() {
        if (this.currentAction) {
            this.currentAction.hide();
        }
        if (this.actionQueue.length) {
            this.currentAction = this.actionQueue.shift();
            if (this.currentAction.delay) {
                setTimeout(() => {
                    this.currentAction.show();
                }, this.currentAction.delay);
            } else {
                this.currentAction.show();
            }
        } else {
            this.currentAction = null;
        }
        return this.currentAction;
    }

    show() {
        let leafElems = [];
        if (this.ignoreDimming) {
            this.ignoreDimming.forEach(leafSelector => {
                leafElems.push(this.selectorRoot.querySelector(leafSelector));
            });
        } else {
            // leafElems.push(targetElem);
        }

        leafElems.push(document.querySelector('tooltip-renderer'));
        if (document.querySelector('global-debug-menu')) {
            leafElems.push(document.querySelector('global-debug-menu'));
        }

        this.tooltipService.setFocus(leafElems, this.focusDelay);

        let _show = () => {
            updateTooltipPosition(this);
            this.tooltipService.tooltips.push(this);
            if (this.onShow) {
                this.onShow(this);
            }
            this.nextAction();
        };

        if (this.tooltipDelay || this.focusDelay) {
            setTimeout(() => {
                _show();
            }, (this.tooltipDelay || 0) + (this.focusDelay || 0));
        } else {
            _show();
        }
    }

    hide() {
        this.tooltipService.clearFocus();
        let index = this.tooltipService.tooltips.indexOf(this);
        this.tooltipService.tooltips.splice(index, 1);
        if (this.onHide) {
            this.onHide(this);
        }
    }

    fadeOut() {
        //
        this.hideNow = true;
    }
}

class TooltipAction {
    constructor(options, tooltip, tooltipService) {
        this.tooltip = tooltip;
        this.tooltipService = tooltipService;
        Object.assign(this, options);
        if (this.placement && !this.placement.selectorRoot) {
            this.placement.selectorRoot = document.body;
        }
    }

    show() {
        updateTooltipActionPosition(this);

        if (this.onShow) {
            this.onShow(this);
        }
        this.tooltipService.actions.push(this);
    }

    hide() {
        if (this.onHide) {
            this.onHide(this);
        }
        let index = this.tooltipService.actions.indexOf(this);
        this.tooltipService.actions.splice(index, 1);
    }
}

function updateTooltipActionPosition(tooltipAction) {
    if (!tooltipAction.placement) {
        return;
    }
    let placement = tooltipAction.placement;
    if (!placement.targetElem) {
        placement.targetElem = placement.selectorRoot
            ? placement.selectorRoot.querySelector(placement.target)
            : document.body.querySelector(placement.target);
    }
    if (placement.targetElem) {
        let hostBb = tooltipAction.tooltipService.hostElem.getBoundingClientRect();
        let bb = placement.targetElem.getBoundingClientRect();

        let containerLeft = bb.left - hostBb.left;
        let containerTop = bb.top - hostBb.top;

        placement.containerCss = `top: ${containerTop}px; left: ${containerLeft}px; width: ${bb.width}px; height: ${
            bb.height
        }px; ${placement.targetCss || ''}`;

        // placement.containerCss = `top: ${bb.top}px; left: ${bb.left}px; width: ${bb.width}px; height: ${bb.height}px; ${placement.targetCss || ''}`;
    }
}

function updateTooltipPosition(tooltip) {
    if (!tooltip.targetElem) {
        tooltip.targetElem = tooltip.selectorRoot.querySelector(tooltip.target);
    }
    if (tooltip.targetElem) {
        let hostBb = tooltip.tooltipService.hostElem.getBoundingClientRect();
        let bb = tooltip.targetElem.getBoundingClientRect();
        // Assume placement is 'top'

        tooltip.x = bb.left - hostBb.left + bb.width / 2;
        tooltip.y = bb.top - hostBb.top;

        // tooltip.x = bb.left + bb.width / 2;
        // tooltip.y = bb.top;
    }
}

function buildTree(leafElements) {
    let mapping = new Map();
    let root = null;

    for (let i = 0; i < leafElements.length; i++) {
        let elem = leafElements[i];
        if (mapping.has(elem)) {
            continue;
        }
        mapping.set(elem, {
            elem: elem,
            children: new Map()
        });

        let child = elem;
        let parent = child.parentElement;
        while (parent) {
            if (mapping.has(parent)) {
                mapping.get(parent).children.set(child, mapping.get(child));
                break;
            }
            mapping.set(parent, {
                elem: parent,
                children: new Map([[child, mapping.get(child)]])
            });
            child = parent;
            parent = child.parentElement;

            if (!root && !parent) {
                root = mapping.get(child);
            }
        }
    }
    return root;
}

function iterateTree(node, func, rootFirst = true) {
    if (rootFirst) {
        func(node);
    }

    node.children.forEach(ch => iterateTree(ch, func, rootFirst));

    if (!rootFirst) {
        func(node);
    }
}
