import { inject, bindable } from 'aurelia-framework';
import { DataService } from '../services/data-service';

@inject(DataService)
export class CategoryItem {
    @bindable item;
    repetitions = [];

    constructor(dataService) {
        this.dataService = dataService;
    }

    bind(bindingContext, overrideContext) {
        this.repetitions = this.item.children.map(child => child.repetition);
    }
}

export class ClosestRepetitionValueConverter {
    toView(repetitions) {
        let repetition = { level: -1 };
        if (repetitions.every(r => r.level === 0)) {
            repetition = repetitions[0]; // Just return a rep that will render as a checkmark
        } else {
            repetitions.forEach(r => {
                if (r.level > 0 && r.level > repetition.level) {
                    repetition = r;
                }
            });
        }

        return repetition;
    }
}
