const images = require.context('@/modules/memolanguage/aurelia/images/', true, /\.(png|jpg|svg)$/);

export default function getHashedUrl(value) {
    if (value && value.startsWith('/')) {
        let path = '';
        if (value.startsWith('/images/')) {
            path = value.substr(8);
        } else if (value.startsWith('/memolanguage/images/')) {
            path = value.substr('/memolanguage/images/'.length);
        }
        value = images(`./${path}`);
    }
    return value;
}
