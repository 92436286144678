export default {
    languageMap: {
        english: 'english',
        english_no: 'engelsk',
        norwegian: 'norwegian',
        norwegian_no: 'norsk',
        french: 'french',
        french_no: 'fransk',
        italian: 'italian',
        italian_no: 'italiensk',
        german: 'german',
        german_no: 'tysk',
        chinese: 'chinese',
        chinese_no: 'kinesisk',
        portuguese: 'portuguese',
        portuguese_no: 'portugisisk',
        spanish: 'spanish',
        spanish_no: 'spansk'
    },

    cta: {
        whatIsThePronunciationOfThisWord: 'What is the pronunciation of this word?',
        whatIsThePronunciationOfThisWord_no: 'Hva er uttalen for dette ordet?',

        whatIsThisInTargetLanguage: 'What is this in $t(languageMap.{{ targetLanguage }})?',
        whatIsThisInTargetLanguage_no: 'Hva er dette på $t(languageMap.{{ targetLanguage }})?',

        whatIsTheWordInTargetLanguage: "What's the word in $t(languageMap.{{ targetLanguage }})?",
        whatIsTheWordInTargetLanguage_no: 'Hva er ordet på $t(languageMap.{{ targetLanguage }})?'
    },

    quizBase: {
        noHeadphones: "I don't have headphones with me",
        noHeadphones_no: 'Jeg har ikke øretelefoner',

        skip: 'Skip',
        skip_no: 'Hopp over',

        textualFeedbackCorrect: 'Great Job!!!',
        textualFeedbackCorrect_no: 'Riktig!',
        textualFeedbackWrong: 'Wrong answer',
        textualFeedbackWrong_no: 'Galt svar'
    },

    listeningTestView: {},

    multipleChoiceView: {},

    writingTestView: {
        placeholderText: 'Type your answer here or tap below',
        placeholderText_no: 'Skriv svaret her eller klikk nedenfor'
    },

    phraseTestView: {
        inputPlaceholderText: 'Tap to create the phrase'
    }
};
