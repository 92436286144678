import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { Auth } from '../auth';

const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

@inject(DialogController, Element, Auth)
export class FlytogetEmailSignupPopup {
    waitingFacebookLogin = false;
    unknownEmailSignupError = false;

    signupData = {
        email: '',
        password: ''
    };

    errors = {
        email: '',
        password: ''
    };

    constructor(controller, element, auth) {
        this.controller = controller;
        this.element = element;
        this.auth = auth;
    }

    attached() {
        let cls = 'memolanguage-FlytogetEmailSignupPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }

    closeSignupForm() {}

    emailChanged() {
        this.unknownEmailSignupError = false;
        this.errors.email = '';
        if (!this.signupData.email) {
            this.errors.email = 'required';
        } else if (!emailRegex.exec(this.signupData.email)) {
            this.errors.email = 'email';
        }
    }

    passwordChanged() {
        this.unknownEmailSignupError = false;
        this.errors.password = '';
        if (!this.signupData.password) {
            this.errors.password = 'required';
        } else if (this.signupData.password.length < 8) {
            this.errors.password = 'minLength';
        }
    }

    submitEmailSignup() {
        if (this.errors.email || this.errors.password) {
            return;
        }

        this.auth
            .createOrUpdateUser({
                email: this.signupData.email,
                name: 'flytoget-user',
                username: this.signupData.email,
                password: this.signupData.password
            })
            .then(response => {
                this.unknownEmailSignupError = false;

                this.controller.ok();
            })
            .catch(err => {
                if (['Username already taken', 'Email already registered'].indexOf(err.error) !== -1) {
                    this.errors.email = 'alreadyUsed';
                } else {
                    this.unknownEmailSignupError = true;
                }
            });
    }

    cancel() {
        this.controller.cancel();
    }
}
