import { PLATFORM } from 'aurelia-framework';
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { DataService } from '../services/data-service';

PLATFORM.moduleName('./conjugations-popup');

@inject(DialogController, DataService, Element)
export class ConjugationsPopup {
    word = null;
    fantasyIndex = 0;
    activeChildIndex = 0;
    conjugations = [];
    translation = '';

    constructor(controller, dataService, element) {
        this.controller = controller;
        this.dataService = dataService;
        this.element = element;
    }

    activate(word) {
        this.word = word;
        this.translation = this.word.translationString;
        this.pronunciation = this.word.pronunciation;
        if (!this.pronunciation) {
            let pronunciation = this.word.pronunciations.find(p => {
                return p.from_language === this.dataService.activeSourceLanguage;
            });

            if (pronunciation) {
                this.pronunciation = pronunciation.pronunciation;
            }
        }

        function _parseItems(obj) {
            return Object.keys(obj).map(key => {
                return {
                    key: key,
                    value: obj[key]
                };
            });
        }
        function _parseChildren(obj) {
            return Object.keys(obj).map(key => {
                return {
                    key: key,
                    items: _parseItems(obj[key])
                };
            });
        }

        if (this.word.language === 'italian') {
            this.conjugations = [
                { label: 'Indicativo', children: _parseChildren(this.word.conjugation.Indicativo) },
                { label: 'Congiuntivo', children: _parseChildren(this.word.conjugation.Congiuntivo) },
                { label: 'Condizionale', children: _parseChildren(this.word.conjugation.Condizionale) }
            ];
        }
        // console.log(this.conjugations);
        // console.log(this.word.conjugation);
    }

    attached() {
        let cls = 'memolanguage-WordPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }
}
