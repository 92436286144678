/**
 * Expected fixture data:
 *  _id
 *  collections
 *  logoUrl
 *  backgroundUrl
 *  settings
 */
export class AppPackage {
    isLoaded = true;

    constructor(services, fixture) {
        this.services = services;
        Object.assign(this, fixture);
    }

    set activeCategoryIndex(value) {
        // NOTE: we should consider to persist in pouch, so the value is synced.
        return sessionStorage.setItem('memolanguage-home-activeCategoryIndex', value);
    }

    get activeCategoryIndex() {
        return parseInt(sessionStorage.getItem('memolanguage-home-activeCategoryIndex') || 0, 10);
    }

    getCollections() {
        return Promise.all(
            this.collections.map(collectionId => {
                return this.services.dataService.getCategory(collectionId);
            })
        );
    }
}
