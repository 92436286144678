import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

// import {RefereeHttpClient, json} from '../../clients';
// import {expandI18nSource} from '../../expand-i18n-source';

import { QuizSession } from '../quiz-session';
import quizI18nSource from '../i18n/quiz';
import localsPreprocess from '@/locales-preprocess';

@inject(/*RefereeHttpClient,*/ EventAggregator, I18N)
export class QuizService {
    session = null;

    constructor(/*refereeClient,*/ ea, i18n) {
        // this.refereeClient = refereeClient;
        this.ea = ea;
        this.i18n = i18n;

        // Setting up i18n
        const quizI18n = localsPreprocess(quizI18nSource);
        this.i18n.i18next.addResourceBundle('en', 'quiz', quizI18n.en);
        this.i18n.i18next.addResourceBundle('no', 'quiz', quizI18n.no);
    }

    initiateSession(options) {
        this.session = new QuizSession(options, this.ea);
        return Promise.resolve(this.session);
    }

    // report() {
    //   let result = {
    //     collectionId: this.session.extraData.collectionId || null,
    //     result: this.session.completedQuizItemSessions.map(itemSession => {
    //       return {
    //         time: itemSession.time,
    //         score: itemSession.score,
    //         difficulty: itemSession.model.difficulty
    //       };
    //     })
    //   };
    //   return this.refereeClient.fetch(`/report/${this.session.appPackageId}`, {method: 'post', body: json(result)});
    // }
}
