/* global PLATFORM */
import { inject } from 'aurelia-framework';

import { SidebarService } from '../services/sidebar-service';

import { Auth } from '../auth';

@inject(SidebarService, Auth)
export class StandardViewHeader {
    constructor(sidebarService, auth) {
        this.sidebarService = sidebarService;
        this.auth = auth;
    }

    openNotificationSidebar() {
        this.sidebarService.open({
            viewModel: PLATFORM.moduleName('../components/notification-sidebar'),
            placement: 'right'
        });
    }

    openProfileSidebar() {
        this.sidebarService.open({
            viewModel: PLATFORM.moduleName('../components/profile-sidebar'),
            placement: 'right'
        });
    }
}
