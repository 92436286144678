import { inject } from 'aurelia-framework';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import { TimelineMax } from 'gsap';

@inject(Element)
export class QuizItemBase {
    itemSession = null;
    model = null;

    correctSound = correctSound;

    constructor(element) {
        this.soundService = soundService;
        this.element = element;
    }

    attached() {
        this.itemSession.start(this);
        this.animateEnter();
    }

    detached() {
        if (this._tl) {
            this._tl.kill();
        }
    }

    activate(itemSession) {
        this.itemSession = itemSession;
        this.model = itemSession.model;
    }

    commit() {
        if (this.model.score === 1) {
            if (this.correctSound) {
                this.soundService.play(this.correctSound);
            }
        }
        return this.showFeedback().then(() => this.animateLeave());
    }

    animateEnter() {
        let topElems = this.element.querySelectorAll('[slot="cta"], [slot="question"]');
        let interactionElem = this.element.querySelector('[slot="interaction"]');
        let footerElem = this.element.querySelector('.memolanguageQuiz-QuizBase__footer');

        let tl = (this._tl = new TimelineMax());
        tl.set(interactionElem, { opacity: 0 });
        tl.set(footerElem, { opacity: 0 });
        tl.staggerFromTo(topElems, 0.5, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 0.1);
        tl.set(interactionElem, { clearProps: 'opacity' });
        tl.addPause('+=0', () => {
            this.animateEnterInteraction().then(() => tl.play());
        });
        tl.to(footerElem, 0.5, { opacity: 1, clearPros: 'opacity' });
    }

    // Override this in subclass for custom animation
    animateEnterInteraction() {
        return new Promise(resolve => {
            let interactionElem = this.element.querySelector('[slot="interaction"]');
            let tl = new TimelineMax({ onComplete: () => resolve() });
            tl.fromTo(interactionElem, 0.5, { opacity: 0, y: 20 }, { opacity: 1, y: 0 });
        });
    }

    animateLeave() {
        return new Promise(resolve => {
            let footerElem = this.element.querySelector('.memolanguageQuiz-QuizBase__footer');
            let tl = (this._tl = new TimelineMax({ onComplete: () => resolve() }));
            tl.to(footerElem, 0.3, { opacity: 0, y: 20 });
            tl.addPause('+=0', () => {
                this.animateLeaveInteraction().then(() => tl.play());
            });
            tl.to(footerElem, 0.01, { opacity: 0 }); // NOTE: Dummy tween, because otherwise timline onComplete is called too soon
        });
    }

    // Override this in subclass for custom animation
    animateLeaveInteraction() {
        return new Promise(resolve => {
            let interactionElem = this.element.querySelector('[slot="interaction"]');
            let tl = new TimelineMax({ onComplete: () => resolve() });
            tl.to(interactionElem, 0.5, { opacity: 0, y: 20 });
        });
    }

    // Override this in subclass
    showFeedback() {
        return Promise.resolve();
    }
}
