import { TargetItemBase } from './target-item-base';

import { LearnMixin } from './learn-mixin';
import { FantasyMixin } from './fantasy-mixin';
import { mix } from '../util';

export class WordModel extends mix(TargetItemBase).with(FantasyMixin, LearnMixin) {
    type = 'word';

    constructor(services, fixture) {
        super(services, fixture);
    }

    unload() {
        if (this.isLoaded) {
            super.unload();
        }
    }

    startUrl() {
        return `${this.type}/${this.id}`;
    }

    get translationString() {
        return this.translations.map(translation => translation.word).join(', ');
    }

    get defaultSound() {
        if (this.tts) {
            return this.tts.male || this.tts.female || null;
        } else {
            return null;
        }
    }

    getIncludedWords() {
        return Promise.resolve([]);
    }
}
