import { AbstractScoreEngine } from './quiz/abstract-scoring-engine';

export class LanguageScoreEngine extends AbstractScoreEngine {
    maxTimeForNextQuestion(difficulty) {
        return 40;
    }

    update(test) {
        let newEvents = [];
        this.questionCount += 1;
        if (test.score === 1) {
            this.correctCount += 1;
        }
        this.bonusPoints = this.correctCount;
        this.events.splice(this.events.length, 0, ...newEvents);
        return newEvents;
    }
}
