import { bindable, bindingMode, inject } from 'aurelia-framework';
import Hammer from 'hammerjs';
import { TimelineMax, Power0 } from 'gsap';

@inject(Element)
export class Carousel {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) activeItemIndex;
    @bindable options = {
        inactiveItemStyle: {
            scale: 0.9,
            opacity: 0.2
        }
    };

    swipeThreshold = 1 / 4;
    velocityThreshold = 0.3;

    transitionSpeed = 0.5; // half second

    constructor(element) {
        this.element = element;
    }

    activeItemIndexChanged(newActiveItemIndex, oldActiveItemIndex) {
        if (oldActiveItemIndex === null) {
            return;
        }

        let delta = Math.abs(this.tl.time() - (1 + newActiveItemIndex));
        let speed = delta < 0.9 ? 1 : delta;
        let ease = Cubic.easeOut;

        this.tl.timeScale(speed / this.transitionSpeed);
        this.tl.tweenTo(1 + newActiveItemIndex, { ease: ease });
    }

    attached() {
        this._mutationObserver = new MutationObserver(mutations => {
            if (this._lastItemCount !== this.itemCount) {
                this._setupTimeline();
            }
        });

        this._mutationObserver.observe(this.itemsContainerElem, {
            childList: true
        });

        this._setupTimeline();

        let lastTime;
        let blockInteraction = false;

        this._handlePanStart = event => {
            blockInteraction = this.element.classList.contains('tooltip-no-interaction');
            event.srcEvent.stopImmediatePropagation();
            event.srcEvent.preventDefault();
            event.srcEvent.stopPropagation();

            lastTime = this.tl.time();
        };
        this._handlePanMove = event => {
            if (blockInteraction) {
                return;
            }
            let deltaTime = event.deltaX / this.itemsContainerElem.clientWidth / 2;
            if (deltaTime > 1) {
                deltaTime = 1;
            } else if (deltaTime < -1) {
                deltaTime = -1;
            }

            this.tl.time(lastTime - deltaTime);

            event.srcEvent.preventDefault();
        };
        this._handlePanEnd = event => {
            this.element.onclick = e => {
                e.stopImmediatePropagation();
                this.element.onclick = null;
            };
            // NOTE: onclick is not triggered for short distances, so we make sure to null it anyway
            setTimeout(() => (this.element.onclick = null), 1);

            if (blockInteraction) {
                blockInteraction = false;
                return;
            }

            let velocity = Math.abs(event.velocityX);
            let _lastTime = this.activeItemIndex + 1;
            let delta = _lastTime - this.tl.time();

            if (delta < 0) {
                if (
                    (delta < -this.swipeThreshold || velocity > this.velocityThreshold) &&
                    this.activeItemIndex < this.itemCount - 1
                ) {
                    this.activeItemIndex += 1;
                } else {
                    this.tl.timeScale(1);
                    this.tl.tweenTo(_lastTime, { ease: Power1.easeOut });
                }
            } else if (delta > 0) {
                if ((delta > this.swipeThreshold || velocity > this.velocityThreshold) && this.activeItemIndex > 0) {
                    this.activeItemIndex -= 1;
                } else {
                    this.tl.timeScale(1);
                    this.tl.tweenTo(_lastTime, { ease: Power1.easeOut });
                }
            }
        };
        this._handleTap = event => {
            if (
                this.element.classList.contains('tooltip-no-interaction') ||
                this.itemsContainerElem.classList.contains('tooltip-no-interaction')
            ) {
                return;
            }

            let target = event.target;
            let itemIndex = -1;
            let itemElems = Array.from(this.itemsContainerElem.children);

            if (this.itemsContainerElem !== target && this.itemsContainerElem.contains(target)) {
                while (target && target !== this.itemsContainerElem) {
                    itemIndex = itemElems.indexOf(target);
                    if (itemIndex !== -1) {
                        break;
                    }
                    target = target.parentElement;
                }
            } else {
                let activeItem = itemElems[this.activeItemIndex];
                let leftItem = itemElems[this.activeItemIndex - 1];
                let rightItem = itemElems[this.activeItemIndex + 1];

                let activeItemRect = activeItem.getBoundingClientRect();

                if (event.center.x < activeItemRect.left) {
                    if (leftItem && leftItem.getBoundingClientRect().right < event.center.x) {
                        itemIndex = this.activeItemIndex - 1;
                    }
                } else if (event.center.x > activeItemRect.right) {
                    if (rightItem && rightItem.getBoundingClientRect().left > event.center.x) {
                        itemIndex = this.activeItemIndex + 1;
                    }
                }
            }
            if (itemIndex !== -1 && this.activeItemIndex !== itemIndex) {
                this.element.onclick = e => {
                    e.stopImmediatePropagation();
                    this.element.onclick = null;
                };
                this.activeItemIndex = itemIndex;
            }
        };

        this.hammer = new Hammer.Manager(this.element, {
            recognizers: [
                [Hammer.Tap, { threshold: 4 }],
                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
            ]
        });
        this.hammer.on('panstart', this._handlePanStart);
        this.hammer.on('panmove', this._handlePanMove);
        this.hammer.on('panend', this._handlePanEnd);
        this.hammer.on('tap', this._handleTap);
    }

    detached() {
        this.hammer.destroy();
        this.tl.kill();
        this._mutationObserver.disconnect();
    }

    get itemCount() {
        this._lastItemCount = this.itemsContainerElem.children.length;
        return this._lastItemCount;
    }

    _setupTimeline() {
        if (this.tl) {
            this.tl.kill();
        }
        let itemCount = this.itemCount;
        let inactiveItemLeftStyle = Object.assign({}, this.options.inactiveItemStyle);
        let inactiveItemRightStyle = Object.assign({}, this.options.inactiveItemStyle);

        let tl = new TimelineMax();
        tl.pause();
        tl.seek(0);

        tl.set(this.itemsContainerElem.children, this.options.inactiveItemStyle);
        for (let i = 0; i < itemCount + 1; i++) {
            tl.fromTo(
                this.itemsContainerElem,
                1,
                {
                    ease: Power0.easeNone,
                    xPercent: -(i - 1) * 100
                },
                {
                    ease: Power0.easeNone,
                    xPercent: -i * 100
                }
            );

            if (i > 0) {
                tl.fromTo(
                    this.itemsContainerElem.children[i - 1],
                    1,
                    { opacity: 1, scale: 1, immediateRender: false },
                    Object.assign({}, inactiveItemLeftStyle),
                    i
                );
            }
            if (i < itemCount) {
                tl.fromTo(
                    this.itemsContainerElem.children[i],
                    1,
                    Object.assign({}, inactiveItemRightStyle),
                    { opacity: 1, scale: 1, immediateRender: false },
                    i
                );
            }
        }

        this.tl = tl;
        tl.time(this.activeItemIndex + 1);
    }
}
