function isLocalStorageEnabled() {
    try {
        localStorage.setItem('mod', 'mod');
        localStorage.removeItem('mod');
        return true;
    } catch (e) {
        return false;
    }
}

let CookieStorage = {
    getItem: function(sKey) {
        if (!sKey || !this.hasOwnProperty(sKey)) {
            return null;
        }
        return unescape(
            document.cookie.replace(
                new RegExp(
                    '(?:^|.*;\\s*)' + escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'
                ),
                '$1'
            )
        );
    },
    key: function(nKeyId) {
        return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, '').split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
    },
    setItem: function(sKey, sValue) {
        if (!sKey) {
            return;
        }
        document.cookie = escape(sKey) + '=' + escape(sValue) + '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/';
        this.length = document.cookie.match(/\=/g).length;
    },
    length: 0,
    removeItem: function(sKey) {
        if (!sKey || !this.hasOwnProperty(sKey)) {
            return;
        }
        document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        this.length--;
    },
    hasOwnProperty: function(sKey) {
        return new RegExp('(?:^|;\\s*)' + escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
            document.cookie
        );
    },

    /* aurelia-auth compatibility */
    set: function(sKey, sValue) {
        return this.setItem(sKey, sValue);
    },

    get: function(sKey) {
        return this.getItem(sKey);
    }
};

let LocalStorage = {
    getItem: function(sKey) {
        return window.localStorage.getItem(sKey);
    },

    setItem: function(sKey, sValue) {
        return window.localStorage.setItem(sKey, sValue);
    },

    removeItem: function(sKey) {
        return window.localStorage.removeItem(sKey);
    },

    set: function(sKey, sValue) {
        return this.setItem(sKey, sValue);
    },

    get: function(sKey) {
        return this.getItem(sKey);
    }
};

let storage = LocalStorage;

if (!isLocalStorageEnabled()) {
    storage = CookieStorage;
}

export default storage;
