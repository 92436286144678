import store from '@/store';

export class MemolanguageSettings {
    completedTooltips = {};
    onboardingDone = false;

    constructor() {
        this.initialUserstateReady = new Promise(resolve => (this.initialUserstateReadyResolve = resolve));

        store.watch(
            state => state.moduleMemolanguage.settings,
            (newValue, oldValue) => {
                this.completedTooltips = newValue.completedTooltips;
                this.onboardingDone = newValue.onboardingDone;
            },
            { deep: true, immediate: true }
        );

        this.initialUserstateReadyResolve();
    }

    waitReady() {
        return this.initialUserstateReady;
    }

    markTooltipAsSeen(tooltipId) {
        return store.dispatch('moduleMemolanguage/markTooltipAsSeen', { tooltipId });
    }

    markOnboardingAsSeen() {
        this.onboardingDone = true; // NOTE: in order have new value BEFORE watch callback
        return store.dispatch('moduleMemolanguage/markOnboardingAsSeen');
    }
}
