import { FantasyMixin } from './fantasy-mixin';
import { LearnMixin } from './learn-mixin';

import { mix } from '../util';

import { TargetItemBase } from './target-item-base';

export class PronunciationModel extends mix(TargetItemBase).with(FantasyMixin, LearnMixin) {
    static idPrefix = 'p-';
    type = 'pronunciation';

    constructor(services, fixture) {
        super(services, fixture);
    }

    get prefixedId() {
        return `p-${this.id}`;
    }

    unload() {
        if (this.isLoaded) {
            super.unload();
        }
    }

    get defaultSound() {
        if (this.tts) {
            return this.tts.male || this.tts.female || null;
        } else {
            return null;
        }
    }

    startUrl() {
        return `${this.type}/${this.id}`;
    }
}
