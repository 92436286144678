import { shuffleArray } from './util';
// import {getLogger} from 'aurelia-logging';
import sampleSize from 'lodash-es/sampleSize';
import shuffle from 'lodash-es/shuffle';
import take from 'lodash-es/take';
import without from 'lodash-es/without';

// const logger = getLogger('quiz-generators-language');

function hasTTS(item) {
    return item.tts.male || item.tts.female;
}

function generateListeningTests(items, dataPool, maxChoicesPerTest, appPackageSettings) {
    items = items.filter(i => i.type === 'word');
    dataPool = dataPool.filter(i => i.type === 'word');
    maxChoicesPerTest = 4;
    let tests = [];
    let allChoices = [];

    dataPool.forEach(item => {
        let translation = item.translationString;
        allChoices.push(translation);
    });
    allChoices = Array.from(new Set(allChoices));

    items.filter(hasTTS).forEach(item => {
        let q = item.title;
        let translation = item.translationString;
        let choices = [
            {
                answer: translation,
                isCorrect: true
            }
        ];

        let wrongs = allChoices.filter(choice => {
            return choice !== choices[0].answer;
        });
        wrongs.slice(0, maxChoicesPerTest - 1).forEach(choice => {
            choices.push({
                answer: choice,
                isCorrect: false
            });
        });

        choices = shuffleArray(choices);

        tests.push({
            type: 'listen',
            source: item.id,
            cta: { t: 'quiz:cta.whatIsTheWordInTargetLanguage', tParams: { targetLanguage: item.language } },
            question: q,
            tts: item.tts,
            choices: choices
        });
    });
    return tests;
}

const ARTICLES = {
    english: ['a', 'an'],
    french: ['un', 'une'],
    german: ['ein', 'eine'],
    spanish: ['un', 'una'],
    portuguese: ['um', 'uma'],
    norwegian: ['en', 'ei', 'et'],
    italian: ['un', 'una']
};

function generateWritingTests(items, dataPool, maxChoicesPerTest, appPackageSettings) {
    items = items.filter(i => i.type === 'word');
    dataPool = dataPool.filter(i => i.type === 'word');

    let tests = [];
    if (items.length === 0) {
        return tests;
    }
    let articles;
    if (items[0].language) {
        articles = new Set(ARTICLES[items[0].language]);
    } else {
        articles = new Set();
    }
    let allChars = new Set();
    // add lowercase alphabet
    for (let i = 97; i <= 122; i++) {
        allChars.add(String.fromCharCode(i));
    }
    dataPool.forEach(item => {
        splitTitle(item.word.split('/')[0]).forEach(c => {
            allChars.add(c);
        });
        if (item.article) {
            articles.add(item.article);
        }
    });
    allChars.delete(' ');

    items.forEach(item => {
        let translation = item.translationString;
        let q = translation;

        let chars = new Set();
        let title = item.word.split('/')[0];
        splitTitle(title).forEach(c => {
            chars.add(c);
        });

        // add all chars outside ASCII
        let match = title.match(/([^\x20-\x7E])+/g);
        if (match) {
            match.forEach(c => {
                chars.add(c);
            });
        }

        // and add some wrong chars
        let notUsed = Array.from(allChars).filter(char => {
            return title.indexOf(char) === -1;
        });

        sampleSize(notUsed, 2).forEach(n => {
            chars.add(n);
        });

        chars = shuffle(Array.from(chars));

        let choices = chars.map(c => {
            return { answer: c, isCorrect: true };
        });
        articles.forEach(a => {
            choices.push({
                answer: a + ' ',
                isCorrect: true
            });
        });

        // add any whitespace char at the end
        if (item.title.indexOf(' ') > -1) {
            choices.push({
                answer: ' ',
                isCorrect: true
            });
        }

        tests.push({
            type: 'writing',
            source: item.id,
            cta: { t: 'quiz:cta.whatIsTheWordInTargetLanguage', tParams: { targetLanguage: item.language } },

            question: q,
            answer: [item.title.split('/')[0], item.word.split('/')[0]],
            choices: choices
        });
    });
    return tests;
}

function generatePronunciationTests(items, dataPool, maxChoicesPerTest, appPackageSettings) {
    items = items.filter(i => i.type === 'pronunciation');

    let tests = [];
    items.forEach(item => {
        let choices = [
            {
                answer: item.pronunciation,
                isCorrect: true
            }
        ];

        item.wrongs.forEach(wrong => {
            choices.push({
                answer: wrong,
                isCorrect: false
            });
        });

        tests.push({
            source: item.id,
            type: 'choice',
            cta: { t: 'quiz:cta.whatIsThePronunciationOfThisWord' },
            question: item.example, //item.title,
            choices: shuffleArray(choices)
        });

        if (hasTTS(item)) {
            tests.push({
                type: 'listen',
                source: item.id,
                cta: { t: 'quiz:cta.whatIsThePronunciationOfThisWord' },
                // question: item.title,
                tts: item.tts,
                choices: shuffleArray(JSON.parse(JSON.stringify(choices)))
            });
        }
    });

    return tests;
}

function generatePhrasesTests(items, dataPool, maxChoicesPerTest, appPackageSettings) {
    items = items.filter(i => i.type === 'phrase');
    let tests = [];

    items.forEach(item => {
        let translation = item.translationString;
        let q = translation;

        let words = [];
        item.title.split(' ').forEach(w => {
            words.push(w);
        });

        words = shuffle(words);

        let choices = words.map(c => {
            return { answer: c };
        });
        tests.push({
            type: 'phrase',
            source: item.id,
            cta: { t: 'quiz:cta.whatIsThisInTargetLanguage', tParams: { targetLanguage: item.language } },
            question: q,
            answer: item.title,
            choices: choices
        });
    });

    // Adding two false words to each tests:

    let allFragments = new Set();
    tests.forEach(test => {
        test.choices.forEach(choice => {
            allFragments.add(choice.answer);
        });
    });
    allFragments = Array.from(allFragments);
    allFragments = shuffleArray(allFragments);

    const maxFalseFragments = 2;
    tests.forEach(test => {
        let correctAnswers = test.choices.map(c => c.answer);
        let candidates = without(allFragments, ...correctAnswers);
        let falseFragments = take(candidates, maxFalseFragments).map(f => {
            return { answer: f };
        });
        test.choices.push(...falseFragments);
    });

    return tests;
}

function splitTitle(title) {
    let chars = new Set();
    title.split(' ').forEach(word => {
        let length = word.length;

        // split all short words and add all chars
        if (length < 4) {
            word.split('').forEach(c => {
                chars.add(c);
            });
        } else {
            // split all long words in two parts
            let index = Math.ceil(length / 2);
            chars.add(word.substring(0, index));
            chars.add(word.substring(index));
        }
    });
    return chars;
}

const generators = [
    {
        title: 'writing',
        easy: [generateWritingTests]
    },
    {
        title: 'listening',
        easy: [generateListeningTests]
    },
    {
        title: 'pronunciation',
        easy: [generatePronunciationTests]
    },
    {
        title: 'phrases',
        easy: [generatePhrasesTests]
    }
];

export { generators };
