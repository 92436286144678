import { QuizItemBase } from '../quiz-item-base';
import { TimelineMax, Power2 } from 'gsap';

export class PhraseTest extends QuizItemBase {
    autoCommit = true;
    status = ''; // correct | wrong
    answer = '';
    items = [];

    select(choice) {
        if (!choice.used) {
            this.items.push(choice);
            choice.used = true;
            this.updateScore();
        }
    }

    remove(item) {
        item.used = false;
        let index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.updateScore();
    }

    updateScore() {
        this.answer = this.items.map(item => item.answer).join(' ');
        if (this.answer === this.model.answer) {
            this.model.score = 1;
        } else {
            this.model.score = 0;
        }
        if (this.autoCommit && this.model.score === 1) {
            this.submitAnswer();
        }
    }

    submitAnswer() {
        this.itemSession.commit();
    }

    showFeedback() {
        if (this.model.score === 1) {
            return this._showFeedbackForCorrectAnswer();
        } else {
            return this._showFeedbackForWrongAnswer();
        }
    }

    _showFeedbackForCorrectAnswer() {
        this.status = 'correct';

        return new Promise(resolve => {
            let tl = (this._tl = new TimelineMax({
                onComplete: () => {
                    resolve();
                }
            }));
            tl.addCallback(() => {
                this.phraseTextElem.classList.add('feedback-correct');
            });
            tl.to(this.phraseTextElem, 0.2, {
                scale: 1.2
            });
            tl.to(this.phraseTextElem, 0.2, {
                scale: 1
            });
            tl.to(this.phraseTextElem, 1 + this.model.scoreEvents.length * 1, {});
        });
    }

    _showFeedbackForWrongAnswer() {
        this.status = 'wrong';

        return new Promise(resolve => {
            let shakeSpeed = 0.1;
            let tl = (this._tl = new TimelineMax({
                onComplete: () => {
                    resolve();
                }
            }));
            tl.addCallback(() => {
                this.phraseTextElem.classList.add('feedback-wrong');
            });
            tl.to(this.phraseTextElem, shakeSpeed, {
                x: -7,
                ease: Power2.easeInOut
            });
            tl.to(this.phraseTextElem, shakeSpeed, {
                repeat: 4,
                x: 5,
                yoyo: true,
                ease: Power2.easeInOut
            });
            tl.to(this.phraseTextElem, shakeSpeed, {
                x: 0
            });
            tl.addCallback(() => {
                this.phraseTextElem.classList.remove('feedback-wrong');
                this.phraseTextElem.classList.add('feedback-fasit');

                this.answer = this.model.answer;
            });
            tl.to(this.phraseTextElem, 2, {});
        });
    }
}
