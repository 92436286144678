import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';

import { DebugService } from '../services/debug-service';
import { DataService } from '../services/data-service';
import { TimelineMax, Sine, Power0 } from 'gsap';

import { UpgradePopup } from '../modals/upgrade-popup';

import { Auth } from '../auth';

import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import tadaSound from '@/assets/sounds/tada.mp3';

@inject(DialogController, Element, DataService, DebugService, DialogService, Auth)
export class ListCompletedPopup {
    isFlipped = false;
    upgraded = false;
    categoryCompleted = false;

    constructor(controller, element, dataService, debugService, dialogService, auth) {
        this.controller = controller;
        this.element = element;
        this.dataService = dataService;
        this.debugService = debugService;
        this.dialogService = dialogService;
        this.auth = auth;
    }

    activate(list) {
        this.list = list;

        this._registerDebugCommands();

        this.newlyLearnedItemCount = this.list.itemCount;
        this.showPreviousBeltValue = true;
        this.parent = this.list.parent;
        if (this.list.useGrandparentInListCompletedPopup) {
            this.parent = this.list.parent.parent;
        }

        this.categoryCompleted = this.parent.learnedItemCount === this.parent.itemCount;

        this.upgraded = this.auth.isSubscribed();

        return Promise.all([soundService.preload([tadaSound, correctSound])]);
    }

    _registerDebugCommands() {
        this.debugService.add(
            'flip',
            () => {
                this.isFlipped = !this.isFlipped;
            },
            this
        );
    }

    showUpgradePopup() {
        this.dialogService.open({ viewModel: UpgradePopup }).whenClosed(response => {
            if (!response.wasCancelled) {
                this.controller.ok('upgraded');
            }
        });
    }

    shareOnFacebook() {
        FB.ui(
            {
                method: 'share',
                href: 'http://www.memolife.com/flytoget-share'
            },
            response => {
                if (response) {
                    // Assume no error -- TODO
                    this.controller.ok();
                }
                // callback after sending
            }
        );
    }

    shareCategoryCompletionOnFacebook() {
        FB.ui(
            {
                method: 'share',
                href: 'http://www.memolife.com/flytoget-share'
            },
            response => {
                if (response) {
                    // Assume no error -- TODO
                    // this.controller.ok();
                }
                // callback after sending
            }
        );
    }

    _tickTargetItemCount() {
        if (this.newlyLearnedItemCount > 0) {
            this.newlyLearnedItemCount -= 1;

            setTimeout(() => this._tickTargetItemCount(), 100);
        }
    }

    attached() {
        let cls = 'memolanguage-ListCompletedPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);

        if (!document.body.classList.contains('memolanguage-theme-flytoget')) {
            this.sharingDisabled = true;
        }

        this._animate();
    }

    detached() {
        this.debugService.remove(this);
    }

    deactivate() {
        if (this._tl) {
            this._tl.kill();
        }
    }

    _animate() {
        let iconElem = this.iconElem;
        let boxElem = this.boxElem;
        let iconCheckmarkElem = this.iconCheckmarkElem;
        let listIndicatorElem = this.listIndicatorElem;
        let progressbarIndicatorElem = this.progressbarIndicatorElem;

        let avatarCircleElem;

        if (this.auth.isTempUser) {
            avatarCircleElem = this.element.querySelector('.memolanguage-ListCompletedPopup__signupBanner .svg-icon');
        } else {
            avatarCircleElem = this.avatarElem.querySelector('.memolanguage-ProfileIcon__avatar');
        }

        let tl = (this._tl = new TimelineMax({
            onComplete: () => {
                if (this.categoryCompleted) {
                    this.isFlipped = true;
                }
            }
        }));

        tl.set(iconElem, { scale: 0.5 });
        tl.set(listIndicatorElem, { opacity: 0 });
        // tl.set(iconBorderCircleElem, {transformOrigin: 'center', rotation: -90});
        tl.set(iconElem, { rotationX: 630 });
        tl.to(progressbarIndicatorElem, 1, {
            width: `${((this.parent.learnedItemCount - this.list.itemCount) / this.parent.itemCount) * 100}%`
        });

        tl.fromTo(boxElem, 1, { y: this.element.clientHeight }, { y: 0, ease: Bounce.easeOut });

        // tl.to(iconBorderCircleElem, 0.8, {strokeDashoffset: 0, xrotation: -90});
        // tl.to(iconBorderCircleElem, 0.6, {scale: 0, xtransformOrigin: 'center', xstrokeWidth: 10});
        tl.addLabel('start1', '-=0.5');
        tl.to(
            iconElem,
            1.5,
            {
                scale: 1,
                ease: Bounce.easeOut,
                onStart: () => {
                    soundService.play(tadaSound);
                }
            },
            'start1'
        );
        tl.to(iconElem, 1, { rotationX: 0 }, 'start1');

        // NOTE: Firefox (gsap) not happy animating iconCheckmarkElem.
        // tl.to(iconCheckmarkElem, 1, {className: '+=show', onStart: () => {
        //   soundService.play('/memolanguage/images/sounds/correct-answer.mp3');
        // }});
        // NOTE: Workaround, just using boxElem as a dummy here, because svg elem or [] triggers buggy gsap code:
        tl.to(boxElem, 1, {
            onStart: () => {
                iconCheckmarkElem.classList.add('show');
                soundService.play(correctSound);
            }
        });
        tl.addPause('+=0', () => {
            // calc positions... add to timeline
            let bb = iconElem.getBoundingClientRect();
            let bb2 = avatarCircleElem.getBoundingClientRect();

            let xx = listIndicatorElem.offsetParent.getBoundingClientRect();

            let xAdjust = bb.width / 2 - xx.left;
            let yAdjust = 20 - xx.top;
            let x2Adjust = bb2.width / 2 - xx.left;
            let y2Adjust = bb2.height / 2 - xx.top;

            let bezierPath = [
                { x: bb.left + xAdjust, y: bb.bottom + yAdjust },
                { x: bb.left + xAdjust, y: bb2.top - xx.top + (bb.top - bb2.top) / 3 },
                { x: bb2.left + x2Adjust, y: bb2.top + y2Adjust }
            ];

            tl.set(listIndicatorElem, { x: bb.left + xAdjust, y: bb.bottom + yAdjust });
            tl.to(listIndicatorElem, 1, { opacity: 1 }, '+=0.5');
            tl.to(listIndicatorElem, 1, { bezier: { values: bezierPath }, xscale: 0.5, ease: Sine.easeIn }, '+=0');
            tl.to(listIndicatorElem, 0.5, { scale: 1.2 }, '-=0.8');
            tl.to(listIndicatorElem, 1, { scale: 0.5 }, '-=0.5');
            tl.to(listIndicatorElem, 0.5, {
                scale: 0,
                onStart: () => {
                    //
                }
            });

            tl.to(avatarCircleElem, 0.2, { scale: 1.1 }, '-=0.5');
            tl.to(avatarCircleElem, 0.2, { scale: 1 }, '-=0.3');

            tl.addPause('+=0', () => {
                this._animateCategoryProgress(tl);
            });

            tl.play();
        });
    }

    _animateCategoryProgress(tl) {
        let progressbarIndicatorElem = this.progressbarIndicatorElem;

        tl.to(progressbarIndicatorElem, 1, {
            width: `${(this.parent.learnedItemCount / this.parent.itemCount) * 100}%`,
            ease: Power0.easeNone,
            onStart: () => {
                this._tickTargetItemCount();
            }
        });
        tl.play();
    }
}
