import experimentsService from '@/services/experiments-service';

import { bindable } from 'aurelia-framework';
import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { FlytogetSignupPopup } from '../modals/flytoget-signup-popup';
import { FlytogetSignup2Popup } from '../modals/flytoget-signup2-popup';

import { UpgradePopup } from '../modals/upgrade-popup';

import { Auth } from '../auth';

@inject(DialogService, Auth)
export class CtaBanner {
    @bindable style = 'small';

    constructor(dialogService, auth) {
        this.dialogService = dialogService;
        this.auth = auth;
    }

    showPopup() {
        if (this.auth.isTempUser) {
            let variation = experimentsService.getVariation('flytoget-signup-popup');
            if (variation === 1) {
                this.dialogService.open({ viewModel: FlytogetSignup2Popup });
            } else {
                this.dialogService.open({ viewModel: FlytogetSignupPopup });
            }
        } else {
            this.dialogService.open({ viewModel: UpgradePopup });
        }
    }
}
