export default {
    test: 'Common',
    common: {
        chapter: 'Chapter',
        chapter_no: 'Kapittel'
    },

    languageMap: {
        english: 'English',
        english_no: 'Engelsk',
        norwegian: 'Norwegian',
        norwegian_no: 'Norsk',
        french: 'French',
        french_no: 'Fransk',
        italian: 'Italian',
        italian_no: 'Italiensk',
        german: 'German',
        german_no: 'Tysk',
        chinese: 'Chinese',
        chinese_no: 'Kinesisk',
        portuguese: 'Portuguese',
        portuguese_no: 'Portugisisk',
        spanish: 'Spanish',
        spanish_no: 'Spansk'
    },

    ordinals: {
        '1': '1st',
        '1_no': '1.',
        '2': '2nd',
        '2_no': '2.',
        '3': '3rd',
        '3_no': '3.',
        '4': '4th',
        '4_no': '4.',
        '5': '5th',
        '5_no': '5.'
    },

    itemTypes: {
        word: 'word',
        phrase: 'phrase',
        pronunciation: 'pronunciation'
    },
    itemTypesPlural: {
        word: 'words',
        word_no: 'ord',
        phrase: 'phrases',
        phrase_no: 'setninger',
        pronunciation: 'pronunciations',
        pronunciation_no: 'uttaler',
        mixed: 'items'
    },

    navigation: {
        browseLifeskills: 'Browse',
        browseLifeskills_no: 'Utforsk',
        activity: 'Activity',
        account: 'My subscription',
        account_no: 'Mitt abonnement',
        upgrade: 'Upgrade',
        upgrade_no: 'Oppgrader',
        upgrade2: 'Upgrade now',
        upgrade2_no: 'Oppgrader nå',
        logout: 'Sign out',
        logout_no: 'Logg ut',

        profile: 'Profile',
        profile_no: 'Profil'
    },

    notFoundView: {
        title: 'Oops!',
        subtitle: 'Sorry, we are not able to find what you are looking for.',
        button: 'Back to Homepage'
    },

    loginView: {
        title: 'Learn Fast. Remember Forever.',
        subtitle: 'Learn a new language fast with fun memory tips that will make it stick to your head.',

        emailFieldLabel: 'Email Address',
        passwordFieldLabel: 'Password',

        emailButton: 'Sign in',
        facebookButton: 'Login with Facebook',

        wrongCombo: 'Wrong email/password combination.',
        wrongCombo_no: 'Feil brukernavn eller passord.',

        forgotPassword: 'Forgot your password?',
        forgotPassword_no: 'Glemt passordet ditt?',

        facebookError: 'Facebook login error'
    },

    signupView: {
        title: 'Learn Fast.<br> Remember Forever!',
        title_no: 'Lær hurtig.<br> Husk for alltid!',
        subtitle: 'Sign up to select your language',
        subtitle_no: 'Sign opp for å velge språk',

        alreadyMember: 'Already a member?',
        alreadyMember_no: 'Allerede medlem?'
    },

    forgotPasswordView: {
        title: 'Forgot your password?',
        title_no: 'Glemt passord?',
        email: 'Email',
        email_no: 'E-post',
        instructions:
            'Enter your email below and we will send you an email with instructions on how to reset your password.',
        instructions_no:
            'Fyll inn din epost-adresse nedenfor og vi vil sende deg en epost med instruksjoner på hvordan du setter nytt passord.',
        // instructionsSent: 'Email with instructions on how to reset your password has been sent. Please check your inbox and your spam folder.',
        // instructionsSent_no: 'En epost med instruksjoner har blitt sendt til. Sjekk innboksen og evt. spam-folderen.',
        error: 'An error occured. Are you sure this is your email address?',
        error_no: 'En feil skjedde, er du sikker på at dette er riktig epost-adresse?',
        submit: 'Reset my password',
        submit_no: 'Sett nytt passord'
    },

    forgotPasswordSentView: {
        title: 'Check your mail',
        title_no: 'Sjekk mailen din',
        text:
            'Email with instructions on how to reset your password has been sent. Please check your inbox and your spam folder.',
        text_no: 'En epost med instruksjoner har blitt sendt. Sjekk innboksen og evt. spam-folderen.',
        button: 'Back to Login',
        button_no: 'Tilbake til login'
    },

    setNewPasswordView: {
        title: 'Reset your password',
        title_no: 'Sett nytt passord',
        subtitle: 'You can now set a new password for your account.',
        subtitle_no: 'Du kan nå sette et nytt passord for din brukerkonto.',
        passwordFieldLabel: 'New password',
        passwordFieldLabel_no: 'Nytt passord',
        repeatedPasswordFieldLabel: 'Repeat new password',
        repeatedPasswordFieldLabel_no: 'Repeter nytt passord',
        failed: 'Failed to reset password.',
        failed_no: 'En feil skjedde når vi prøvde å sette nytt passord.',
        submit: 'Set my password',
        submit_no: 'Sett nytt passord'
    },

    languageSelectionView: {
        listHeader: 'I want to learn ...',
        listHeader_no: 'Jeg vil lære ...',

        // signIn: 'Already a user? Sign in',
        // signIn_no: 'Allerede bruker? Logg inn'

        signIn: 'Sign in',
        signIn_no: 'Logg inn',

        switchBaseLanguage: 'Endre til norsk base-språk',
        switchBaseLanguage_no: 'Change to English base language',

        facebookButton: 'Sign up with Facebook',
        facebookButton_no: 'Sign opp med Facebook',
        emailButton: 'or use e-mail',
        emailButton_no: 'eller bruk e-post'
    },

    languageSelection2View: {
        listHeader: 'What do you want to learn fast, while having fun?',
        listHeader_no: 'Hva vil du lære på en hurtig og morsom måte?',

        // signIn: 'Already a user? Sign in',
        // signIn_no: 'Allerede bruker? Logg inn'

        signIn: 'Sign in',
        signIn_no: 'Logg inn',

        switchBaseLanguage: 'Endre til norsk base-språk',
        switchBaseLanguage_no: 'Change to English base language',

        facebookButton: 'Sign up',
        facebookButton_no: 'Sign opp',
        emailButton: 'Sign up with email',
        emailButton_no: 'Sign opp med e-post'
    },

    onboardingView: {
        slide1: {
            masterTitle: 'Learn fast.',
            masterTitle_no: 'Lær hurtig.',
            subtitle: 'with fun memory techniques',
            subtitle_no: 'med morsomme husketeknikker',
            image: '/memolanguage/images/onboarding/slide-1.png',
            title: 'Fun visualizations',
            title_no: 'Morsom visualisering',
            // text: 'Instead of the old parrot-style learning, use visualizations that activate the brain part responsible for emotions and memory. You’ll have fun and learn fast.',
            // text_no: 'Istedenfor den gamle papegøye-måten å lære på, bruker du visualisering for å aktivere hjernedelen som er ansvarlig for følelser og hukommelse. Dermed har du det morsomt og lærer hurtig.'
            preText: 'Use visualizations,',
            preText_no: 'Bruk visualisering,',
            text:
                'not rote learning, to activate the brain part for emotions and memory. Have fun, while learning the language fast.',
            text_no:
                'ikke pugging, for å aktivere hjernedelene for følelser og hukommelse. Ha det gøy, mens du lærer språket hurtig..'
        },
        slide2: {
            masterTitle: 'Remember forever.',
            masterTitle_no: 'Husk for alltid.',
            subtitle: 'with fun memory techniques',
            subtitle_no: 'med morsomme husketeknikker',
            image: '/memolanguage/images/onboarding/slide-2.png',
            title: 'Brain-friendly repetitions',
            title_no: 'Hjernevennlige repetisjoner',
            // text: 'Instead of randomly repeating what you’ve learnt, we’ll remind you to repeat just before your brain starts to forget. You’ll be amazed at how much you can remember forever.',
            // text_no: 'Istedenfor å friske opp kunnskapen helt tilfeldig, minner vi deg på å repetere rett før hjernen begynner å glemme. Du vil bli overrasket over hvor mye du kan huske for alltid.'
            preText: 'No random repetitions.',
            preText_no: 'Ingen tilfeldige repetisjoner.',
            text:
                'Instead, be reminded to repeat just before your brain forgets, so you can remember the language forever.',
            text_no:
                'Vi minner deg på å repetere rett før hjernen begynner å glemme, så du kan huske språket for alltid.'
        },
        slide3: {
            text: '3 words in 3 minutes',
            text_no: '3 gloser på 3 minutter'
        },

        startLearning: 'Start learning',
        startLearning_no: 'Begynn å lære'
    },

    homeView: {
        cards: {
            flytoget: {
                name: 'Memolife Airport Express',
                name_no: 'Flytogets Memolife Express',
                text: 'Learn the vocabularies for your trip fast with fun memory techniques.',
                text_no: 'Lær glosene du trenger til reisen hurtig med morsomme husketeknikker.'
            },
            words: {
                name: 'Words',
                name_no: 'Gloser',
                // text: 'Master the words you need super-fast with fun memory techniques, cognates and spaced repetitions.',
                // text_no: 'Lær lynraskt med morsomme husketeknikker, kognater og hjernebaserte repetisjoner.'
                text: 'Learn new words super-fast with memo tips, ‘frequently used’ and ‘similar words’.',
                text_no: 'Lær nye ord lynraskt med memotips, ‘mest brukte’ og ‘ord som ligner’.'
            },
            pronunciation: {
                name: 'Pronunciation',
                name_no: 'Uttale',
                // text: 'Master the pronunciation rules with fun memory techniques and ‘Englishified subtitles’.',
                // text_no: 'Mestre uttalereglene med morsomme husketeknikker og ‘norskifiserte undertekster’.'
                text: 'Avoid typical pronunciation mistakes. Pronounce letters and syllables correctly.',
                text_no: 'Unngå de klassiske uttalefeilene og lær deg riktig uttale på bokstaver og stavelser.'
            },
            phrases: {
                name: 'Phrases',
                name_no: 'Setninger',
                // text: 'Get dynamic access to phrases based on the words you know and the topics you’re interested in.',
                // text_no: 'Få dynamisk tilgang til setninger basert på glosene du kan og temaene du er interessert i.'
                text: 'Get dynamic access to phrases based on the words you know or take a pick.',
                text_no: 'Få dynamisk tilgang til setninger basert på glosene du kan eller velg dem som du vil.'
            },
            sprints: {
                name: 'Language Sprint',
                name_no: 'Språksprint',
                // text: 'Challenge yourself or your friends with a fun 30, 60 or 100 words per day sprint!',
                // text_no: 'Utfordre deg selv og vennene dine med en morsom 30, 60 eller 100 ord per dag sprint!'
                text: '30, 60 or 100 words in a day is easier than you think. Go solo or challenge someone.',
                text_no: '30, 60 eller 100 ord på en dag er lettere enn du tror. Kjør solo eller utfordre noen.'
            }
        }
    },

    categoryViewWithCarousel: {
        of: 'of',
        of_no: 'av',
        categories: 'categories',
        categories_no: 'kategorier',
        lists: 'lists',
        lists_no: 'lister'
    },

    categoryViewWithBadge: {
        categoryProgress:
            "You've learnt {{ learntCnt }} of {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}}) (from {{ listCnt }} lists)",
        categoryProgress_no:
            'Du har lært {{ learntCnt }} av {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}}) (fra {{ listCnt }} lister)'
    },

    listItem: {
        timeToRepeat: 'Time to repeat',
        timeToRepeat_no: 'På tide å repetere',

        itemProgress: '{{ learntCnt }} / {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}})',
        itemProgress_no: '{{ learntCnt }} / {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}})'
    },

    listView: {
        // For the old view
        progress: {
            label: {
                word: 'Words completed',
                word_no: 'Fullførte gloser',
                phrase: 'Phrases completed',
                phrase_no: 'Fullførte setninger',
                pronunciation: 'Pronunciations completed',
                pronunciation_no: 'Fullførte uttaler'
            }
        },

        // For the old view
        tabLabel: {
            allWords: 'Words',
            allWords_no: 'Gloser',
            allPhrases: 'Phrases',
            allPhrases_no: 'Setninger',
            allPronunciations: 'Pronunciations',
            allPronunciations_no: 'Uttaler',

            repetitions: 'Repetitions',
            repetitions_no: 'Repetisjoner',
            quizStats: 'Quiz stats',
            quizStats_no: 'Quiz resultater',
            wordsIncluded: 'Words included',
            wordsIncluded_no: 'Inkluderte gloser'
        },

        progressText: '{{ learntCnt }} / {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}})',
        progressText_no: '{{ learntCnt }} / {{ totalCnt }} $t(memolanguage:itemTypesPlural.{{type}})',

        learningProgressSection: {
            header: 'Learning Progress',
            header_no: 'Læringsfremgang',
            learnLabel: 'Learn',
            learnLabel_no: 'Lær'
        },

        repetitionPending: 'Your next repetition is',
        repetitionPending_no: 'Din neste repetisjon er',
        repetitionDue: "You're about to forget - repeat now.", // ' to keep remembering.',
        repetitionDue_no: 'Du er iferd med å glemme - repeter nå.', // ' for å fremdeles huske.',

        footer: {
            // No repetition:
            none: {
                initial: {
                    // NOTE: special case: before learning has taken place
                    primary: 'Start Learning',
                    primary_no: 'Begynn å lære',
                    secondary: 'or take the test',
                    secondary_no: 'eller ta testen'
                },
                clean: {
                    primary: 'Start testing',
                    primary_no: 'Start testen',
                    secondary: 'or refresh your learning',
                    secondary_no: 'eller repeter læringen'
                },
                good: {
                    primary: 'Continue with the test',
                    primary_no: 'Fortsett med testen',
                    secondary: 'or continue learning',
                    secondary_no: 'eller lær mer først'
                },
                bad: {
                    primary: 'Continue Learning',
                    primary_no: 'Fortsett å lære',
                    secondary: 'or take the quiz again',
                    secondary_no: 'eller ta testen på nytt'
                }
            },

            // Pending repetition:
            pending: {
                clean: {
                    primary: 'Refresh Learning',
                    primary_no: 'Repeter læringen',
                    secondary: 'or take the quiz again',
                    secondary_no: 'eller ta testen på nytt'
                },
                justCompleted: {
                    primary: 'Continue',
                    primary_no: 'Fortsett',
                    secondary: 'or refresh learning',
                    secondary_no: 'eller repeter læringen'
                },
                good: {
                    primary: 'Continue with the test',
                    primary_no: 'Fortsett med testen',
                    secondary: 'or continue learning',
                    secondary_no: 'eller lær mer først'
                },
                bad: {
                    primary: 'Continue Learning',
                    primary_no: 'Fortsett å lære',
                    secondary: 'or take the test again',
                    secondary_no: 'eller ta testen på nytt'
                }
            },

            // Due or overdue repetition:
            due: {
                clean: {
                    primary: 'Start repetition test',
                    primary_no: 'Start repetisjonstesten',
                    secondary: 'or refresh your learning',
                    secondary_no: 'eller frisk opp læringen'
                },
                good: {
                    primary: 'Continue repetition test',
                    primary_no: 'Fortsett repetisjonstesten',
                    secondary: 'or refresh your learning',
                    secondary_no: 'eller frisk opp læringen'
                },
                bad: {
                    primary: 'Refresh Learning',
                    primary_no: 'Repeter læringen',
                    secondary: 'or start repetition quiz again',
                    secondary_no: 'eller start repetisjonstesten på nytt'
                }
            }
        }
    },

    learnView: {
        exit: 'Go back',
        exit_no: 'Gå tilbake',

        summaryForChapter: 'Summaries for Chapter {{clock}} – {{title}}',
        summaryForChapter_no: 'Sammendrag for kapittel {{clock}} – {{title}}',

        nextWord: 'Next Word',
        nextWord_no: 'Neste glose',
        nextPhrase: 'Next Phrase',
        nextPhrase_no: 'Neste setning',
        next: 'Next',
        next_no: 'Neste',
        done: 'Done',
        done_no: 'Ferdig',

        quiz: 'Start Quiz',
        quiz_no: 'Start quiz'
    },

    pronunciationView: {
        letter: 'The letter',
        letter_no: 'Bokstaven',

        viewMoreExamples: 'View more examples',
        viewMoreExamples_no: 'Se flere eksempler'
    },

    phraseView: {
        memotipsLabel: 'Word Memotips',

        grammartipsLabel: 'Grammar tips'
    },

    quizView: {
        progress: 'Question {{ doneCount }}/{{ totalCount }}',
        progress_no: 'Spørsmål {{ doneCount }}/{{ totalCount }}'
    },

    activityLoadingView: {
        quizLabel: 'Test',
        memorizeLabel: 'Memorization',
        memorizeLabel_no: 'Memorering'
    },

    wordListSidebar: {
        title: 'All Words',
        title_no: 'Alle gloser'
    },

    pronunciationListSidebar: {
        title: 'All Pronunciations',
        title_no: 'Alle uttaler'
    },

    phraseListSidebar: {
        title: 'All Phrases',
        title_no: 'Alle setninger',

        relatedWordsProgress: 'You know {{ completedCount }} of {{ count }} word',
        relatedWordsProgress_plural: 'You know {{ completedCount }} of {{ count }} words',
        relatedWordsProgress_no: 'Du kan {{ completedCount }} av {{ count }} ord',

        learnIndividualWords: 'Learn words',
        learnIndividualWords_no: 'Lær ordene',

        footerText: 'You will learn {{ count }} new word',
        footerText_plural: 'You will learn {{ count }} new words',
        footerText_no: 'Du vil lære {{ count }} nytt ord',
        footerText_plural_no: 'Du vil lære {{ count }} nye ord'
    },

    components: {
        fantasyDisplay: {
            me: 'me',
            me_no: 'meg',

            like: 'I like this memo tip',
            like_no: 'Jeg liker memotipset'
        },
        repetitions: {
            title: 'My Repetitions',
            title_no: 'Mine repetisjoner',

            instruction: 'Repeat your chapters to remember what you have learnt.',
            instruction_no: 'Repeter dine kapitler for å huske hva du har lært.',
            noRepetitions: "You don't have any repetitions!<br/>Good Job!",
            noRepetitions_no: 'Du har ingen repetisjoner.<br/>Bra jobba!'
        },
        repetitionStatus: {
            startLearning: 'Click to start learning',
            startLearning_no: 'Klikk for å starte',
            fresh: 'Next Repetition:',
            fresh_no: 'Neste repetisjon:',
            due: 'Repetition is due',
            due_no: 'Tid for å repetere',
            overdue: 'Repetition is overdue!',
            overdue_no: 'Tid for å repetere!'
        },

        listWordsInPhrasesCard: {
            tip: "Tip: Learn the words you don't know here!",
            tip_no: 'Tips: Lær ordene du ikke kan enda her!',
            tipLink: 'here!',
            tipLink_no: 'her!'
        },

        fantasyEdit: {
            codeLabel: 'Memocode', //Memory code',
            codeLabel_no: 'Huskekode',
            // codePlaceholderText: 'What does the word sound / look like?',
            // codePlaceholderText_no: 'Hva høres ordet som / ser det ut som?',
            codePlaceholderText: 'What the word reminds you of (sound/spellinig).',
            codePlaceholderText_no: 'Hva ligner ordet på (høres/skrives)?',

            fantasyLabel: 'Memo Fantasy',
            fantasyLabel_no: 'Huskefantasi',
            // fantasyPlaceholderText: 'Link your memory code into a fun, visual story ...',
            // fantasyPlaceholderText_no: 'Lag en morsom, visuell historie med huskekoden ...',
            fantasyPlaceholderText: 'Make a fun, visual story that links the memo code to the English word.',
            fantasyPlaceholderText_no: 'Lag en morsom, visuell historie som kobler huskekoden til det norske ordet.',

            cancelButton: 'Cancel',
            cancelButton_no: 'Avbryt',
            doneButton: 'Done',
            doneButton_no: 'Ferdig'
        },

        subcategoryItem: {
            completed: 'Completed',
            completed_no: 'Fullført'
        }
    },

    fantasyPlaceholder: {
        message: 'Add you own fun mnemonic',
        message_no: 'Lag ditt eget morsomme husketips',

        signupMessage: '<b class="signupLink">Sign up</b> for free to add your own memory tips',
        signupMessage_no: '<b class="signupLink">Logg inn</b> gratis for å lage dine egne husketips',

        addLabel: 'Add memo tip',
        addLabel_no: 'Lag husketips'
    },

    listFinished: {
        toRepeat: 'You have to repeat the list in 1 day',
        toRepeat_no: 'Du må repetere denne listen etter 1 dag',
        completedList: 'You completed',
        completedList_no: 'Du ble ferdig med',
        points: 'learning points',
        points_no: 'læringspoeng',
        congratulations: 'Congratulations',
        congratulations_no: 'Gratulerer'
    },

    notificationSidebar: {
        title: 'Notifications',
        title_no: 'Varslinger',
        repetitionsLabel: 'Your Repetitions',
        repetitionsLabel_no: 'Dine repetisjoner',

        // repetitionsText: 'Time to repeat these lists',
        // repetitionsText_no: 'På tide å repetere disse listene',

        unlockedPhrasesLabel: 'Unlocked Phrases',
        unlockedPhrasesLabel_no: 'Nye setninger',

        viewAll: 'View all',
        viewAll_no: 'Se alle',

        repetitionSpeakbubble: {
            face: '/memolanguage/images/frank-avatar.jpg',
            name: 'Frank Wedde, CEO Memolife',
            text: 'Hi {{ name }}, the spaced repetitions can empower you to remember forever.',
            text_no: 'Hei {{ name }}, repetisjonene følger en syklus som kan gjøre det mulig å huske for alltid.',
            linkText: 'Learn more here',
            linkText_no: 'Lær mer her'
        }
    },

    unlockedPhraseItem: {
        message: 'You have unlocked a new phrase',
        message_no: 'Du har tilgang til en ny setning'
    },

    // Dialogs

    wordsInPhrasesDialog: {
        title: 'Words included',
        title_no: 'Inkluderte gloser',

        button: "Learn the words you don't know",
        button_no: 'Lær ordene du ikke kan'
    },

    quizResultPopup: {
        good: {
            title: 'Almost there',
            title_no: 'Nesten',
            message: 'A couple more and you’ll know it all. Continue with the quiz!',
            message_no: 'Et par til, så kan du alle. Fortsett med quiz!',
            button: 'Continue testing',
            button_no: 'Test videre'
        },
        bad: {
            title: 'Let’s learn some more',
            title_no: 'Fortsett med læring',
            message: 'Re-learn the list and you’ll ace the quiz before you know it.',
            message_no: 'Lær listen om igjen, og du vil klare quizen på 1-2-3.',
            button: 'Continue learning',
            button_no: 'Lær videre'
        },
        perfect: {
            title: 'Great',
            title_no: 'Flott',
            message: 'You answered all questions correctly!',
            message_no: 'Du svarte rett på alle spørsmålene!',
            button: 'Continue',
            button_no: 'Fortsett'
        },
        resultText: '{{ correctCount }}/{{ totalCount }} items correct',
        resultText_no: '{{ correctCount }}/{{ totalCount }} riktige svar'
    },

    listCompletedPopup: {
        title: 'Nice Work!',
        title_no: 'Godt jobbet!',
        subtitle: 'You have completed "{{ listName }}"',
        subtitle_no: 'Du har fullført "{{ listName }}"',
        firstRepetition: 'First repetition in 24 hours',
        firstRepetition_no: 'Første repetisjon om 24 timer',

        preTitle: 'New Award',
        preTitle_no: 'Fullført liste',

        // challengeYourFriends: 'Challenge your friends',
        // challengeYourFriends_no: 'Del med vennene dine',
        challengeYourFriends: 'Share',
        challengeYourFriends_no: 'Del',

        plussOneList: '+ 1 list',
        plussOneList_no: '+ 1 liste',

        learntXofY: 'You learnt <span>{{ x }}</span> of <span>{{ y }}</span>',
        learntXofY_no: 'Du har lært <span>{{ x }}</span> av <span>{{ y }}</span>',

        continue: 'Continue',
        continue_no: 'Fortsett',

        badgeCompletion: {
            subtitle: 'Impressive, you completed the badge',
            subtitle_no: 'Imponerende, du fullførte kategorien',

            share: 'Share your success!',
            share_no: 'Del opplevelsen din!',

            upgrade: 'Upgrade to continue',
            upgrade_no: 'Oppgrader for å fortsette'
        }
    },

    spacedRepetitionPopup: {
        title: 'Remember forever',
        title_no: 'Husk for alltid',
        subtitle: 'by reversing your brain’s forgetting curve',
        subtitle_no: 'ved å reversere hjernens glemselskurve',
        text:
            'We’ll remind you to repeat just before your brain statistically starts to forget. Follow the spaced repetition intervals and you’ll be amazed at how much you will remember forever.',
        text_no:
            'Vi minner deg på å reptere rett før hjernen statistisk sett begynner å glemme. Følger du repetisjonsintervallene vil du bli overrasket over hvor mye du kan huske for alltid.',
        // image: '/memolanguage/images/remember-forever.png',
        image: '/memolanguage/images/graph-repetition.png'
    },

    noSoundPopup: {
        shortMessage: 'Having problems with the sound?',
        shortMessage_no: 'Har du problemer med lyden?',
        title: 'If you have a sound problem ...',
        title_no: 'Dersom du har lydproblemer ...',
        text: 'Unmute your phone to enable your loudspeakers or use a headset to hear the sounds.',
        text_no: 'Skru av stille-modus for å aktivere høytalerne eller bruk øretelefoner for å høre lyden.',

        close: 'Close',
        close_no: 'Lukk'
    },

    upgradePopup: {
        title: 'Få full tilgang til Memolife',
        subtitle: 'Akselerert læring av kunnskap og ferdigheter',
        button: 'Få full tilgang nå',
        items: [
            {
                icon: '/memolanguage/images/upgrade/icon-one.svg',
                title: 'Akselerert språklæring',
                subtitle: 'Gloser, uttale, setninger, grammatikk'
            },
            {
                icon: '/memolanguage/images/upgrade/icon-two.svg',
                title: '100 interesseområder',
                subtitle: 'Kunnskap, ferdigheter og vaner'
            },
            {
                icon: '/memolanguage/images/upgrade/icon-three.svg',
                title: 'Morsomme teknikker',
                subtitle: 'Vitenskapbasert og praktisk'
            }
        ],
        text: 'You only need 5 minutes per day',
        text_no: 'Du trenger kun 5 minutter per dag'
    },

    signupPopup: {
        title: 'Sign up for free and<br> save your progress!',
        title_no: 'Sign opp gratis og<br> lagre fremgangen din!',
        text: 'Complete <b>Memolife Express</b> on the plane<br> and challenge your friends.',
        text_no: 'Fullfør <b>Memolife Express</b> på flyet<br> og utfordre vennene dine',
        facebook: 'Sign up with Facebook',
        facebook_no: 'Sign opp med facebook',
        email: 'or create a Memolife profile with e-mail',
        email_no: 'eller lag en Memolife profil med e-post',

        emailForm: {
            title: 'Sign up',
            emailLabel: 'E-mail',
            passwordLabel: 'Passord',
            submit: 'Sign up',
            back: 'Back',
            unknownError: 'Unknown error',
            unknownError_no: 'Ukjent feil',

            alreadyUsed: 'already used',
            alreadyUsed_no: 'allerede brukt',

            required: 'is required',
            required_no: 'er påkrevd!',

            invalid: 'is not valid',
            invalid_no: 'er ikke gyldig',

            minLength: 'must be at least 8 character',
            minLength_no: 'må ha minst 8 tegn'
        },

        // For standard signupPopup
        title2: 'Signup',
        title2_no: 'Registrering',
        or: 'or',
        or_no: 'eller'
    },

    flytogetSignup2Popup: {
        premium: 'Get premier features',
        premium_no: 'Med premium funksjonalitet',
        freeWords: '100 Words Free',
        freeWords_no: '100 gloser gratis',
        redeemGift: 'Sign up to redeem your gift!',
        redeemGift_no: 'Sign opp for å motta gavekortet!',

        email: 'Or use your email',
        email_no: 'Eller sign opp med e-post'
    },

    signupBanner: {
        text: 'Sign up for free',
        text_no: 'Sign opp gratis',

        textLarge: 'Sign up for free to save your progress',
        textLarge_no: 'Sign opp gratis for å lagre fremgangen',

        upgradeText: 'Upgrade',
        upgradeText_no: 'Oppgrader',
        upgradeLargeText: 'Upgrade to get full access',
        upgradeLargeText_no: 'Oppgrader for å få full tilgang'
    },

    checkoutPopup: {
        title: 'Checkout',
        title_no: 'Utsjekk',

        salesTerms: 'Terms and Conditions',
        salesTerms_no: 'Salgsbetingelser'
    },

    onboardingTooltips: {
        word1: {
            tooltip1Action1: 'Memorize your first word',
            tooltip1Action1_no: 'Memorer ditt første ord',

            tooltip2Title: "Here's a memo tip",
            tooltip2Title_no: 'Dette er et memotips',
            tooltip2Text: "Use what the word '{{ targetWord }}' reminds you of and visualize a fun story.",
            tooltip2Text_no: "Bruk hva ordet '{{ targetWord }}' minner deg om og visualiser en morsom historie.",
            tooltip2Action1: 'Got it',
            tooltip2Action1_no: 'Skjønner',

            // tooltip3Title: 'Browse memo tips',
            // tooltip3Title_no: 'Utforsk memotips',
            // tooltip3Text: 'Browse through the memo tips to find the one you like the most.',
            // tooltip3Text_no: 'Bla deg gjennom husketipsene for å finne det du liker best.',
            // tooltip3Action2: 'Got it',
            // tooltip3Action2_no: 'Skjønner',
            tooltip3Title: 'Funny, logical or strange',
            tooltip3Title_no: 'Morsomme, logiske eller rare',
            tooltip3Text: 'The memotips help you learn faster. Swipe and explore.',
            tooltip3Text_no: 'Memotipsene hjelper deg lære hurtig. Bla deg videre og utforsk.',

            tooltip4Title: 'Choose memo tips',
            tooltip4Title_no: 'Velg memotips',
            tooltip4Text: 'Click like on the memotip you prefer.', //', or sign up to create your own.',
            tooltip4Text_no: 'Klikk liker på memotipset du foretrekker.', //', eller sign opp for å lage ditt eget.',
            tooltip4Action2: "Let's start",
            tooltip4Action2_no: 'La oss starte'
        },
        word2: {
            tooltip1Title: 'Exaggerate',
            tooltip1Title_no: 'Overdriv',
            tooltip1Text: 'When you exaggerate your "memory cartoons" work even better.',
            tooltip1Text_no: 'Overdriv litt ekstra, så vil "huske-tegnefilmene" fungere enda bedre.',
            tooltip1Action1: 'Got it',
            tooltip1Action1_no: 'Skjønner'
        },
        word3: {
            tooltip1Title: 'Use emotions',
            tooltip1Title_no: 'Bruk følelser',
            tooltip1Text:
                'When you add emotional, romantic and funny elements you also activate the brain part responsible for memory.',
            tooltip1Text_no:
                'Legg til følelse, romantikk eller humor, så aktiviserer du hjernedelen som er ansvarlig for hukommelse.',
            tooltip1Action1: 'Got it',
            tooltip1Action1_no: 'Skjønner'
        },

        phrase1: {
            tooltip1Title: 'Word Memory tip',
            tooltip1Text: 'Swipe the cards to view the memorytips of each individual word.'
        },
        phrase2: {
            tooltip1Title: 'Verb conjugation',
            tooltip1Text: 'Click the Verb button to see all the conjugations in all tenses.'
        }
    },

    tempListDefaultName: {
        itemsOfType: {
            word: 'Word list',
            word_no: 'Ordliste',
            phrase: 'Phrase list',
            phrase_no: 'Setningsliste',
            pronunciation: 'Pronunciation list',
            pronunciation_no: 'Uttaleliste',
            mixed: 'List',
            mixed_no: 'Liste'
        }
    },

    conjugationsPopup: {
        comingSoon: 'We are working to add all the conjugations soon - stay tuned.',
        comingSoon_no: 'Vi er i ferd med å legge til alle konjugeringer - kommer snart.'
    }
};
