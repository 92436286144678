export class WordListSidebar {
    loaded = false;

    activate(list) {
        this.list = list;
        this.list.ensureItemsLoaded().then(() => {
            this.loaded = true;
        });
    }
}
