import { bindable } from 'aurelia-framework';

export class FantasyCard {
    @bindable fantasy;
    @bindable options = { hideCode: false };
    flipped = false;
    flipping = false;

    _flip() {
        this.flipped = true;
        this._startFlippingHack();
    }

    _unflip() {
        this.flipped = false;
        this._startFlippingHack();
    }

    // Flipping hack needed for Firefox. Because we must avoid having perspective set in css before it is needed. Otherwise we get strange flicker when the page is transitioning
    _startFlippingHack() {
        clearTimeout(this._flippingT);
        this.flipping = true;
        this._flippingT = setTimeout(() => (this.flipping = false), 1000);
    }

    edit() {
        this._flip();
    }

    cancelEdit() {
        this._unflip();
    }

    editDone() {
        this._unflip();
    }
}
