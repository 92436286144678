export default function preloadImages(imageUrls) {
    function loadImage(url) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = e => {
                resolve(true);
            };
            image.onerror = e => {
                resolve(false);
            };
            image.src = url;
        });
    }
    let promises = imageUrls.map(imageUrl => loadImage(imageUrl));
    return Promise.all(promises);
}
