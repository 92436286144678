import { bindable } from 'aurelia-framework';

export class PhraseItem {
    @bindable phrase;

    bind() {
        this.phraseWithMarkup = this.phrase.factText;
        this.phrase.getPhraseWithMarkup().then(phraseWithMarkup => (this.phraseWithMarkup = phraseWithMarkup));
    }
}
