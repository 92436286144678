import { bindable } from 'aurelia-framework';

export class QuizViewer {
    @bindable quizItemSession = null;

    configs = [];

    quizItemSessionChanged(newValue, oldValue) {
        this._update();
    }

    _update() {
        if (this.quizItemSession) {
            this.configs.push({ viewModel: this.quizItemSession.viewModelId, model: this.quizItemSession });
            if (this.configs.length > 1) {
                // NOTE: This timeout is needed in order to trigger both 'leave' AND 'enter' animations for items in the Aurelia repeat construct
                // setTimeout(() => {
                this.configs.shift();
                // }, 0);
            }
        } else {
            this.configs.shift();
        }
    }
}
