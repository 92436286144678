import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';

import { QuizService } from '../quiz/services/quiz-service';
import { DataService } from '../services/data-service';
import { RepetitionService } from '../services/repetition-service';
import { DebugService } from '../services/debug-service';

import { LanguageScoreEngine } from '../language-scoring-engine';
import { QuizResultPopup } from '../modals/quiz-result-popup';
import { ListCompletedPopup } from '../modals/list-completed-popup';

@inject(DataService, Router, QuizService, EventAggregator, RepetitionService, DebugService, I18N, DialogService)
export class QuizView {
    current = null;
    started = false;
    challenge = null;
    progressInQuiz = 0;
    showingResult = false;

    list = null;

    constructor(dataService, router, quizService, ea, repetitionService, debugService, i18n, dialogService) {
        this.dataService = dataService;
        this.router = router;
        this.quizService = quizService;
        this.ea = ea;
        this.repetitionService = repetitionService;
        this.debugService = debugService;
        this.i18n = i18n;
        this.dialogService = dialogService;
    }

    async activate(params) {
        this.collectionId = params.collectionId;
        this.appPackageName = this.dataService.activePackageName;
        this.listId = params.listId;

        this._registerDebugCommands();

        await this.dataService.ensureLanguageLoaded();

        return this.dataService.getList(params.listId).then(list => {
            this.list = list;

            return list.createQuestionProvider().then(questionProvider => {
                let quizSessionOptions = {
                    appPackageId: this.appPackageName,
                    extraData: {
                        collectionId: params.listId
                    },
                    scoringEngine: LanguageScoreEngine,
                    questionProvider: questionProvider
                };

                this.quizService.initiateSession(quizSessionOptions).then(quizSession => {
                    this.quizSession = quizSession;
                    this.scoreEngine = this.quizSession.scoreEngine;

                    this.quizSession.start({
                        onNextQuizItem: this.onNextQuizItem.bind(this),
                        onQuizCompleted: this.onQuizCompleted.bind(this)
                    });
                });
            });
        });
    }

    _registerDebugCommands() {
        this.debugService.add(
            'fakeCompletion',
            () => {
                this.quizSession.abort();
                if (this.quizSession.currentQuizItemSession) {
                    this.quizSession.currentQuizItemSession.score = 1;
                    this.quizSession.currentQuizItemSession.time = 1000;
                    this.quizSession.completedQuizItemSessions.push(this.quizSession.currentQuizItemSession);
                }
                let quizItem = this.quizSession.questionProvider.next();
                while (quizItem) {
                    this.quizSession.completedQuizItemSessions.push({
                        score: 1,
                        time: 1000,
                        timeUsedOnQuestion: 1,
                        model: quizItem
                    });
                    quizItem = this.quizSession.questionProvider.next();
                }
                this.quizSession.onCompleted();
            },
            this
        );

        this.debugService.add(
            'pause/resume clock',
            () => {
                if (this.quizSession.currentQuizItemSession) {
                    this.quizSession.currentQuizItemSession.dbgToggleTimer();
                }
            },
            this
        );
    }

    deactivate() {
        this.debugService.remove(this);

        if (!this.quizSession.complete) {
            this.quizSession.abort();
        }
    }

    onNextQuizItem(quizItemSession) {
        quizItemSession.on('commit', () => {
            this._updateProgressBar();
        });
        this._updateProgressBar();
    }

    onQuizCompleted() {
        this.progressInQuiz = 1;

        return this.list
            .updateQuizState(
                this.quizSession.completedQuizItemSessions.map(itemSession => {
                    return {
                        source: itemSession.model.source,
                        score: itemSession.score,
                        time: itemSession.timeUsedOnQuestion * 1000
                    };
                }),
                true
            )
            .then(() => {
                let showQuizResultPopup = false;
                let showListCompletedPopup = false;

                let quizState = this.list.getQuizState();

                if (quizState.completionFraction === 1) {
                    if (this.list.requestUpgradeOnCompletion) {
                        this.dataService.memolanguageSettings.signupOrUpgradeRequested = 'upgrade';
                    } else {
                        this.dataService.memolanguageSettings.signupOrUpgradeRequested = true;
                    }
                }
                if (this.list.onboarding) {
                    // NOTE: If completing a quiz in onoarding flow, we go back to list selection view and will NOT automatically show any signup/upgrade popups there
                    this.dataService.memolanguageSettings.signupOrUpgradeRequested = false;
                }

                if (this.list.isTemporary) {
                    showQuizResultPopup = true;
                } else {
                    if (quizState.firstTimeCompleted) {
                        showListCompletedPopup = true;
                    } else {
                        showQuizResultPopup = true;
                    }
                }

                if (this.list.onboarding) {
                    quizState.justUpdated = false;
                }

                if (showQuizResultPopup) {
                    return this.showQuizResultPopup();
                } else if (showListCompletedPopup) {
                    return this.showListCompletedPopup();
                } else {
                    if (this.list.navigationAfterQuiz) {
                        return this.router.navigateToRoute(
                            this.list.navigationAfterQuiz.name,
                            this.list.navigationAfterQuiz.params
                        );
                    }
                    return this.router.navigateToRoute('list', { listId: this.list.id });
                }
            });
    }

    _updateProgressBar() {
        if (this.quizSession.questionCount) {
            // Not all quiz-types will have a fixed question count.
            let questionIndex = this.quizSession.index;
            if (this.quizSession.currentQuizItemSession && this.quizSession.currentQuizItemSession.commited) {
                questionIndex += 1; // NOTE: In order for progressbar to react right after commit, and not wait until next question is ready
            }
            this.progressInQuiz = questionIndex / this.quizSession.questionCount;
        }
    }

    showQuizResultPopup() {
        this.showingResult = true;
        this.dialogService.open({ viewModel: QuizResultPopup, model: this.list }).whenClosed(response => {
            if (!response.wasCancelled) {
                if (response.output === 'continueLearning') {
                    this.router.navigateToRoute('memorize-loading', { listId: this.list.id });
                } else if (response.output === 'continueTesting') {
                    this.router.navigateToRoute('quiz-loading', { listId: this.list.id });
                }
            } else {
                if (this.list.navigationAfterQuiz) {
                    return this.router.navigateToRoute(
                        this.list.navigationAfterQuiz.name,
                        this.list.navigationAfterQuiz.params
                    );
                } else if (this.list.onboarding) {
                    return this.router.navigateToRoute('category', { categoryId: this.list.parent.id });
                } else {
                    return this.router.navigateToRoute('list', { listId: this.list.id });
                }
            }
        });
    }

    showListCompletedPopup() {
        this.showingResult = true;
        return this.dialogService.open({ viewModel: ListCompletedPopup, model: this.list }).whenClosed(response => {
            if (this.list.onboarding) {
                return this.router.navigateToRoute('category', { categoryId: this.list.parent.id });
            } else {
                return this.router.navigateToRoute('list', { listId: this.list.id });
            }
        });
    }

    exit() {
        return this.list
            .updateQuizState(
                this.quizSession.completedQuizItemSessions.map(itemSession => {
                    return {
                        source: itemSession.model.source,
                        score: itemSession.score,
                        time: itemSession.timeUsedOnQuestion * 1000
                    };
                })
            )
            .then(() => {
                if (this.list.navigationOnExit) {
                    return this.router.navigateToRoute(
                        this.list.navigationOnExit.name,
                        this.list.navigationOnExit.params
                    );
                }
                return this.router.navigateToRoute('list', { listId: this.listId });
            });
    }
}
