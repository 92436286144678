import { bindable } from 'aurelia-framework';

import soundService from '@/services/sound-service';

export class WordAudio {
    @bindable word;
    @bindable autoplay = true;
    voice = 'male';
    soundUrl = null;

    bind() {
        if (this.word.tts.male) {
            this.voice = 'male';
        } else if (this.word.tts.female) {
            this.voice = 'female';
        } else {
            this.voice = null;
        }
        if (this.voice !== null) {
            this.soundUrl = this.word.tts[this.voice];
        }
    }

    attached() {
        // LATER: Probably preload sound
        if (this.autoplay) {
            this._t = setTimeout(this.play.bind(this), 1000);
        }
    }

    detached() {
        clearTimeout(this._t);
    }

    play(initiatedByUser) {
        if (this.soundUrl) {
            soundService.play(this.soundUrl, initiatedByUser);
        }
    }
}
