import { PLATFORM } from 'aurelia-framework';
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

import { DataService } from '../services/data-service';

PLATFORM.moduleName('./word-popup');

@inject(DialogController, DataService, Element)
export class WordPopup {
    word = null;
    fantasyIndex = 0;
    filteredFantasies = [];
    translation = '';

    constructor(controller, dataService, element) {
        this.controller = controller;
        this.dataService = dataService;
        this.element = element;
    }

    activate(word) {
        this.word = word;
        this.translation = this.word.translationString;
        this.pronunciation = this.word.pronunciation;
        if (!this.pronunciation) {
            let pronunciation = this.word.pronunciations.find(p => {
                return p.from_language === this.dataService.activeSourceLanguage;
            });

            if (pronunciation) {
                this.pronunciation = pronunciation.pronunciation;
            }
        }

        // NOTE: We assume that word.fantasies will not change while this view is active.
        this._updateFilteredFantasies();

        let fantasyIndex = this.filteredFantasies.findIndex(item => item.id && item.id === this.word.currentFantasyId);
        if (fantasyIndex > -1) {
            this.fantasyIndex = fantasyIndex;
        } else {
            this.fantasyIndex = 0;
        }
    }

    attached() {
        let cls = 'memolanguage-WordPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);

        if (this.filteredFantasies.length === 1 && !this.filteredFantasies[0].id) {
            this._t = setTimeout(() => {
                let elem = document.querySelector('.memolanguage-FantasyPlaceholder__createButton');
                if (elem) {
                    elem.click();
                }
            }, 1000);
        }
    }

    detached() {
        clearTimeout(this._t);
    }

    _updateFilteredFantasies() {
        this.filteredFantasies = this.word.getFilteredFantasies({
            addEmptyFantasy: true
        });
    }
}
