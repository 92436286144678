import { shuffleArray } from './util';

export class QuestionProvider {
    queue = [];
    numberOfQuestions = 0;
    lastQuizItem = null;

    constructor(options, appPackageOptions) {
        let tests = [];
        let dataPool = options.dataPool || options.testData;
        let maxChoicesPerTest = 5;
        let maxTests = options.maxTests || 6;

        options.generators.forEach(generatorConfig => {
            let easy = generatorConfig.easy || [];
            easy.forEach(generatorFunc => {
                let easyTests = generatorFunc(options.testData, dataPool, maxChoicesPerTest, appPackageOptions);
                easyTests.forEach(t => {
                    t.difficulty = 'low';
                    t.sectionTitle = generatorConfig.title;
                });
                tests = tests.concat(easyTests);
            });

            if (options.shuffle) {
                tests = shuffleArray(tests);
            }

            if (maxTests !== 'all') {
                tests = tests.splice(0, this.maxTests);
            }
        });

        let alreadySeen = {};
        tests = tests.filter(quizItem => {
            if (alreadySeen[quizItem.source]) {
                if (alreadySeen[quizItem.source].type === 'listen' && quizItem.type !== 'listen') {
                    alreadySeen[quizItem.source].alternative = quizItem;
                }
                return false;
            } else {
                alreadySeen[quizItem.source] = quizItem;
                return true;
            }
        });

        this.queue = tests;
        this.numberOfQuestions = this.queue.length;
    }

    next(options) {
        if (options && options.convert && this.lastQuizItem.alternative) {
            this.queue.unshift(this.lastQuizItem.alternative);
        }

        this.lastQuizItem = this.queue.shift() || null;
        return this.lastQuizItem;
    }
}
