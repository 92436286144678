/* global PLATFORM */

import { inject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SidebarService } from './services/sidebar-service';
import { DataService } from './services/data-service';
import { ThemeController } from './theme-controller';

import store from '@/store';
import vueRouter from '@/router';

import { RouteTransitionConfigurationStep } from './route-transition-helper';

let COUNT = 1;

@inject(I18N, SidebarService, DataService, ThemeController, EventAggregator)
export class MemolanguageRoot {
    @bindable sourceLanguage;
    @bindable targetLanguage;

    // Sidebar stuff:
    componentId = null;
    model = null;
    placement = '';
    sidebarIsExpanded = false;
    preparing = false;

    constructor(i18n, sidebarService, dataService, themeController, ea) {
        this.i18n = i18n;
        this.sidebarService = sidebarService;
        this.dataService = dataService;
        this.themeController = themeController;
        this.ea = ea;

        this.COUNT = COUNT++;
    }
    created() {
        this.i18n.setLocale(store.getters['moduleApp/locale']);
    }

    configureRouter(config, router) {
        const branch = process.env.GIT_BRANCH;
        const prefix = window.location.pathname.startsWith(`/${branch}/`) ? `/${branch}` : '';

        config.options.root = `${prefix}/memolanguage/${this.targetLanguage}`;
        config.options.pushState = true;

        config.addPreActivateStep(RouteTransitionConfigurationStep);

        config.map([
            {
                route: '',
                name: 'default',
                redirect: 'home'
            },
            {
                route: 'home',
                name: 'home',
                moduleId: PLATFORM.moduleName('./views/home-view'),
                nav: false,
                title: 'Select Language',
                navigationStrategy: instruction => {
                    delete instruction.config.redirect;
                    if (!this.dataService.memolanguageSettings.onboardingDone) {
                        instruction.config.redirect = 'onboarding';
                    }
                }
            },
            {
                route: 'onboarding',
                name: 'onboarding',
                moduleId: PLATFORM.moduleName('./views/onboarding-view'),
                nav: false,
                title: 'xxxxx'
            },
            {
                route: 'category/:categoryId',
                name: 'category',
                moduleId: PLATFORM.moduleName('./views/category-view'),
                nav: false,
                title: 'Category',
                settings: { canShowSignupOrUpgrade: true }
            },
            {
                route: 'list/:listId',
                moduleId: PLATFORM.moduleName('./views/list-view'),
                name: 'list',
                nav: false,
                title: 'List',
                auth: true,
                settings: { canShowSignupOrUpgrade: true }
            },
            {
                route: 'list/:listId/memorize-loading',
                name: 'memorize-loading',
                moduleId: PLATFORM.moduleName('./views/activity-loading-view'),
                nav: false,
                title: 'Quiz',
                auth: true,
                settings: { next: 'memorize' }
            },
            {
                route: 'list/:listId/memorize',
                name: 'memorize',
                moduleId: PLATFORM.moduleName('./views/learn-view'),
                nav: false,
                title: 'Learn',
                auth: true
            },
            {
                route: 'list/:listId/quiz-loading',
                name: 'quiz-loading',
                moduleId: PLATFORM.moduleName('./views/activity-loading-view'),
                nav: false,
                title: 'Quiz',
                auth: true,
                settings: { next: 'quiz' }
            },
            {
                route: 'list/:listId/quiz',
                name: 'quiz',
                moduleId: PLATFORM.moduleName('./views/quiz-view'),
                nav: false,
                title: 'Quiz',
                auth: true
            }
        ]);
        this.router = router;
    }

    _updateTheme() {
        let currentInstruction = this.router.currentInstruction;

        let affiliateTheme = null;
        if (currentInstruction.params.categoryId) {
            if (currentInstruction.params.categoryId === '580') {
                affiliateTheme = 'flytoget';
            } else {
                affiliateTheme = '';
            }
        } else if (currentInstruction.params.listId) {
            let list = this.dataService.getSync('c-' + currentInstruction.params.listId);
            if (list && list.parent && list.parent.id === 580) {
                affiliateTheme = 'flytoget';
            } else {
                affiliateTheme = '';
            }
        } else if (['select-language', 'root', 'onboarding'].indexOf(currentInstruction.config.name) !== -1) {
            if (this.affiliate === 'flytoget') {
                affiliateTheme = 'flytoget';
            } else {
                affiliateTheme = '';
            }
        }

        if (affiliateTheme !== null) {
            this.themeController.set(affiliateTheme);
        }
        // NOTE: When affiliateTheme is null, the theme must be controlled by the current view;
    }

    exit() {
        vueRouter.push({ name: 'DashboardHomeView' });
    }

    // Sidebar stuff:
    showSidebar(options) {
        this.componentId = options.viewModel;
        this.componentId = this.componentId.substr(1);
        this.model = options.model;
        this.placement = options.placement || 'left';
        this.preparing = true;

        if (!options.waitForViewModel) {
            setTimeout(() => {
                this.preparing = false;
                this.sidebarIsExpanded = true;
            }, 0);
        }
    }

    openReady() {
        setTimeout(() => {
            this.preparing = false;
            this.sidebarIsExpanded = true;
        }, 10);
    }

    hideSidebar() {
        if (!this.sidebarIsExpanded) {
            return;
        }
        this.sidebarIsExpanded = false;
        let lastComponentId = this.componentId;
        let lastModel = this.model;
        setTimeout(() => {
            if (lastComponentId === this.componentId && lastModel === this.model) {
                this.componentId = null;
                this.model = null;
                this.placement = '';
            }
        }, 600);
    }

    attached() {
        this.sidebarService.register(this);

        this._subscriptionRouterNavigationSuccess = this.ea.subscribe('router:navigation:complete', response => {
            this._updateTheme();
            // this._showSignupOrUpgradePopup();
            this._previousNavigationInstruction = this.router.currentInstruction;
        });
    }

    detached() {
        // this.sidebarService.hide();
        this.sidebarService.unregister(this);
        this.router.deactivate();
        this.router.reset();
        this.router.container.viewModel = null; // NOTE: needed in order to prevent AppRouter from using previous this viewModel next time we enhance
        this._subscriptionRouterNavigationSuccess.dispose();
        this.dataService.unloadActiveBundle();
    }
}
