import { inject, BindingEngine } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

import { TooltipService } from '../services/tooltip-service';
import { DataService } from '../services/data-service';

import * as wordTooltips from './word__tooltips';

@inject(DataService, EventAggregator, DialogService, BindingEngine, TooltipService, I18N)
export class Word {
    word = null;

    itemId = null;
    fantasyIndex = 0;
    filteredFantasies = [];

    constructor(dataService, ea, dialogService, bindingEngine, tooltipService, i18n) {
        this.dataService = dataService;
        this.ea = ea;
        this.dialogService = dialogService;
        this.bindingEngine = bindingEngine;
        this.tooltipService = tooltipService;
        this.i18n = i18n;
    }

    determineActivationStrategy(params) {
        return activationStrategy.replace;
    }

    activate(params, routeConfig, navigationInstruction) {
        this.listId = params.listId;
        this.itemId = params.itemId;
        this._previousInstruction = navigationInstruction.previousInstruction;

        return this.dataService
            .getList(this.listId)
            .then(list => list.ensureItemsLoaded())
            .then(list => {
                this.list = list;
                this.word = list.items.find(item => '' + item.id === this.itemId);
                this.translation = this.word.translationString;

                this.pronunciation = this.word.pronunciation;
                if (!this.pronunciation) {
                    let pronunciation = this.word.pronunciations.find(p => {
                        return p.from_language === this.dataService.activeSourceLanguage;
                    });

                    if (pronunciation) {
                        this.pronunciation = pronunciation.pronunciation;
                    }
                }

                // NOTE: Disabled, because we handle this a different way in fantasy-display card
                // this._fantasyIndexSubscription = this.bindingEngine.propertyObserver(this, 'fantasyIndex')
                // .subscribe((newValue, oldValue) => {
                //   let fantasy = this.filteredFantasies[newValue];
                //   if (fantasy.id) {
                //     this.word.setCurrentFantasy(fantasy.id);
                //   }
                // });

                // NOTE: We assume that word.fantasies will not change while this view is active.
                this._numberOfFantasies = this.word.getFilteredFantasies().length;
                this._updateFilteredFantasies();

                let fantasyIndex = this.filteredFantasies.findIndex(
                    item => item.id && item.id === this.word.currentFantasyId
                );
                if (fantasyIndex > -1) {
                    this.fantasyIndex = fantasyIndex;
                } else {
                    this.fantasyIndex = 0;
                }
            });
    }

    deactivate() {
        // this._fantasyIndexSubscription.dispose();
        this.tooltipService.clear();
    }

    attached() {
        this._initiateTooltip();
    }

    _checkMightShowTooltip() {
        if (this.list.isTemporary) {
            return false;
        }
        let wordIndex = this.list.items.findIndex(item => '' + item.id === this.itemId);
        let prevNavigSameList = this._previousInstruction && this._previousInstruction.config.name === 'word';
        if (wordIndex === 0 && !prevNavigSameList) {
            return true;
        }
        return false;
    }

    _checkShouldShowTooltip1() {
        if (this._checkMightShowTooltip()) {
            let completedTooltips = this.dataService.memolanguageSettings.completedTooltips;
            if (!completedTooltips.firstWordTooltip && this._numberOfFantasies > 1) {
                return true;
            }
        }
        return false;
    }

    _initiateTooltip() {
        if (this._checkMightShowTooltip()) {
            let completedTooltips = this.dataService.memolanguageSettings.completedTooltips;

            if (!completedTooltips.firstWordTooltip) {
                if (this._checkShouldShowTooltip1()) {
                    wordTooltips.installTooltipsForOnboardingWord1.call(this);
                    this.tooltipService.start(() => {
                        this._updateFilteredFantasies();
                    });
                }
            } else if (this._numberOfFantasies > 0) {
                let doTooltip = false;

                if (!completedTooltips.secondWordTooltip) {
                    wordTooltips.installTooltipsForOnboardingWord2.call(this);
                    doTooltip = true;
                } else if (!completedTooltips.thirdWordTooltip) {
                    wordTooltips.installTooltipsForOnboardingWord3.call(this);
                    doTooltip = true;
                }
                if (doTooltip) {
                    this.tooltipService.start();
                }
            }
        }
    }

    _updateFilteredFantasies() {
        let doingFirstWordTooltipFlow = this._checkShouldShowTooltip1();

        this.filteredFantasies = this.word.getFilteredFantasies({
            addEmptyFantasy: !doingFirstWordTooltipFlow
        });
    }
}
