import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

export class RouteTransitionHelper {
    transitionHint = null;

    setTransitionHint(hint) {
        this.transitionHint = hint;
    }
}

function applyTransitionHints(navigationInstruction, routeTransitionHelper) {
    let router = navigationInstruction.router;
    let transitionDirectionMap = router.transitionDirectionMap || {};

    let prevRouteName = navigationInstruction.previousInstruction
        ? navigationInstruction.previousInstruction.config.name
        : '-';
    let nextRouteName = navigationInstruction.config.name;
    let defaultDirection = transitionDirectionMap[`${prevRouteName}->${nextRouteName}`] || 'fade-with-drop'; //'forward';
    let viewPortInstructions = navigationInstruction.viewPortInstructions;

    Object.keys(viewPortInstructions)
        .map(name => viewPortInstructions[name])
        .forEach(viewPortInstruction => {
            if (viewPortInstruction.strategy === 'replace') {
                //let transitionHint = viewPortInstruction.component.router.transitionHint || {};
                let transitionHint = routeTransitionHelper.transitionHint || {};
                let viewPorts = viewPortInstruction.component.router.viewPorts;
                Object.keys(viewPorts)
                    .map(name => viewPorts[name])
                    .forEach(viewPort => {
                        viewPort.element.dataset.direction = transitionHint.direction || defaultDirection;
                        viewPort.element.dataset.backgroundTheme = transitionHint.backgroundTheme || '';
                    });
            }
            if (viewPortInstruction.childNavigationInstruction) {
                applyTransitionHints(viewPortInstruction.childNavigationInstruction, routeTransitionHelper);
            }
        });
    //delete router.transitionHint;
}

@inject(RouteTransitionHelper, EventAggregator)
export class RouteTransitionConfigurationStep {
    constructor(routeTransitionHelper, ea) {
        this.routeTransitionHelper = routeTransitionHelper;
        this.ea = ea;

        let navigWasAsync = false;

        this._subscriptionRouterNavigationProcessing = this.ea.subscribe('router:navigation:processing', response => {
            document.body.classList.remove('transition-complete');
            document.body.getBoundingClientRect(); // Force update
            document.body.classList.add('transition-in-progress');
            navigWasAsync = false;
            setTimeout(() => (navigWasAsync = true), 100);
        });
        this._subscriptionRouterNavigationSuccess = this.ea.subscribe('router:navigation:complete', response => {
            document.body.getBoundingClientRect(); // Force update
            document.body.classList.remove('transition-in-progress');
            if (navigWasAsync) {
                document.body.classList.add('transition-complete');
            }
            // document.body.getBoundingClientRect(); // Force update
            // document.body.classList.remove('transition-complete');

            // NOTE: This hack might only be needed for Firefox. And the timeout is only required when the router-view has swap-order 'with'
            setTimeout(() => {
                // document.body.classList.remove('transition-complete');

                Array.from(document.querySelectorAll('.au-leave-active')).forEach(elem => {
                    elem.classList.add('au-leave-active-hack');
                });
            }, 100);
        });
    }

    run(navigationInstruction, next) {
        if (navigationInstruction.previousInstruction) {
            applyTransitionHints(navigationInstruction, this.routeTransitionHelper);
            this.routeTransitionHelper.setTransitionHint(null);
        }

        return next();
    }
}
