import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { RepetitionCreatedOrUpdatedEvent } from '../events';
import { RepetitionService } from '../services/repetition-service';
import { DataService } from '../services/data-service';

@inject(DataService, RepetitionService, EventAggregator)
export class NotificationIndicator {
    level = -1;
    dueRepetitionCount = 0;

    constructor(dataService, repetitionService, ea) {
        this.dataService = dataService;
        this.repetitionService = repetitionService;
        this.ea = ea;
    }

    bind() {
        this.repetitionService.ensureInitialLoad().then(this._updateLevel.bind(this));
        this._repEventSubscription = this.ea.subscribe(RepetitionCreatedOrUpdatedEvent, payload => {
            this._updateLevel();
        });
    }

    detached() {
        this._repEventSubscription.dispose();
    }

    _updateLevel() {
        let level = -1;
        let dueRepetitionCount = 0;
        this.repetitionService
            .getRepetitions(this.dataService.activePackageName, listId => this.dataService.isList(listId))
            .forEach(rep => {
                level = Math.max(level, rep.level);
                if (rep.level > 0) {
                    dueRepetitionCount += 1;
                }
            });
        this.level = level;
        this.dueRepetitionCount = dueRepetitionCount;
    }
}
