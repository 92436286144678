import { bindable } from 'aurelia-framework';

export class CircularProgressNew {
    @bindable fraction = 0;

    attached() {
        this._update();

        setInterval(() => {
            //this._update();
        }, 1000);
    }

    fractionChanged() {
        this._update();
    }

    _update() {
        if (this.circleElem) {
            // let i = Math.floor(Math.random() * 100);
            let i = this.fraction * 100;
            let increment = 180 / 100;

            this.maskFullElem.style.transform = `rotate(${increment * i}deg)`;
            this.maskFullFillElem.style.transform = `rotate(${increment * i}deg)`;
            this.maskHalfFillElem.style.transform = `rotate(${increment * i}deg)`;

            this.maskHelfFillFixElem.style.transform = `rotate(${increment * i * 2}deg)`;

            // let i = Math.floor(Math.random() * 100);
            // let increment = 180 / 100;

            // this.maskFullElem.style.transform = `rotate(${increment * i}deg)`;
            // this.maskFullFillElem.style.transform = `rotate(${increment * i}deg)`;
            // this.maskHalfFillElem.style.transform = `rotate(${increment * i}deg)`;

            // this.maskHelfFillFixElem.style.transform = `rotate(${increment * i * 2}deg)`;
        }
    }
}
