import { PLATFORM } from 'aurelia-framework';
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

PLATFORM.moduleName('./spaced-repetition-popup');

@inject(DialogController, Element)
export class SpacedRepetitionPopup {
    constructor(controller, element) {
        this.controller = controller;
        this.element = element;
    }

    attached() {
        let cls = 'memolanguage-SpacedRepetitionPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }
}
