import { inject, BindingEngine } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { DataService } from '../services/data-service';

@inject(DataService, EventAggregator, DialogService, BindingEngine)
export class Pronunciation {
    item = null;

    itemId = null;
    fantasyIndex = 0;
    filteredFantasies = [];

    constructor(dataService, ea, dialogService, bindingEngine) {
        this.dataService = dataService;
        this.ea = ea;
        this.dialogService = dialogService;
        this.bindingEngine = bindingEngine;
    }

    determineActivationStrategy(params) {
        return activationStrategy.replace;
    }

    activate(params, routeConfig, navigationInstruction) {
        this.listId = parseInt(params.listId, 10);
        this.itemId = parseInt(params.itemId, 10);

        return this.dataService.getList(this.listId).then(list => {
            this.item = list.items.find(item => item.id === this.itemId);

            // NOTE: Disabled, because we handle this a different way in fantasy-display card
            // this._fantasyIndexSubscription = this.bindingEngine.propertyObserver(this, 'fantasyIndex')
            // .subscribe((newValue, oldValue) => {
            //   let fantasy = this.filteredFantasies[newValue];
            //   if (fantasy.id) {
            //     this.item.setCurrentFantasy(fantasy.id);
            //   }
            // });

            this._updateFilteredFantasies();

            let fantasyIndex = this.filteredFantasies.findIndex(
                item => item.id && item.id === this.item.currentFantasyId
            );
            if (fantasyIndex > -1) {
                this.fantasyIndex = fantasyIndex;
            } else {
                this.fantasyIndex = 0;
            }
        });
    }

    deactivate() {
        // this._fantasyIndexSubscription.dispose();
    }

    _updateFilteredFantasies() {
        this.filteredFantasies = this.item.getFilteredFantasies({
            addEmptyFantasy: false,
            ignoreSourceLanguage: true
        });
    }
}
