import { inject, BindingEngine } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';

import { UpgradePopup } from '../modals/upgrade-popup';
import { FlytogetSignupPopup } from '../modals/flytoget-signup-popup';
import { FlytogetSignup2Popup } from '../modals/flytoget-signup2-popup';
// import {CheckoutPopup} from '../modals/checkout-popup';

import { ThemeController } from '../theme-controller';
import { DataService } from '../services/data-service';

import { DebugService } from '../services/debug-service';
import { RepetitionService } from '../services/repetition-service';
import { Auth } from '../auth';
import preloadImages from '../preloadImages';
import getHashedUrl from '../getHashedUrl';

import vueRouter from '@/router';

@inject(
    DataService,
    Router,
    BindingEngine,
    I18N,
    EventAggregator,
    DialogService,
    Auth,
    ThemeController,
    RepetitionService,
    DebugService
)
export class Home {
    activeCategoryIndex = -1; // NOTE: starting on -1 to always trigger a change when this page is loading.
    hasDashboardAccess = true;
    categoryProgress = 0;

    constructor(
        dataService,
        router,
        bindingEngine,
        i18n,
        ea,
        dialogService,
        auth,
        themeController,
        repetitionService,
        debugService
    ) {
        this.dataService = dataService;
        this.router = router;
        this.bindingEngine = bindingEngine;
        this.i18n = i18n;
        this.ea = ea;
        this.dialogService = dialogService;
        this.auth = auth;
        this.themeController = themeController;
        this.repetitionService = repetitionService;
        this.debugService = debugService;
    }

    async activate(params) {
        await this.dataService.ensureLanguageLoaded();
        this.targetLanguage = this.dataService.activeTargetLanguage;

        this._registerDebugCommands();

        this.categories = [
            {
                name: this.i18n.tr('memolanguage:homeView.cards.words.name'),
                image: '/memolanguage/images/level-0-category-images/words.png',
                icon: '/memolanguage/images/level-0-category-icons/words.svg',
                iconName: 'words',
                text: this.i18n.tr('memolanguage:homeView.cards.words.text'),
                id: 1
            },
            {
                name: this.i18n.tr('memolanguage:homeView.cards.pronunciation.name'),
                image: '/memolanguage/images/level-0-category-images/pronunciation.png',
                icon: '/memolanguage/images/level-0-category-icons/pronunciation.svg',
                iconName: 'pronunciation',
                text: this.i18n.tr('memolanguage:homeView.cards.pronunciation.text'),
                id: 470
            },
            {
                name: this.i18n.tr('memolanguage:homeView.cards.phrases.name'),
                image: '/memolanguage/images/level-0-category-images/phrases.png',
                icon: '/memolanguage/images/level-0-category-icons/phrases.svg',
                iconName: 'phrases',
                text: this.i18n.tr('memolanguage:homeView.cards.phrases.text'),
                id: 479
            }
            // {
            //   name: this.i18n.tr('memolanguage:homeView.cards.sprints.name'),
            //   image: '/memolanguage/images/level-0-category-images/sprints.png',
            //   icon: '/memolanguage/images/level-0-category-icons/sprints.svg',
            //   iconName: 'sprints',
            //   text: this.i18n.tr('memolanguage:homeView.cards.sprints.text')
            // }
        ];

        if (this.auth.hasRole('flytoget')) {
            this.categories.unshift({
                name: this.i18n.tr('memolanguage:homeView.cards.flytoget.name'),
                image: '/memolanguage/images/level-0-category-images/flytoget.png',
                icon: '/memolanguage/images/level-0-category-icons/flytoget.svg',
                iconName: 'flytoget',
                text: this.i18n.tr('memolanguage:homeView.cards.flytoget.text'),
                id: 580,
                theme: 'flytoget'
            });
        }
        if (this.auth.hasRole('michelle')) {
            this.categories.unshift({
                name: 'Fransk med Michelle',
                image: '/memolanguage/images/level-0-category-images/michelle.png',
                icon: '/memolanguage/images/level-0-category-icons/words.svg',
                iconName: 'words',
                text: 'Fransk med Michelle',
                id: 753
            });
        }

        this.categories.forEach(x => {
            if (x.id) {
                x.category = this.dataService.getSync('c-' + x.id);
            }
        });

        this._updateAccess();

        this._authSubscription = this.ea.subscribe('auth:profileChanged2', payload => {
            this._updateAccess();
        });

        this.dataPropertySubscription = this.bindingEngine
            .propertyObserver(this, 'activeCategoryIndex')
            .subscribe(this.activeCategoryIndexChanged.bind(this));

        let appPackageId = `appPackage/${this.dataService.activePackageName}`;

        // return this.repetitionService.ensureInitialLoad()
        // Load category models. Needed for progress indication in footer-button

        return Promise.all(
            this.categories.map(category => {
                if (category.id) {
                    return this.dataService.getCategory(category.id).then(model => (category.model = model));
                } else {
                    return Promise.resolve();
                }
            })
        )
            .then(() => this.dataService.get(appPackageId))
            .then(appPackage => {
                this.appPackage = appPackage;
                this.activeCategoryIndex = appPackage.activeCategoryIndex || 0;
            })
            .then(() => {
                let urls = [];
                this.categories.forEach(c => {
                    urls.push(getHashedUrl(c.image));
                    urls.push(getHashedUrl(c.icon));
                });
                return preloadImages(urls);
            });
    }

    deactivate() {
        this.dataPropertySubscription.dispose();
        this._authSubscription.dispose();
        this.debugService.remove(this);
    }

    _registerDebugCommands() {
        this.debugService.add(
            'showUpgradePopup',
            () => {
                this.dialogService.open({ viewModel: UpgradePopup });
            },
            this
        );
        this.debugService.add(
            'showFlytogetSignupPopup',
            () => {
                this.dialogService.open({ viewModel: FlytogetSignupPopup });
            },
            this
        );
        this.debugService.add(
            'showFlytogetSignup2Popup',
            () => {
                this.dialogService.open({ viewModel: FlytogetSignup2Popup });
            },
            this
        );

        // this.debugService.add('showCheckoutPopup', () => {
        //   this.dialogService.open({viewModel: CheckoutPopup});
        // }, this);

        this.debugService.add(
            'goto onboarding',
            () => {
                this.router.navigateToRoute('onboarding');
            },
            this
        );
        this.debugService.add(
            'goto flytoget languages view',
            () => {
                vueRouter.push({ name: 'MemolanguageFlytogetLanguagesView' });
            },
            this
        );
    }

    _updateAccess() {
        this.categories.forEach(category => {
            if (category.category) {
                category.locked = category.category.locked;
            } else {
                category.locked = true;
            }
        });

        if (this.auth.hasRole('flytoget')) {
            if (!this.auth.isSubscribed()) {
                this.hasDashboardAccess = false;
            } else {
                this.categories.forEach(category => (category.locked = false));
                this.hasDashboardAccess = true;
            }
        }
    }

    activeCategoryIndexChanged(newValue, oldValue) {
        let category = this.categories[this.activeCategoryIndex];
        this.themeController.set(category.theme || '');
        this.appPackage.activeCategoryIndex = this.activeCategoryIndex;
        this.categoryProgress = Math.random();

        if (category.model) {
            this.categoryProgress = category.model.learnedItemCount / category.model.itemCount;
        } else {
            this.categoryProgress = 0;
        }
    }

    selectCategory(categoryIndex) {
        if (this.activeCategoryIndex === categoryIndex) {
            let category = this.categories[categoryIndex];
            if (category.locked) {
                this.dialogService.open({ viewModel: UpgradePopup });
            } else {
                if (category.id) {
                    this.router.navigateToRoute('category', { categoryId: category.id });
                } else {
                    // Not implemented. True for 'Sprint'
                }
            }
        }
    }

    selectLanguage() {
        if (this.auth.profile.roles.indexOf('flytoget') !== -1) {
            // if (this.router.currentInstruction.parentInstruction.config.name === 'memolanguage') {
            //   // this.router.navigate('/memolanguage');
            // } else {
            //   // this.router.navigate('/flytoget/memolanguage');
            // }
            vueRouter.push({ name: 'MemolanguageFlytogetLanguagesView' });
        }
    }

    exitMemolanguage() {
        vueRouter.push({ name: 'DashboardHomeView' });
    }
}
