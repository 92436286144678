import { QuizItemBase } from '../quiz-item-base';

export class ListeningTest extends QuizItemBase {
    autoCommit = true;
    voice = 'male';
    status = ''; // correct | wrong | timeout

    attached() {
        super.attached();
        this.play();
    }

    play(initiatedByUser) {
        let url = this.model.tts[this.voice] || this.model.tts.female;
        if (url) {
            this.soundService.play(url, initiatedByUser);
        }
    }

    selectionChanged() {
        if (this.model.choices.find(c => c.selected && c.isCorrect)) {
            this.model.score = 1;
        } else {
            this.model.score = 0;
        }

        if (this.autoCommit) {
            this.itemSession.commit();
        }
    }

    showFeedback() {
        return this.interactionVm.showFeedback(this.itemSession);
    }

    animateEnterInteraction() {
        return this.interactionVm.animateEnter();
    }

    animateLeaveInteraction() {
        return this.interactionVm.animateLeave();
    }
}
