/* global PLATFORM */

import { inject, BindingEngine } from 'aurelia-framework';
import { Router, activationStrategy, Redirect } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';

import { DebugService } from '../services/debug-service';
import { RepetitionService } from '../services/repetition-service';
import { DataService } from '../services/data-service';

import { Auth } from '../auth';

import { UpgradePopup } from '../modals/upgrade-popup';
import Storage from '../storage';

@inject(DataService, Router, BindingEngine, RepetitionService, Auth, DebugService, DialogService)
export class CategoryView {
    activeChildIndex = 0;
    children = [];
    childrenOfType = 'mixed';

    selectedCategory = null;

    constructor(dataService, router, bindingEngine, repetitionService, auth, debugService, dialogService) {
        this.dataService = dataService;
        this.router = router;
        this.bindingEngine = bindingEngine;
        this.repetitionService = repetitionService;
        this.auth = auth;
        this.debugService = debugService;
        this.dialogService = dialogService;
    }

    determineActivationStrategy(params) {
        // NOTE: We need this in order to trigger transitions when navigating to the same view, and for making sure getViewStrategy is invoked.
        return activationStrategy.replace;
    }

    getViewStrategy() {
        // flytoget, pronunciation, michelle has no subcategories. Display in grid
        // view
        const gridLayoutCategories = [580, 470, 753];
        if (gridLayoutCategories.indexOf(this.category.id) > -1) {
            return PLATFORM.moduleName('./category-with-grid-layout-view.html');
        }
        return this.category.depth > 0
            ? PLATFORM.moduleName('./category-with-grid-layout-view.html')
            : PLATFORM.moduleName('./category-with-carousel-layout-view.html');
    }

    canActivate(params, routeConfig, navigationInstruction) {
        return this.dataService
            .ensureLanguageLoaded()
            .then(() => {
                return this.dataService.getCategory(params.categoryId);
            })
            .then(category => {
                if (!category) {
                    return new Redirect(`${this.router.baseUrl}not-found`);
                }
                if (category.avoidCategoryView) {
                    return new Redirect(`${this.router.baseUrl}category/${category.parent.id}`);
                } else {
                    return true;
                }
            });
    }

    activate(params) {
        this.categoryId = params.categoryId;
        this.category = {
            id: parseInt(this.categoryId, 10)
        };

        this._registerDebugCommands();

        this.dataPropertySubscription = this.bindingEngine
            .propertyObserver(this, 'activeChildIndex')
            .subscribe(this.activeChildIndexChanged.bind(this));

        return this.repetitionService
            .ensureInitialLoad()
            .then(() => this.dataService.getCategory(this.categoryId))
            .then(category => {
                this.category = category;
                this.children = category.children;

                let itemTypes = new Set(this.children.map(child => child.model || 'unknown'));
                if (itemTypes.size === 1) {
                    this.childrenOfType = Array.from(itemTypes)[0];
                }

                this.activeChildIndex = Math.min(this.category.activeChildIndex || 0, this.children.length - 1);
            });
    }

    deactivate() {
        this.dataPropertySubscription.dispose();
        this.debugService.remove(this);
    }

    _registerDebugCommands() {}

    attached() {
        if (this.bottomElem) {
            this._setupScrollBehaviourForBadgeLayoutView();
        }
    }

    _setupScrollBehaviourForBadgeLayoutView() {
        // Only in category-with-grid-layout-view.html

        this.bottomElem.addEventListener('scroll', e => {
            if (this.bottomElem.scrollTop > 0) {
                this.topElem.classList.add('is-minimized');
                this.bottomElem.classList.add('top-is-minimized');
            } else {
                this.topElem.classList.remove('is-minimized');
                this.bottomElem.classList.remove('top-is-minimized');
            }
        });
    }

    activeChildIndexChanged() {
        this.category.activeChildIndex = this.activeChildIndex;
    }

    selectChild(childIndex) {
        let child = this.children[childIndex];
        if (this.activeChildIndex === childIndex) {
            return this.navigateToChild(child);
        }
    }

    navigateToChild(child) {
        if (child.locked) {
            this.dialogService.open({ viewModel: UpgradePopup });
            return;
        }
        if (child.model === 'ListModel') {
            if (!Storage.getItem('memolanguage-dbgDisableAnnoyingPopups')) {
                this.dataService.memolanguageSettings.signupOrUpgradeRequested = true;

                // NOTE: Exception. We don't want signup popup if the repetiion-tooptip is going to be showed in the list view
                if (child.getQuizState().firstTimeCompleted) {
                    this.dataService.memolanguageSettings.signupOrUpgradeRequested = false;
                }
            }
            return this.router.navigateToRoute('list', { listId: child.id });
        } else {
            return this.router.navigateToRoute('category', { categoryId: child.id });
        }
    }

    selectItem(child) {
        if (this.selectedCategory === child) {
            this.selectedCategory = null;
        } else {
            // NOTE: Setting flex order for sublistsContainer. This will ensure the container always is positioned on a separate row beneath the selected item.
            this.sublistsContainerOrder = this.children.indexOf(child) + (1 - (this.children.length % 2));
            this.selectedCategory = child;
            this.selectedCategoryChildren = this.selectedCategory.children;
        }
    }

    navigateBack() {
        if (this.category.parent) {
            return this.router.navigateToRoute('category', { categoryId: this.category.parent.id });
        } else {
            return this.router.navigateToRoute('home');
        }
    }
}
