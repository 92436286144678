import Vue from 'vue';
import vueRouter from '@/router';

import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { RouteTransitionHelper } from '../route-transition-helper';
import { SidebarService } from '../services/sidebar-service';
import { Auth } from '../auth';

@inject(Router, SidebarService, RouteTransitionHelper, Auth)
export class ProfileSidebar {
    constructor(router, sidebarService, routeTransitionHelper, auth) {
        this.router = router;
        this.sidebarService = sidebarService;
        this.routeTransitionHelper = routeTransitionHelper;
        this.auth = auth;
    }

    attached() {
        this.sidebarService.openReady();
    }

    detached() {}

    navigateToCheckout() {
        vueRouter.push({
            name: 'AccountCheckoutView',
            params: { plan: 'month' },
            query: { next: location.pathname }
        });
        // this.router.navigateToRoute('checkout', {plan: 'month'}).then(() => this.sidebarService.ok());
    }

    openAccount() {
        return this.auth.portal().then(responseJson => {
            window.location.href = responseJson.url;
        });
    }

    openProfile() {
        Promise.resolve(this.router.navigateToRoute('profile')).then(() => this.sidebarService.ok());
    }

    signOut() {
        this.auth.logout().then(() => location.reload());
    }

    openSupport() {
        this.sidebarService.ok();
        Vue.prototype.$intercom.open();
    }
}
