import { inject } from 'aurelia-framework';

import { TooltipService } from '../services/tooltip-service';

@inject(TooltipService)
export class TooltipRenderer {
    constructor(tooltipService) {
        this.tooltipService = tooltipService;
    }
}
