import { bindable, inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

import { WordPopup } from '../modals/word-popup';
import { ConjugationsPopup } from '../modals/conjugations-popup';

@inject(DialogService)
export class WordInPhrase {
    @bindable word;

    translation = '';
    fantasy = null;

    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    attached() {}

    bind() {
        this.translation = this.word.translationString;
        this.fantasy = this.word.getPreferredFantasy();
    }

    browseFantasies() {
        // show single word in popup
        this.dialogService.open({ viewModel: WordPopup, model: this.word }).whenClosed(response => {
            this.fantasy = this.word.getPreferredFantasy();
        });
    }

    showDetails() {
        if (this.word.wtype === 'verb') {
            this.dialogService.open({ viewModel: ConjugationsPopup, model: this.word }).whenClosed(response => {
                //
            });
        }
    }
}
