import store from '@/store';

export class Auth {
    constructor() {
        this.profile = {
            roles: []
        };

        this.profile2 = {
            subscribed: false
        };

        store.watch(
            state => state.moduleAuth.profile,
            profile => {
                this.profile = profile;
                this.profile2.subscribed =
                    this.profile && this.profile.subscription && this.profile.subscription.plan_id;
            },
            { immediate: true }
        );
    }

    hasRole(role) {
        return this.profile.roles.indexOf(role) > -1;
    }

    getLanguage() {
        return store.getters['moduleApp/locale'];
    }

    isAuthenticated() {
        return store.state.moduleAuth.isAuthenticated;
    }

    get isTempUser() {
        return store.getters['moduleAuth/isTempUser'];
    }

    logout() {
        return store.dispatch('moduleAuth/logout');
    }

    isSubscribed() {
        const profile = store.state.moduleAuth.profile;
        return profile && profile.subscription && profile.subscription.plan_id;
    }

    authenticate(provider) {
        return store.dispatch('moduleAuth/authenticate', provider);
    }

    updateMe(data) {
        return store.dispatch('moduleAuth/updateMe', data);
    }

    createOrUpdateUser(data) {
        return store.dispatch('moduleAuth/createOrUpdateUser', data);
    }
}
