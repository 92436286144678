import moment from 'moment';

export class DateValueConverter {
    toView(value, format) {
        if (!value) {
            return null;
        }
        return moment(value).format(format);
    }
}

export class RelativeDateValueConverter {
    toView(value, format) {
        if (!value) {
            return null;
        }
        return moment(value, format).fromNow();
    }
}

export class FormatedDurationValueConverter {
    toView(value) {
        if (!value) {
            value = 0;
        }

        let fragments = [];
        let duration = moment.duration(value);
        if (duration.asDays() >= 1) {
            fragments.push(`${duration.days()}<span>d</span>`);
            if (duration.hours()) {
                fragments.push(`${duration.hours()}<span>h</span>`);
            }
        } else {
            if (duration.hours()) {
                fragments.push(`${duration.hours()}<span>h</span>`);
            }
            if (duration.minutes()) {
                fragments.push(`${duration.minutes()}<span>m</span>`);
            }
            if (!duration.hours() && duration.seconds()) {
                fragments.push(`${duration.seconds()}<span>s</span>`);
            }
            if (fragments.length === 0) {
                fragments.push('0<span>s</span>');
            }
        }
        return fragments.join(' ');
    }
}
