import store from '@/store';

let LearnMixin = superclass =>
    class extends superclass {
        learnStatus = 0;
        _learnStatusDerivedFromRepetition = false;

        activate() {
            return super.activate();
        }

        unload() {
            super.unload();
        }

        markAsSeen() {
            this.seen = true;
            // NOTE: no persisting of this
        }

        markAsLearned() {
            if (!this.learnStatus || this._learnStatusDerivedFromRepetition) {
                this.learnStatus = 1;
                this._learnStatusDerivedFromRepetition = false;
                this.lists.forEach(list => list.updateLearnedItemCount());

                store.dispatch('moduleMemolanguage/markItemAsLearned', {
                    targetLanguage: this.services.dataService.activeTargetLanguage,
                    prefixedItemId: this.prefixedId
                });
            }
        }

        markAsLearnedNoPersist(derivedFromRep = false) {
            if (!this.learnStatus) {
                this.learnStatus = 1;
                this.lists.forEach(list => list.updateLearnedItemCount());
                if (derivedFromRep) {
                    this._learnStatusDerivedFromRepetition = true;
                }
            }
        }
    };

export { LearnMixin };
