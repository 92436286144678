import { PLATFORM } from 'aurelia-pal';
import { bootstrap, starting } from 'aurelia-bootstrapper';
import { TemplatingEngine } from 'aurelia-framework';
import localsPreprocess from '@/locales-preprocess';

import { DebugService } from './services/debug-service';
import { DataService } from './services/data-service';
import memolanguageI18nSource from './i18n/memolanguage';

let setupDone = false;
let aa = null;
let aureliaDialogConfig = null; // yet another hack
const tools = {};

export default function setupAurelia() {
    if (setupDone) {
        return starting.then(() => {
            return tools;
        });
    }

    function configure(aurelia) {
        aa = aurelia;
        aurelia.use
            .standardConfiguration()
            .developmentLogging()
            .globalResources(
                PLATFORM.moduleName('@/modules/memolanguage/aurelia/value-converters/image-url'),
                PLATFORM.moduleName('@/modules/memolanguage/aurelia/value-converters/date'),
                PLATFORM.moduleName('@/modules/memolanguage/aurelia/memolanguage-root'),
                PLATFORM.moduleName('@/modules/memolanguage/aurelia/elements/my-button.html')
            )
            .plugin(PLATFORM.moduleName('aurelia-animator-css'))
            .plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
                config.useDefaults();
                config.useCSS('');
                config.settings.position = x => {};
                aureliaDialogConfig = config;
            })
            .plugin(PLATFORM.moduleName('aurelia-i18n'), instance => {
                const memolanguageI18n = localsPreprocess(memolanguageI18nSource);

                instance.setup({
                    resources: {
                        no: { memolanguage: memolanguageI18n.no },
                        en: { memolanguage: memolanguageI18n.en }
                    },
                    lng: 'no',
                    attributes: ['t', 'i18n'],
                    fallbackLng: 'en',
                    fallbackNS: 'memolanguage',
                    debug: false
                });
            });

        return aurelia.start().then(a => {});
    }

    bootstrap(configure);

    function enhance(element) {
        const aurelia = aa;
        let templatingEngine = aurelia.container.get(TemplatingEngine);

        const x = templatingEngine.enhance({
            container: aurelia.container,
            element: element,
            resources: aurelia.resources
        });
        aureliaDialogConfig.settings.host = document.querySelector('.memolanguageRoot');
        return x;
    }

    return starting.then(a => {
        setupDone = true;
        let languageService = aa.container.get(DataService);
        tools.enhance = enhance;
        tools.debugService = aa.container.get(DebugService);
        tools.activateLanguage = languageService.activateLanguage.bind(languageService);
        return tools;
    });
}
