import { PLATFORM } from 'aurelia-framework';
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

PLATFORM.moduleName('./unlocked-phrases-popup');

@inject(DialogController, Element)
export class UnlockedPhrasesPopup {
    constructor(controller, element) {
        this.controller = controller;
        this.element = element;

        this.controller.settings.overlayDismiss = true;
    }

    activate(phrases) {
        this.phrases = phrases;
    }

    attached() {
        let cls = 'memolanguage-UnlockedPhrasesPopup';
        this.element.parentElement.parentElement.classList.add(`contains-${cls}`);
        this.element.parentElement.parentElement.previousElementSibling.classList.add(`for-${cls}`);
    }

    select(phrase) {
        this.controller.ok(phrase);
    }
}
