import { QuestionProvider } from '../question-provider';
import { generators as languageGenerators } from '../quiz-generators-language';

export class TempListModel {
    static idPrefix = 'c-';
    isTemporary = true; // As opposed to a normal list
    isLoaded = true;
    itemsOfType = 'unknown';

    // NOTE: maybe persist
    allItemsSeen = false;

    constructor(services, fixture) {
        this.dataService = services.dataService;

        Object.assign(this, fixture);
    }

    get prefixedId() {
        return `c-${this.id}`;
    }

    ensureItemsLoaded() {
        return Promise.all(this.items.map(item => item.ensureLoaded())).then(() => this);
    }

    loadAllRelatedWords() {
        return Promise.resolve(this);
    }

    markItemsAsSeen() {
        this.allItemsSeen = true;
    }

    createQuestionProvider() {
        let appPackageId = `appPackage/${this.dataService.activePackageName}`;
        return this.ensureItemsLoaded()
            .then(list => this.dataService.get(appPackageId))
            .then(appPackage => {
                let testData = this.items;

                return new QuestionProvider(
                    {
                        generators: languageGenerators,
                        maxTests: 'all',
                        shuffle: true,
                        testData: testData,
                        dataPool: this.items
                    },
                    appPackage.settings
                );
            });
    }

    updateQuizState(items, allQuestionsAnswered) {
        let numberOfItems = this.items.length;
        let quizState = this.getQuizState();
        let newCompletedItemIds = [];

        items.forEach(item => {
            quizState.totalTimeUsed += item.time;

            if (item.score === 1 && quizState.completedItemIds.indexOf(item.source) === -1) {
                quizState.completedItemIds.push(item.source);
                newCompletedItemIds.push(item.source);
                quizState.correctCount += 1;
            }
        });

        quizState.completionFraction = quizState.completedItemIds.length / numberOfItems;
        quizState.allQuestionsAnswered = allQuestionsAnswered ? true : false;
        this.quizState = quizState;

        if (quizState.completionFraction === 1) {
            this.items.forEach(item => {
                item.markAsLearned();
            });
        }
        return Promise.resolve();
    }

    getQuizState() {
        return (
            this.quizState || {
                completedItemIds: [],
                completionFraction: 0,
                firstTimeCompleted: false,
                allQuestionsAnswered: false,
                totalTimeUsed: 0,
                justUpdated: false
            }
        );
    }
}
