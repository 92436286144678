import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@inject(EventAggregator)
export class SidebarService {
    targets = []; // Not really used for anything ATM
    target = null; // This is the latest target to register

    constructor(ea) {
        this.ea = ea;

        this.ea.subscribe('router:navigation:complete', response => {
            this.hide();
        });
    }

    register(target) {
        this.targets.push(target);
        this.target = target;
    }

    unregister(target) {
        let index = this.targets.indexOf(target);
        if (index !== -1) {
            this.targets.splice(index, 1);
            if (this.targets.length === 0) {
                this.target = null;
            }
        }
    }

    hide() {
        if (this.target) {
            this.target.hideSidebar();
        }
    }

    open(options) {
        if (this.target) {
            this.target.showSidebar(options);
            return new Promise(resolve => {
                this._resolve = resolve;
            });
        }
    }

    openReady() {
        if (this.target) {
            this.target.openReady();
        }
    }

    // Used from sidebar views
    ok(result, keepOpen = false) {
        if (!keepOpen) {
            this.hide();
        }
        this._resolve({ wasCancelled: false, result: result });
    }

    cancel() {
        if (this.target) {
            this.target.hideSidebar();
            this._resolve({ wasCancelled: true });
        }
    }
}
