import { QuestionProvider } from '../question-provider';
import { generators as languageGenerators } from '../quiz-generators-language';
import store from '@/store';
import * as types from '@/store/mutation-types';

export class ListModel {
    static idPrefix = 'c-';
    isLoaded = true;
    itemsOfType = 'unknown';
    repetition = {};
    parent = null; // NOTE: Populated from parent model constructor

    // NOTE: maybe persist
    allItemsSeen = false;

    itemCount = 0;
    learnedItemCount = 0; // NOTE: updated when calling getCompletedTargetItemCount()

    constructor(services, fixture) {
        this.services = services;
        Object.assign(this, fixture);
        this.items.forEach(item => item.lists.push(this));
        if (this.image && this.itemsOfType === 'pronunciation') {
            this.image = '/memolanguage/images/pronunciation-placeholder-square.png';
        }
        if (!this.image) {
            this.image = {
                word: '/memolanguage/images/word-placeholder.png',
                phrase: '/memolanguage/images/phrase-placeholder.png',
                pronunciation: '/memolanguage/images/pronunciation-placeholder-square.png'
            }[this.itemsOfType];
        }

        this.itemCount = this.items.length;
        this.repetition = this.services.repetitionService.getRepetition(
            this.services.dataService.activePackageName,
            String(this.id)
        );
    }

    get prefixedId() {
        return `c-${this.id}`;
    }

    ensureItemsLoaded() {
        return Promise.all(this.items.map(item => item.ensureLoaded())).then(() => this);
    }

    updateLearnedItemCount() {
        this.parent.updateLearnedItemCount();
        clearTimeout(this._calcLearnedItemCountTimeoutId);
        this._calcLearnedItemCountTimeoutId = setTimeout(() => this._calcLearnedItemCount(), 1);
    }

    _calcLearnedItemCount() {
        this.learnedItemCount = this.items.filter(item => item.learnStatus === 1).length;
    }

    loadAllRelatedWords() {
        return this.getIncludedWords().then(() => this);
    }

    getIncludedWords() {
        return this.ensureItemsLoaded().then(() => {
            if (this.itemsOfType === 'phrase') {
                let phraseItems = this.items;
                let allRelatedWordIds = [];
                phraseItems.forEach(
                    phraseItem => (allRelatedWordIds = allRelatedWordIds.concat(phraseItem.relatedWordIds))
                );
                allRelatedWordIds = Array.from(new Set(allRelatedWordIds));
                let allRelatedWords = allRelatedWordIds.map(wordId => this.services.dataService.getSync(wordId));
                return allRelatedWords;
            } else if (this.itemsOfType === 'word') {
                return this.items;
            } else {
                return [];
            }
        });
    }

    isFinished() {
        return (
            this.itemCount === this.learnedItemCount ||
            this.services.repetitionService.findRepetition(this.services.dataService.activePackageName, String(this.id))
        );
    }

    markItemsAsSeen() {
        this.allItemsSeen = true;
    }

    createQuestionProvider() {
        let appPackageId = `appPackage/${this.services.dataService.activePackageName}`;
        return this.services.repetitionService
            .ensureInitialLoad()
            .then(() => this.services.dataService.get(appPackageId))
            .then(appPackage => {
                return this.ensureItemsLoaded().then(list => {
                    let testData = this.items;
                    let completedItemIds = this.getQuizState().completedItemIds;
                    let filteredTestData = testData.filter(x => completedItemIds.indexOf(x.id) === -1);
                    if (filteredTestData.length) {
                        testData = filteredTestData;
                    }

                    let _languageGenerators = languageGenerators;
                    if (this.itemsOfType === 'pronunciation') {
                        _languageGenerators = languageGenerators.filter(g => {
                            return g.title === 'pronunciation';
                        });
                    }

                    if (this.onboarding) {
                        // Drop listening tests for quiz in onboarding context.
                        _languageGenerators = languageGenerators.filter(g => {
                            return g.title !== 'listening';
                        });
                    }

                    return new QuestionProvider(
                        {
                            generators: _languageGenerators,
                            maxTests: 'all',
                            shuffle: true,
                            testData: testData,
                            dataPool: this.items
                        },
                        appPackage.settings
                    );
                });
            });
    }

    updateQuizState(items, allQuestionsAnswered) {
        let numberOfItems = this.items.length;
        let quizState = this.getQuizState();
        items.forEach(item => {
            quizState.totalTimeUsed += item.time;
            quizState.totalItemsAttempted += 1;

            if (item.score === 1 && quizState.completedItemIds.indexOf(item.source) === -1) {
                quizState.completedItemIds.push(item.source);
                quizState.correctCount += 1;
            }
        });

        quizState.completionFraction = quizState.completedItemIds.length / numberOfItems;
        quizState.accuracy = quizState.correctCount / quizState.totalItemsAttempted;
        quizState.allQuestionsAnswered = allQuestionsAnswered ? true : false;
        quizState.justUpdated = true;
        if (this.repetition.level === -1 && quizState.completionFraction === 1) {
            quizState.firstTimeCompleted = true;
        } else {
            quizState.firstTimeCompleted = false;
        }

        if (quizState.completionFraction > 0 && quizState.completionFraction < 1) {
            quizState.evaluation = quizState.completionFraction <= 0.6 ? 'bad' : 'good';
        } else {
            quizState.evaluation = 'clean';
        }

        this.quizState = quizState;

        let promise;
        if (quizState.completionFraction === 1) {
            this.items.forEach(targetItem => {
                targetItem.markAsLearned();
            });

            promise = this.services.repetitionService.createOrUpdateRepetition(
                this.services.dataService.activePackageName,
                this.id,
                this.name,
                null,
                null,
                `${location.origin}/memolanguage/${this.services.dataService.activeTargetLanguage}`
            );

            this.registerBraindate();
        } else {
            promise = Promise.resolve();
        }
        return promise;
    }

    registerBraindate() {
      store.commit('moduleMemolanguage/' + types.MEMOLANGUAGE_QUIZ_COMPLETED, {
            language: this.services.dataService.activeTargetLanguage,
            listId: this.id,
      });
    }

    getQuizState() {
        return (
            this.quizState || {
                completedItemIds: [],
                completionFraction: 0,
                firstTimeCompleted: false,
                allQuestionsAnswered: false,
                totalTimeUsed: 0,
                totalItemsAttempted: 0,
                correctCount: 0,
                accuracy: 1,
                justUpdated: false,
                evaluation: 'clean'
            }
        );
    }
}
