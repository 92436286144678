/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export { shuffleArray };

// https://github.com/boyarskiy/decorator-debounce
const DEFAULT_DELAY = 250;

const debounce = (delay = DEFAULT_DELAY, immediate = false) => {
    let timeoutId;

    return (target, name, descriptor) => {
        const method = descriptor.value;

        return {
            ...descriptor,
            value(...args) {
                if (immediate && !timeoutId) {
                    method.apply(this, args);
                }
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(() => {
                    timeoutId = null;
                    if (!immediate) {
                        method.apply(this, args);
                    }
                }, delay);
            }
        };
    };
};

export { debounce };

function updateLanguage(obj, language, ignoreKeys) {
    Object.keys(obj).forEach(function(key) {
        let parts = key.split('_');
        let value = obj[key];
        if (ignoreKeys[key]) {
            // Do nothing
        } else if (parts.length === 2) {
            if (parts[1] === language) {
                obj[parts[0]] = value;
            }
        } else if (value instanceof Array) {
            value.forEach(_obj => {
                updateLanguage(_obj, language, ignoreKeys);
            });
        } else if (value instanceof Object) {
            updateLanguage(value, language, ignoreKeys);
        }
    });
}

export { updateLanguage };

export function mergeRanges(ranges) {
    // const smallestGap = 2;

    let stack = []; // Stack of final ranges

    if (ranges.length === 0) {
        return stack;
    }

    // Sort according to start value
    ranges.sort(function(a, b) {
        return a[0] - b[0];
    });

    // Add first range to stack
    stack.push(ranges[0]);

    ranges.slice(1).forEach(function(range, i) {
        let top = stack[stack.length - 1];

        if (top[1] < range[0]) {
            // No overlap, push range onto stack
            stack.push(range);
        } else if (top[1] < range[1]) {
            // Update previous range
            top[1] = range[1];
        }
    });
    return stack;
}

export function calculateRanges(totalLength, preferredRangeLength = 60 * 5, remainderThreshold = 60 * 3) {
    let ranges = [];
    let numberOfParts = Math.floor(totalLength / preferredRangeLength);
    let remaining = totalLength % preferredRangeLength;

    for (let i = 0; i < numberOfParts; i++) {
        let start = i * preferredRangeLength;
        ranges.push([start, start + preferredRangeLength]);
    }

    if (numberOfParts && remaining < remainderThreshold) {
        ranges[ranges.length - 1][1] = totalLength;
    } else {
        ranges.push([numberOfParts * preferredRangeLength, totalLength]);
    }
    // const START = 0;
    // const END = 1;
    // for (let i = 0; i < numberOfParts; i++) {
    //   let start = i * preferredRangeLength;
    //   ranges.push([
    //     start,
    //     start + preferredRangeLength
    //   ]);
    // }
    //
    // if (numberOfParts && remaining < 2 * preferredRangeLength) {
    //   let lastPart = ranges[numberOfParts - 1];
    //   lastPart[END] += remaining;
    //   remaining = 0;
    // } else if (numberOfParts && remaining < 3 * preferredRangeLength) {
    //   if (numberOfParts > 1) { // Add remaining time to last two ranges
    //     ranges[numberOfParts - 2][END] += 1 * 60;
    //     ranges[numberOfParts - 1][START] += 1 * 60;
    //     ranges[numberOfParts - 1][END] += remaining;
    //     remaining = 0;
    //   } else { // Create a new part and steal 1 sec from prev part
    //     ranges[numberOfParts - 1][END] -= 1 * 60;
    //     remaining += 1 * 60;
    //   }
    // }
    //
    // if (remaining) {
    //   let start = numberOfParts * preferredRangeLength;
    //   ranges.push([
    //     start,
    //     start + remaining
    //   ]);
    // }
    return ranges;
}

// See https://github.com/justinfagnani/mixwith.js/ and http://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/
class MixinBuilder {
    constructor(superclass) {
        this.superclass = superclass;
    }

    with(...mixins) {
        return mixins.reduce((c, mixin) => mixin(c), this.superclass);
    }
}
let mix = superclass => new MixinBuilder(superclass);

export { mix };
