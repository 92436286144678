import store from '@/store';

let FantasyMixin = superclass =>
    class extends superclass {
        activate() {
            let superPromise = super.activate();

            let resolveB;
            const waitB = new Promise(resolve => {
                resolveB = resolve;
            });

            this.unwatchB = store.watch(
                state =>
                    state.moduleMemolanguage.languages[this.services.dataService.activeTargetLanguage].itemsUserState[
                        this.prefixedId
                    ],
                (newValue, oldValue) => {
                    if (newValue) {
                        this.currentFantasyId = newValue.currentFantasyId;
                    } else {
                        this.currentFantasyId = null;
                    }
                    resolveB();
                },
                { deep: true, immediate: true }
            );

            return Promise.all([superPromise, waitB]);
        }

        unload() {
            super.unload();
            this.unwatchB();
        }

        getFilteredFantasies(options = { addEmptyFantasy: false, ignoreSourceLanguage: false }) {
            let filteredArr = this.fantasies.filter(
                f =>
                    (f.public || f.username === this.services.auth.profile.username) &&
                    (options.ignoreSourceLanguage || f.from_language === this.services.dataService.activeSourceLanguage)
            );

            if (options.addEmptyFantasy && !filteredArr.find(f => f.username === this.services.auth.profile.username)) {
                filteredArr.push({
                    wordId: this.id
                });
            }
            return filteredArr;
        }

        getPreferredFantasy() {
            let filteredFantasies = this.getFilteredFantasies();
            let fantasy = filteredFantasies.find(f => f.id && f.id === this.currentFantasyId);
            if (!fantasy) {
                fantasy = filteredFantasies[0] || null;
            }
            return fantasy;
        }

        setCurrentFantasy(fantasyId) {
            store.dispatch('moduleMemolanguage/setCurrentFantasyForItem', {
                targetLanguage: this.services.dataService.activeTargetLanguage,
                prefixedItemId: this.prefixedId,
                fantasyId
            });
        }

        newFantasy(fantasyData) {
            fantasyData.word = this.id;
            fantasyData.from_language = this.services.dataService.activeSourceLanguage;
            return store.dispatch('moduleMemolanguage/newFantasy', { data: fantasyData }).then(responseJson => {
                responseJson.public = false;
                responseJson.wordId = responseJson.word;
                responseJson.isNew = true;
                delete responseJson.word;
                this.fantasies.push(responseJson);
                this._persistFantasy();
                return responseJson;
            });
        }

        updateFantasy(fantasyId, fantasyData) {
            let fantasy = this.fantasies.find(f => f.id === fantasyId);
            if (fantasy) {
                return store
                    .dispatch('moduleMemolanguage/updateFantasy', { id: fantasyId, data: fantasyData })
                    .then(responseJson => {
                        responseJson.wordId = responseJson.word;
                        delete responseJson.word;
                        fantasyData.modified = true;
                        Object.assign(fantasy, fantasyData);
                        this._persistFantasy();
                        return fantasy;
                    });
            }
            return Promise.resolve();
        }

        _persistFantasy() {
            let modifiedFantasies = [];
            modifiedFantasies = this.fantasies
                .filter(fantasy => fantasy.modified || fantasy.isNew)
                .map(fantasy => Object.assign({}, fantasy));

            store.dispatch('moduleMemolanguage/updateItemFantasies', {
                targetLanguage: this.services.dataService.activeTargetLanguage,
                prefixedItemId: this.prefixedId,
                fantasies: modifiedFantasies
            });
        }
    };

export { FantasyMixin };
