export class DebugService {
    functions = {};
    entries = [];

    add(key, func, inst, labelFunc = null) {
        if (this.functions[key]) {
            // NOTE: warn
        } else {
            if (inst && !this.entries.find(entry => entry.inst === inst)) {
                this.entries.push({
                    inst: inst,
                    section: inst.constructor.name
                });
            }
            this.entries.push({
                func: func,
                inst: inst,
                key: key,
                labelFunc: labelFunc
            });
            this.functions[key] = inst ? func.bind(inst) : func;
        }
    }

    remove(inst, key) {
        let keys = [];
        this.entries = this.entries.filter(entry => {
            let keep = key ? key !== entry.key : inst !== entry.inst;
            if (!keep) {
                keys.push(entry.key);
            }
            return keep;
        });
        if (key) {
            // See if we must remove section entry as well
            let sectionEntryIndex = this.entries.findIndex(entry => entry.inst === inst && entry.sectionLabel);
            if (sectionEntryIndex !== -1) {
                if (this.entries[sectionEntryIndex + 1] && this.entries[sectionEntryIndex + 1].inst === inst) {
                    this.entries.splice(sectionEntryIndex + 1, 1);
                }
            }
        }
        keys.forEach(k => delete this.functions[k]);
    }

    updateLabels() {
        this.entries.forEach(entry => {
            if (entry.labelFunc) {
                entry.label = entry.labelFunc();
            }
        });
    }
}
