import { bindable, bindingMode } from 'aurelia-framework';
import { inject } from 'aurelia-dependency-injection';

import { Auth } from '../auth';

@inject(Element, Auth)
export class FacebookButton {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) isProcessing = false;

    constructor(element, auth) {
        this.element = element;
        this.auth = auth;
    }

    signup() {
        this.isProcessing = true;

        return this.auth
            .authenticate('facebook')
            .then(() => {
                let event = new CustomEvent('signup-success', {
                    bubbles: true
                });
                this.element.dispatchEvent(event);
            })
            .catch(err => {
                this.isProcessing = false;
            });
    }
}
