import { inject, bindable } from 'aurelia-framework';
import { DataService } from '../services/data-service';

@inject(DataService)
export class FantasyEdit {
    @bindable fantasy;
    @bindable hideCode = false;

    isSaving = false;

    constructor(dataService) {
        this.dataService = dataService;
    }

    bind(bindingContext, overrideContext) {
        this.isSaving = false;
        this.parentVM = bindingContext;

        this.fantasyData = {
            fantasy: this.fantasy.fantasy || '',
            code: this.fantasy.code
        };
    }

    cancel() {
        this.parentVM.cancelEdit();
    }

    commit() {
        let fantasyData = {
            fantasy: this.fantasyData.fantasy.trim()
        };
        if (this.fantasyData.code !== undefined) {
            fantasyData.code = this.fantasyData.code.trim();
        }

        if (!((fantasyData.code || this.hideCode) && fantasyData.fantasy)) {
            return;
        }

        this.isSaving = true;

        const startTime = Date.now();

        return this.dataService
            .get(this.fantasy.wordId)
            .then(word => {
                let promise;
                if (this.fantasy.id) {
                    promise = word.updateFantasy(this.fantasy.id, fantasyData);
                } else {
                    promise = word.newFantasy(fantasyData);
                }
                return promise.then(newOrUpdatedFantasy => {
                    if (this.fantasy !== newOrUpdatedFantasy) {
                        Object.assign(this.fantasy, newOrUpdatedFantasy);
                    }
                    word.setCurrentFantasy(this.fantasy.id);
                });
            })
            .then(() => {
                const timeUsed = Date.now() - startTime;

                // Adding some extra delay, to indicate saving process:
                setTimeout(() => {
                    this.parentVM.editDone();
                }, Math.max(0, 1000 - timeUsed));
            });
    }
}
