import { QuizItemBase } from '../quiz-item-base';
import { TimelineMax, Power2 } from 'gsap';

export class WritingTest extends QuizItemBase {
    autoCommit = true;
    status = ''; // correct | wrong
    answer = '';
    answerElem;

    attached() {
        super.attached();
        if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
            // maybe on touch device, so let's not put focus
        } else {
            //this.answerElem.focus();
        }
    }

    select(choice) {
        if (choice.answer === ' ' && this.answer[this.answer.length - 1] === ' ') {
            return; // NOTE: do not add space after a space
        }
        this.answer = this.answer + choice.answer;
        this.updateScore();
    }

    deleteChar() {
        if (this.answer.length) {
            let selStart = this.answerElem.selectionStart;
            let selEnd = this.answerElem.selectionEnd;
            if (selStart === selEnd && selStart > 0) {
                selStart -= 1;
            }
            this.answer = this.answer.substr(0, selStart) + this.answer.substr(selEnd);
            this.updateScore();
        }
    }

    updateScore() {
        if (this.model.answer.includes(this.answer)) {
            this.model.score = 1;
        } else {
            this.model.score = 0;
        }
        if (this.autoCommit && this.model.score === 1) {
            this.submitAnswer();
        }
    }

    submitAnswer() {
        this.itemSession.commit();
    }

    showFeedback() {
        this.answerElem.blur();
        if (this.model.score === 1) {
            return this._showFeedbackForCorrectAnswer();
        } else {
            return this._showFeedbackForWrongAnswer();
        }
    }

    _showFeedbackForCorrectAnswer() {
        // override to select the first answer (the one with article)
        this.answer = this.model.answer[0];
        this.status = 'correct';

        return new Promise(resolve => {
            let tl = (this._tl = new TimelineMax({
                onComplete: () => {
                    resolve();
                }
            }));
            tl.addCallback(() => {
                this.answerElem.classList.add('feedback-correct');
            });
            tl.to(this.answerElem, 0.2, {
                scale: 1.2
            });
            tl.to(this.answerElem, 0.2, {
                scale: 1
            });
            tl.to(this.answerElem, 1 + this.model.scoreEvents.length * 1, {});
        });
    }

    _showFeedbackForWrongAnswer() {
        this.status = 'wrong';

        return new Promise(resolve => {
            let shakeSpeed = 0.1;
            let tl = (this._tl = new TimelineMax({
                onComplete: () => {
                    resolve();
                }
            }));
            tl.addCallback(() => {
                this.answerElem.classList.add('feedback-wrong');
            });
            tl.to(this.answerElem, shakeSpeed, {
                x: -7,
                ease: Power2.easeInOut
            });
            tl.to(this.answerElem, shakeSpeed, {
                repeat: 4,
                x: 5,
                yoyo: true,
                ease: Power2.easeInOut
            });
            tl.to(this.answerElem, shakeSpeed, {
                x: 0
            });
            tl.addCallback(() => {
                this.answerElem.classList.remove('feedback-wrong');
                this.answerElem.classList.add('feedback-fasit');

                this.answer = this.model.answer[0];
            });
            tl.to(this.answerElem, 2, {});
        });
    }
}
