import { inject } from 'aurelia-framework';

import { Auth } from '../auth';

@inject(Auth)
export class ProfileIcon {
    constructor(auth) {
        this.auth = auth;
    }
}
