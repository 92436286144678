import { inject, BindingEngine } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { getLogger } from 'aurelia-logging';
import { I18N } from 'aurelia-i18n';

import { DialogService } from 'aurelia-dialog';

import { ConjugationsPopup } from '../modals/conjugations-popup';

import { TooltipService } from '../services/tooltip-service';
import { DataService } from '../services/data-service';

// import * as phraseTooltips from './phrase__tooltips';

const logger = getLogger('phrase');

@inject(DataService, EventAggregator, BindingEngine, DialogService, TooltipService, I18N)
export class Phrase {
    phrase = null;
    phraseWithMarkup = '';
    listId = null;
    itemId = null;
    wordIndex = 0;
    words = [];
    memotipsMinimized = true;

    translation = null;

    constructor(dataService, ea, bindingEngine, dialogService, tooltipService, i18n) {
        this.dataService = dataService;
        this.ea = ea;
        this.bindingEngine = bindingEngine;
        this.dialogService = dialogService;
        this.tooltipService = tooltipService;
        this.i18n = i18n;
    }

    determineActivationStrategy(params) {
        return activationStrategy.replace;
    }

    activate(params, routeConfig, navigationInstruction) {
        this.listId = params.listId;
        this.itemId = params.itemId;

        this._wordIndexSubscription = this.bindingEngine
            .propertyObserver(this, 'wordIndex')
            .subscribe((newValue, oldValue) => this._updatePhraseWithMarkup());

        return this.dataService.getList(this.listId).then(list => {
            this.phrase = list.items.find(item => '' + item.id === this.itemId);
            this.translation = this.phrase.translationString;

            return this.phrase
                .getIncludedWords()
                .then(words => Promise.all(words.map(w => w.ensureLoaded())))
                .then(words => {
                    this.words = words;
                    this._updatePhraseWithMarkup();
                });
        });
    }

    deactivate() {
        this._wordIndexSubscription.dispose();
        this.tooltipService.clear();
    }

    expandMemotips() {
        this.memotipsMinimized = false;
    }

    minimizeMemotips() {
        this.memotipsMinimized = true;
    }

    _updatePhraseWithMarkup() {
        this.phrase
            .getPhraseWithMarkup(this.wordIndex)
            .then(phraseWithMarkup => (this.phraseWithMarkup = phraseWithMarkup));
    }

    inspectWord(wordId) {
        return this.dataService.get(wordId).then(word => {
            logger.debug('word', wordId, word);
        });
    }

    showConjugationPopup(word) {
        this.dialogService.open({ viewModel: ConjugationsPopup, model: word });
    }
}
