import { QuizItemSession } from './quiz-item-session';

export class QuizSession {
    index = -1;
    complete = false;
    itemIds = [];
    extraData = {};
    questionCount = 0;
    completedQuizItemSessions = [];

    currentQuizItem = null;
    currentQuizItemSession = null;

    constructor(options, ea) {
        this.ea = ea;
        this.extraData = options.extraData || {};
        this.appPackageId = options.appPackageId || 'eurocup';
        this.questionProvider = options.questionProvider;
        this.questionCount = this.questionProvider.numberOfQuestions;
        this.itemIds = this.questionProvider.itemIds || []; // Currently needed in relation to challenge, because challenge uses itemIds directly instead of getting them through a list/collection.
        let ScoringEngine = options.scoringEngine;
        this.scoreEngine = new ScoringEngine();
    }

    result() {
        let correctCount = this.completedQuizItemSessions.filter(itemSession => {
            return itemSession.score === 1;
        }).length;
        let wrongCount = this.questionCount - correctCount;

        return {
            correctCount: correctCount,
            wrongCount: wrongCount,
            bonusPoints: this.scoreEngine.bonusPoints,
            stars: this.scoreEngine.stars
        };
    }

    calculate(test) {
        let newScoreEvents = this.scoreEngine.update(test);
        return newScoreEvents;
    }

    serializeState() {
        return this.completedQuizItemSessions.map(itemSession => {
            return {
                time: Math.round(itemSession.time / 1000),
                score: itemSession.score
            };
        });
    }

    // NOTE: only used in quiz-result view. Probably not needed if we base challenges on list, and not individual items.
    serializeTests() {
        return this.completedQuizItemSessions.map(itemSession => {
            return JSON.parse(JSON.stringify(itemSession.model));
        });
    }

    // -------------------- Some new stuff ---------------
    start(callbacks) {
        this.callbacks = callbacks;
        this.next();

        this.ea.publish('quiz:started', {
            collection: { id: this.extraData.collectionId, name: this.extraData.collectionName }
        });
    }

    abort() {
        if (this.currentQuizItemSession) {
            this.currentQuizItemSession.abort();
        }
    }

    next(options) {
        if (this.complete) {
            // NOTE: currently needed when manually calling onCompleted from a debug/test situation
            return;
        }

        let quizItemModel = this.questionProvider.next(options);

        if (quizItemModel) {
            this.index = this.completedQuizItemSessions.length;
            this.currentQuizItem = quizItemModel;

            this.currentQuizItemSession = new QuizItemSession(this, this.currentQuizItem, this.ea);
            this.callbacks.onNextQuizItem(this.currentQuizItemSession);

            this.currentQuizItemSession.waitDone().then(() => {
                this.next();
            });
        } else {
            this.onCompleted();
        }
    }

    onCompleted() {
        this.complete = true;

        this.callbacks.onQuizCompleted();

        this.ea.publish('quiz:completed', {
            collection: { id: this.extraData.collectionId, name: this.extraData.collectionName },
            scoring: this.result()
        });
    }
}
