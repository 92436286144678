import { inject } from 'aurelia-framework';

import { SidebarService } from '../services/sidebar-service';

@inject(SidebarService)
export class SectionWithSidebar {
    componentId = null;
    model = null;
    placement = '';

    sidebarIsExpanded = false;
    preparing = false;

    constructor(sidebarService) {
        this.sidebarService = sidebarService;
    }

    showSidebar(options) {
        this.componentId = options.viewModel;
        this.model = options.model;
        this.placement = options.placement || 'left';
        this.preparing = true;

        if (!options.waitForViewModel) {
            setTimeout(() => {
                this.preparing = false;
                this.sidebarIsExpanded = true;
            }, 0);
        }
    }

    openReady() {
        setTimeout(() => {
            this.preparing = false;
            this.sidebarIsExpanded = true;
        }, 10);
    }

    hideSidebar() {
        if (!this.sidebarIsExpanded) {
            return;
        }
        this.sidebarIsExpanded = false;
        let lastComponentId = this.componentId;
        let lastModel = this.model;
        setTimeout(() => {
            if (lastComponentId === this.componentId && lastModel === this.model) {
                this.componentId = null;
                this.model = null;
                this.placement = '';
            }
        }, 600);
    }

    attached() {
        this.sidebarService.register(this);
    }

    detached() {
        // this.sidebarService.hide();
        this.sidebarService.unregister(this);
    }
}
