<template>
  <div class="MemolanguageAureliaRootView">
    <memolanguage-root
      v-show="loaded"
      class="memolanguageRoot"
      :source-language="sourceLanguage"
      :target-language="targetLanguage" />
    <transition name="fade">
      <div
        v-if="!loaded"
        class="loadingScreen">
        {{ $t('loading') }}
      </div>
    </transition>
  </div>
</template>

<translations>
  loading: 'Loading...'
  loading_no: 'Laster...'
</translations>

<script>
// NOTE: https://github.com/aurelia/binding/issues/376
import { initialize as initializePal } from 'aurelia-pal-browser';

import setupAurelia from '../aurelia/setup-aurelia';

export default {
    name: 'MemolanguageAureliaRootView',
    data() {
        return {
            loaded: false
        };
    },
    computed: {
        sourceLanguage() {
            return this.$store.getters['moduleApp/locale'] === 'no' ? 'norwegian' : 'english';
        },
        targetLanguage() {
            return this.$route.params.targetLanguage;
        }
    },
    created() {},
    async mounted() {
        initializePal();
        const tool = await setupAurelia();
        await tool.activateLanguage({ sourceLanguage: this.sourceLanguage, targetLanguage: this.targetLanguage });
        this.auView = tool.enhance(this.$el);
        this.memolanguageDebugService = tool.debugService;
        this.loaded = true;
    },
    beforeDestroy() {
        if (this.auView) {
            this.auView.detached();
            this.auView.unbind();
        }
    },
    methods: {}
};
</script>

<style lang="scss">
@import '../aurelia/styles';
</style>

<style lang="scss" scoped>
.MemolanguageAureliaRootView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444;
}

.memolanguageRoot {
    font-size: 4vw;

    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

.loadingScreen {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    color: rgba(white, 0.8);

    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity 1s;
    }
    &.fade-enter,
    &.fade-leave-to {
        opacity: 0;
    }
}

// ------------------------------------------------
// Responsive sizes
// ------------------------------------------------
$maxWidthOnDesktop: 380px;

@include media('>=mobile') {
    .memolanguageRoot {
        font-size: $maxWidthOnDesktop / 100 * 4;
    }
}
@include media('>=mobile') {
    .memolanguageRoot {
        max-width: $maxWidthOnDesktop;
        max-height: $maxWidthOnDesktop * 1.77;
        margin: auto;
    }
}
</style>
