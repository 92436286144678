export class AbstractScoreEngine {
    constructor() {
        this.correctCount = 0;
        this.bonusPoints = 0;
        this.streak = 0;
        this.timeBonusMaxTime = 10 * 1000;
        this.questionCount = 0; // Used for calculating stars
        this.events = [];
    }

    /**
     * Will give the max time given to submit an answer.
     */
    maxTimeForNextQuestion(difficulty) {
        return 15;
    }

    get stars() {
        if (this.correctCount / this.questionCount === 1) {
            return 3;
        } else if (this.correctCount / this.questionCount > 0.7) {
            return 2;
        } else {
            return 1;
        }
    }

    /**
     * Note: Dummy rules. Must implement correct rules
     */
    update(test) {
        // or score, time, difficulty
        this.questionCount += 1;
        let score = test.score;
        let time = test.time || this.timeBonusMaxTime;

        let newEvents = [];
        let newBonusPoints = 0;

        const difficulties = {
            low: 1,
            //medium: 2,
            hard: 2
        };

        const streakBonusMap = {
            3: 3,
            5: 5
        };

        if (score === 1) {
            newBonusPoints += 5; // Five point for answering correct
            if (time < this.timeBonusMaxTime) {
                newBonusPoints += Math.round((1 - time / this.timeBonusMaxTime) * 5); // Give a bonus between 0 and 5
                newBonusPoints = newBonusPoints * (difficulties[test.difficulty] || 1);
            }
            newEvents.push({
                // NOTE: This consists of base-bonus + time bonus
                type: 'timeBonus',
                points: newBonusPoints
            });

            this.streak += 1;

            if (this.streak === 3) {
                newBonusPoints += streakBonusMap[3];
                newEvents.push({
                    type: 'streakBonus',
                    points: streakBonusMap[3]
                });
            } else if (this.streak === 5) {
                newBonusPoints += streakBonusMap[5];
                newEvents.push({
                    type: 'streakBonus',
                    points: streakBonusMap[5]
                });
                this.streak = 0;
            }
        } else {
            this.streak = 0;
        }

        // newEvents.push({
        //   type: 'testEvent',
        //   points: 23
        // });
        // newEvents.push({
        //   type: 'testEvent',
        //   points: 12
        // });

        this.correctCount += test.score;
        this.bonusPoints += newBonusPoints;

        this.events.splice(this.events.length, 0, ...newEvents);
        return newEvents;
    }
}
