/* global PLATFORM */

import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { activationStrategy } from 'aurelia-router';
import { RedirectToRoute } from 'aurelia-router';

import { RouteTransitionHelper } from '../route-transition-helper';
import { DataService } from '../services/data-service';
import { DebugService } from '../services/debug-service';
import { Auth } from '../auth';

@inject(DataService, RouteTransitionHelper, EventAggregator, DebugService, Auth)
export class LearnView {
    activeListItemIndex = 0;
    activeListItem = null;

    isShowingFirstItem = false;
    isShowingLastItem = false;

    list = null;

    constructor(dataService, routeTransitionHelper, ea, debugService, auth) {
        this.dataService = dataService;
        this.routeTransitionHelper = routeTransitionHelper;
        this.ea = ea;
        this.debugService = debugService;
        this.auth = auth;
    }

    configureRouter(config, router) {
        config.map([
            {
                route: '',
                name: 'firstItem',
                nav: false,
                title: '',
                navigationStrategy: instruction => {
                    let listId = instruction.parentInstruction.params.listId;
                    return this.dataService
                        .ensureLanguageLoaded()
                        .then(() => this.dataService.getList(listId))
                        .then(list => {
                            instruction.config.redirect = list.items[0].startUrl();
                        });
                }
            },
            {
                route: 'word/:itemId',
                name: 'word',
                nav: false,
                title: 'Word',
                moduleId: PLATFORM.moduleName('./word')
            },
            {
                route: 'phrase/:itemId',
                name: 'phrase',
                nav: false,
                title: 'Phrase',
                moduleId: PLATFORM.moduleName('./phrase')
            },
            {
                route: 'pronunciation/:itemId',
                name: 'pronunciation',
                nav: false,
                title: 'Pronunciation',
                moduleId: PLATFORM.moduleName('./pronunciation')
            }
        ]);
        this.router = router;
    }

    determineActivationStrategy(params) {
        // We force route navigation to trigger activate() although the route fragment for this view has not changed.
        // Purpose: pull in the current cup model from the child route.
        return activationStrategy.invokeLifecycle;
    }

    canActivate(params, routeConfig, navigationInstruction) {
        let nextCupParams = navigationInstruction.plan.default.childNavigationInstruction.params;
        let itemId = nextCupParams.itemId;
        return this.dataService
            .ensureLanguageLoaded()
            .then(() => this.dataService.getList(params.listId))
            .then(list => {
                if (!list) {
                    return new RedirectToRoute('home');
                }
                return list.items.find(item => '' + item.id === itemId)
                    ? true
                    : new RedirectToRoute('memorize', { listId: params.listId });
            });
    }

    activate(params, routeConfig, navigationInstruction) {
        this.listId = params.listId;

        let nextCupParams = navigationInstruction.plan.default.childNavigationInstruction.params;
        let itemId = nextCupParams.itemId;

        return this.dataService
            .getList(this.listId)
            .then(list => list.ensureItemsLoaded())
            .then(list => list.loadAllRelatedWords())
            .then(list => {
                this.ea.publish('learn:started', {
                    collection: list
                });

                this.list = list;
                this.activeListItem = this.list.items.find(item => '' + item.id === itemId);
                this.activeListItem.markAsSeen();

                let index = (this.activeListItemIndex = this.list.items.indexOf(this.activeListItem));

                this.isShowingFirstItem = index === 0;
                this.isShowingLastItem = index === this.list.items.length - 1;

                if (!this.isShowingLastItem) {
                    this.nextItemTitle = this.list.items[index + 1].title;
                }

                this._registerDebugCommands();
            });
    }

    deactivate() {
        this.debugService.remove(this);
    }

    _registerDebugCommands() {
        if (this.list.id === 588) {
            // The flytoget onboarding list
            this.debugService.add(
                'Activate tooltip flow',
                () => {
                    this.dataService.memolanguageSettings.dbgClearTooltipData();
                    this.router.navigateToRoute(
                        this.activeListItem.type,
                        { itemId: this.activeListItem.id },
                        { replace: true }
                    );
                },
                this
            );
        }

        this.debugService.add(
            'markAsLearned',
            () => {
                this.activeListItem.markAsLearned();
            },
            this
        );
    }

    prev() {
        let item = this.list.items[this.activeListItemIndex - 1];
        if (item) {
            this.routeTransitionHelper.setTransitionHint({ direction: 'back' });
            return this.router.navigateToRoute(item.type, { itemId: item.id });
        }
    }

    next() {
        let item = this.list.items[this.activeListItemIndex + 1];
        if (item) {
            this.routeTransitionHelper.setTransitionHint({ direction: 'forward' });
            return this.router.navigateToRoute(item.type, { itemId: item.id });
        }
    }

    exit() {
        this.ea.publish('learn:exit', {
            collection: this.list
        });
        if (this.list.items.every(item => item.seen)) {
            this.list.markItemsAsSeen();
        }
        if (this.list.navigationOnExit) {
            return this.router.navigateToRoute(this.list.navigationOnExit.name, this.list.navigationOnExit.params);
        }
        return this.router.navigateToRoute('list', { listId: this.listId });
    }

    completeAndStartQuiz() {
        this.ea.publish('learn:completed', {
            collection: this.list
        });

        if (this.list.items.every(item => item.seen)) {
            this.list.markItemsAsSeen();
        }
        return this.router.navigateToRoute('quiz-loading', { listId: this.list.id });
    }
}
