import Hammer from 'hammerjs';

export function installTooltipsForOnboardingWord1() {
    this.fantasyIndex = 0;

    let tooltipFlowId = 'firstWordTooltip';

    this.tooltipService.add({
        selectorRoot: this.element,
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__factSection', '.memolanguage-Word__carousel'],
        actions: [
            {
                type: 'next',
                pulse: true,
                text: this.i18n.tr('onboardingTooltips.word1.tooltip1Action1'),
                icon: 'arrow-right',
                placement: {
                    selectorRoot: this.element,
                    target: '.memolanguage-Word__fantasySection',
                    targetCss: 'display: flex; justify-content: center; align-items: center; padding-bottom: 2em;'
                },
                onShow: () => {
                    this.element.querySelector('.memolanguage-Word__carousel').classList.add('tooltip-no-interaction');
                    this.element.querySelector('.memolanguage-Word__carousel').classList.add('push-right');
                },
                onHide: () => {
                    this.element
                        .querySelector('.memolanguage-Word__carousel')
                        .classList.remove('tooltip-no-interaction');
                    this.element.querySelector('.memolanguage-Word__carousel').classList.remove('push-right');
                }
            }
        ]
    });

    let hasVideo = this.filteredFantasies[0] && this.filteredFantasies[0].videoUrl;
    let actions = [];

    if (hasVideo) {
        actions.push({
            onShow: tooltipAction => {
                this.element.querySelector('.memolanguage-Word__carousel').classList.add('tooltip-no-interaction');

                let videoLayerElem = this.element.querySelector('.memolanguage-FantasyDisplay__videoLayer');
                videoLayerElem.classList.add('tooltip-allow-interaction');

                let videoElem = this.element.querySelector('.memolanguage-FantasyDisplay__video');
                let _onplaying = () => {
                    videoElem.removeEventListener('playing', _onplaying);
                    // NOTE: fading out tooltip before it naturally will go away
                    tooltipAction.tooltip.fadeOut();
                };
                videoElem.addEventListener('playing', _onplaying);
                let _onended = () => {
                    videoElem.removeEventListener('ended', _onended);
                    this.tooltipService.next();
                };
                videoElem.addEventListener('ended', _onended);

                let videoPlayButton = this.element.querySelector('.memolanguage-FantasyDisplay__videoPlayIcon');
                videoPlayButton.classList.add('tooltip-pulse');
            },
            onHide: () => {
                this.element.querySelector('.memolanguage-Word__carousel').classList.remove('tooltip-no-interaction');

                let videoPlayButton = this.element.querySelector('.memolanguage-FantasyDisplay__videoPlayIcon');
                videoPlayButton.classList.remove('tooltip-pulse');

                let videoLayerElem = this.element.querySelector('.memolanguage-FantasyDisplay__videoLayer');
                videoLayerElem.classList.remove('tooltip-allow-interaction');
            }
        });
    } else {
        actions.push({
            type: 'next',
            pulse: true,
            text: this.i18n.tr('onboardingTooltips.word1.tooltip2Action1'),
            icon: 'arrow-right',
            placement: {
                target: '.memolanguage-LearnView',
                css: 'position: absolute; bottom: 2em; right: 2em;'
            },
            onShow: () => {
                this.element.querySelector('.memolanguage-Word__carousel').classList.add('tooltip-no-interaction');
                // let videoPlayButton = this.element.querySelector('.memolanguage-FantasyDisplay__videoPlayButton');
                // if (videoPlayButton) {
                //   videoPlayButton.style.pointerEvents = 'auto';
                // }
            },
            onHide: () => {
                this.element.querySelector('.memolanguage-Word__carousel').classList.remove('tooltip-no-interaction');
                // let videoPlayButton = this.element.querySelector('.memolanguage-FantasyDisplay__videoPlayButton');
                // if (videoPlayButton) {
                //   videoPlayButton.style.pointerEvents = '';
                // }
            }
        });
    }

    this.tooltipService.add({
        tooltipDelay: 1000,
        selectorRoot: this.element,
        title: this.i18n.tr('onboardingTooltips.word1.tooltip2Title'),
        text: this.i18n.tr('onboardingTooltips.word1.tooltip2Text', { targetWord: this.word.factText }),
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__carousel'],
        actions: actions
    });

    this.tooltipService.add({
        tooltipDelay: hasVideo ? 2000 : 0,
        selectorRoot: this.element,
        title: this.i18n.tr('onboardingTooltips.word1.tooltip3Title'),
        text: this.i18n.tr('onboardingTooltips.word1.tooltip3Text'),
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__carousel', '.memolanguage-Word__carouselNav'],
        onShow: () => {
            let elem = this.element.querySelector('.memolanguage-FantasyDisplay');
            if (elem) {
                elem.classList.add('tooltip-semi-dimmed');
            }
        },
        onHide: () => {
            let elem = this.element.querySelector('.memolanguage-FantasyDisplay');
            if (elem) {
                elem.classList.remove('tooltip-semi-dimmed');
            }
        },
        actions: [
            {
                delay: hasVideo ? 2000 : 0,
                type: 'next',
                icon: 'swipe',
                iconSize: 'large',
                placement: {
                    selectorRoot: this.element,
                    target: '.memolanguage-Word__carousel',
                    targetCss: 'display: flex; justify-content: flex-end; align-items: center;',
                    css: 'margin-right: 0.5em; pointer-events: none;'
                },
                onShow: () => {
                    this.element
                        .querySelector('.memolanguage-Word__carousel .memolanguage-Carousel__itemsContainer')
                        .classList.add('tooltip-no-interaction'); // Disabling interaction for clicking in fantasy-cards
                    this.element
                        .querySelector('.memolanguage-Word__carouselNav')
                        .classList.add('tooltip-no-interaction');

                    this._tooltipFantasyIndexSubscription = this.bindingEngine
                        .propertyObserver(this, 'fantasyIndex')
                        .subscribe((newValue, oldValue) => {
                            this.tooltipService.next();
                        });

                    let carouselElem = this.element.querySelector('.memolanguage-Word__carousel');
                    carouselElem.insertAdjacentHTML(
                        'beforeend',
                        `
          <div class="memolanguage-Word__tooltipNextClickHelper"></div>
          `
                    );
                    let nextClickHelperElem = carouselElem.querySelector('.memolanguage-Word__tooltipNextClickHelper');

                    let hammer = new Hammer.Manager(nextClickHelperElem, {
                        recognizers: [[Hammer.Tap, { threshold: 4 }]]
                    });
                    hammer.on('tap', event => {
                        this.fantasyIndex += 1;
                    });
                },
                onHide: () => {
                    this.element
                        .querySelector('.memolanguage-Word__carousel .memolanguage-Carousel__itemsContainer')
                        .classList.remove('tooltip-no-interaction');
                    this.element
                        .querySelector('.memolanguage-Word__carouselNav')
                        .classList.remove('tooltip-no-interaction');

                    let nextClickHelperElem = this.element.querySelector('.memolanguage-Word__tooltipNextClickHelper');
                    if (nextClickHelperElem) {
                        nextClickHelperElem.parentNode.removeChild(nextClickHelperElem);
                    }

                    this._tooltipFantasyIndexSubscription.dispose();
                }
            }
        ]
    });

    this.tooltipService.add({
        selectorRoot: this.element,
        title: this.i18n.tr('onboardingTooltips.word1.tooltip4Title'),
        text: this.i18n.tr('onboardingTooltips.word1.tooltip4Text'),
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__carousel', '.memolanguage-Word__carouselNav'],
        actions: [
            {
                onShow: tooltipAction => {
                    let hearts = this.element.querySelectorAll('.memolanguage-FantasyDisplay__likeButtonIcon');
                    Array.from(hearts).forEach(elem => elem.classList.add('tooltip-pulse'));

                    this._tooltipLikeSubscription = this.bindingEngine
                        .propertyObserver(this.word, 'currentFantasyId')
                        .subscribe((newValue, oldValue) => {
                            tooltipAction.tooltip.fadeOut();
                            this.tooltipService.next();
                        });
                    // this._tooltipLikeSubscriptions = this.filteredFantasies.map(fantasy => {
                    //   return this.bindingEngine.propertyObserver(fantasy, 'hasLike')
                    //   .subscribe((newValue, oldValue) => {
                    //     this.tooltipService.next();
                    //   });
                    // });
                },
                onHide: () => {
                    let hearts = this.element.querySelectorAll('.memolanguage-FantasyDisplay__likeButtonIcon');
                    Array.from(hearts).forEach(elem => elem.classList.remove('tooltip-pulse'));

                    this._tooltipLikeSubscription.dispose();
                    // this._tooltipLikeSubscriptions.forEach(subscription => subscription.dispose());
                    this.dataService.memolanguageSettings.markTooltipAsSeen(tooltipFlowId);
                }
            },
            {
                type: 'next',
                pulse: true,
                text: this.i18n.tr('onboardingTooltips.word1.tooltip4Action2'),
                icon: 'arrow-right',
                placement: {
                    target: '.memolanguage-LearnView',
                    css: 'position: absolute; bottom: 2em; right: 2em;'
                }
            }
        ]
    });
}

export function installTooltipsForOnboardingWord2() {
    this.tooltipService.add({
        selectorRoot: this.element,
        focusDelay: 2000,
        tooltipDelay: 1000,
        title: this.i18n.tr('onboardingTooltips.word2.tooltip1Title'),
        text: this.i18n.tr('onboardingTooltips.word2.tooltip1Text'),
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__carousel', '.memolanguage-Word__carouselNav'],
        actions: [
            {
                type: 'next',
                pulse: true,
                text: this.i18n.tr('onboardingTooltips.word2.tooltip1Action1'),
                icon: 'arrow-right',
                placement: {
                    target: '.memolanguage-LearnView',
                    css: 'position: absolute; bottom: 2em; right: 2em;'
                },
                onHide: () => {
                    this.dataService.memolanguageSettings.markTooltipAsSeen('secondWordTooltip');
                }
            }
        ]
    });
}

export function installTooltipsForOnboardingWord3() {
    this.tooltipService.add({
        selectorRoot: this.element,
        focusDelay: 2000,
        tooltipDelay: 1000,
        title: this.i18n.tr('onboardingTooltips.word3.tooltip1Title'),
        text: this.i18n.tr('onboardingTooltips.word3.tooltip1Text'),
        target: '.memolanguage-Word__carousel',
        ignoreDimming: ['.memolanguage-Word__carousel', '.memolanguage-Word__carouselNav'],
        actions: [
            {
                type: 'next',
                pulse: true,
                text: this.i18n.tr('onboardingTooltips.word3.tooltip1Action1'),
                icon: 'arrow-right',
                placement: {
                    target: '.memolanguage-LearnView',
                    css: 'position: absolute; bottom: 2em; right: 2em;'
                },
                onHide: () => {
                    this.dataService.memolanguageSettings.markTooltipAsSeen('thirdWordTooltip');
                }
            }
        ]
    });
}
