import { inject, BindingEngine } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { DataService } from '../services/data-service';
import { DebugService } from '../services/debug-service';
import { Auth } from '../auth';

@inject(DataService, Router, BindingEngine, I18N, DebugService, Auth)
export class OnboardingView {
    activeSlideIndex = 0;
    playingVideoElem = null;

    constructor(dataService, router, bindingEngine, i18n, debugService, auth) {
        this.dataService = dataService;
        this.router = router;
        this.bindingEngine = bindingEngine;
        this.i18n = i18n;
        this.debugService = debugService;
        this.auth = auth;

        this.listId = this.auth.hasRole('flytoget') ? 588 : 750;
    }

    activate(params) {
        this._registerDebugCommands();

        this._activeSlideIndexSubscription = this.bindingEngine
            .propertyObserver(this, 'activeSlideIndex')
            .subscribe((newFocusedMnemonicIndex, oldFocusedMnemonicIndex) => {
                this._pauseVideo();
            });

        return this.dataService.getList(this.listId).then(list => {
            this.list = list;
        });
    }

    deactivate() {
        this.debugService.remove(this);
        this._activeSlideIndexSubscription.dispose();
        this._pauseVideo();
    }

    _registerDebugCommands() {
        this.debugService.add(
            'skip onboarding',
            async() => {
                await this.dataService.memolanguageSettings.markOnboardingAsSeen();
                this.router.navigateToRoute('home');
            },
            this
        );
    }

    playVideo(event) {
        let videoElem = event.target;
        if (videoElem.paused) {
            videoElem.parentElement.classList.add('is-playing');
            videoElem.play();
            videoElem.onended = () => {
                this.playingVideoElem.parentElement.classList.remove('is-playing');
                this.playingVideoElem = null;
            };
            this.playingVideoElem = videoElem;
        } else {
            this._pauseVideo();
        }
    }

    _playVideo(videoElem) {
        //
    }
    _pauseVideo(videoElem) {
        if (this.playingVideoElem) {
            this.playingVideoElem.parentElement.classList.remove('is-playing');
            this.playingVideoElem.pause();
            this.playingVideoElem = null;
        }
    }

    next() {
        if (this.activeSlideIndex < 2) {
            this.activeSlideIndex += 1;
        } else {
            return this.list.ensureItemsLoaded().then(() => {
                this.list.onboarding = true;
                this.dataService.memolanguageSettings.markOnboardingAsSeen();
                return this.router.navigateToRoute('memorize', { listId: this.list.id });
            });
        }
    }
}
