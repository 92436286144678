/* global PLATFORM */

export class QuizItemSession {
    commited = false;
    vm = null;
    convertable = true;

    timeUsedOnQuestion = 0;
    maxTimeForQuestion = 0;
    timeout = false;

    _clockIntervalId = null;

    constructor(quizSession, model, ea) {
        this.quizSession = quizSession;
        this.model = JSON.parse(JSON.stringify(model));
        this.ea = ea;
        this.convertable = this.model.alternative ? true : false;

        let skippableMap = { phrase: true, choice: true };
        if (!this.convertable && skippableMap[this.model.type]) {
            this.skippable = true; // NOTE: a bit hackish
        }

        let moduleId =
            {
                listen: PLATFORM.moduleName('./views/listening-test'),
                writing: PLATFORM.moduleName('./views/writing-test'),
                phrase: PLATFORM.moduleName('./views/phrase-test'),
                choice: PLATFORM.moduleName('./views/multiple-choice-test')
            }[this.model.type] || './views/multiple-choice-test';

        this.viewModelId = '.' + moduleId;

        this._donePromise = new Promise(resolve => {
            this._resolveDonePromise = resolve;
        });
    }

    on(eventType, callback) {
        this['_' + eventType + 'Callback'] = callback;
    }

    start(vm) {
        this.vm = vm;
        this.questionStartTime = Date.now();

        this.ea.publish('quiz:question', {
            collection: { id: this.quizSession.extraData.collectionId, name: this.quizSession.extraData.collectionName }
        });

        this.maxTimeForQuestion = this.quizSession.scoreEngine.maxTimeForNextQuestion(this.model.difficulty);

        this._startTimer();
    }

    abort() {
        this.ea.publish('quiz:aborted', {
            collection: { id: this.quizSession.extraData.collectionId, name: this.quizSession.extraData.collectionName }
        });
        this._stopTimer();
    }

    convert() {
        if (this.convertable) {
            this.abort();
            this.quizSession.next({ convert: true });
        }
    }

    skip() {
        if (this.skippable) {
            this.commit();
        }
    }

    waitDone() {
        return this._donePromise;
    }

    dbgToggleTimer() {
        if (this._clockIntervalId) {
            this._stopTimer();
        } else {
            this._startTimer();
        }
    }

    _startTimer() {
        this._clockIntervalId = setInterval(() => {
            this.timeUsedOnQuestion += 1;
            if (this.maxTimeForQuestion === this.timeUsedOnQuestion) {
                this.onTimeout();
            }
        }, 1000);
    }

    _stopTimer() {
        clearInterval(this._clockIntervalId);
        this._clockIntervalId = null;
    }

    commit() {
        this.commited = true;
        this._stopTimer();

        if (this.timeout) {
            this.model.time = 0;
            this.model.score = 0; // need here?
        } else {
            this.model.time = Date.now() - this.questionStartTime;
        }
        this.time = this.model.time;
        this.score = this.model.score;

        this.model.scoreEvents = this.quizSession.calculate(this.model);

        this.quizSession.completedQuizItemSessions.push(this);

        this.vm.commit().then(() => {
            this._resolveDonePromise();
        });

        if (this._commitCallback) {
            this._commitCallback();
        }

        this.ea.publish('quiz:answer-submitted', {
            collection: {
                id: this.quizSession.extraData.collectionId,
                name: this.quizSession.extraData.collectionName
            },
            timeUsed: this.timeUsedOnQuestion,
            maxTime: this.maxTimeForQuestion,
            score: this.score
        });
    }

    onTimeout() {
        this.timeout = true;
        this.commit();

        this.ea.publish('quiz:answer-timeout', {
            collection: {
                id: this.quizSession.extraData.collectionId,
                name: this.quizSession.extraData.collectionName
            },
            timeUsed: this.timeUsedOnQuestion,
            maxTime: this.maxTimeForQuestion
        });
    }
}
