import { inject } from 'aurelia-framework';
import { Container } from 'aurelia-dependency-injection';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';

import { Auth } from '../auth';
import { getLogger } from 'aurelia-logging';

import { parseCategory, parseTempList } from '../parsers';

import { RepetitionService } from '../services/repetition-service';

import { AppPackage } from '../models/app-package';
import { MemolanguageSettings } from '../models/memolanguage';
import Storage from '../storage';
import store from '@/store';

const logger = getLogger('data-service');

@inject(Auth, Container, I18N, EventAggregator, RepetitionService, MemolanguageSettings)
export class DataService {
    _instances = new Map();
    unlockedPhraseCount = 0;
    bundlePromises = {};

    _unlockedPhraseIds = new Set();
    _newlyUnlockedPhraseIds = new Set();
    _unlockedPhraseIdsSnapshot = new Set();

    _itemsLoadQueue = new Set();
    _loadItemContentTimeoutId = null;

    _newLearnedItemIds = [];
    _reportLearnedItemsTimeoutId = null;
    _makeCacheFriendlyRequests = true;

    constructor(auth, container, i18n, ea, repetitionService, memolanguageSettings) {
        this.auth = auth;
        this.container = container;
        this.i18n = i18n;
        this.ea = ea;
        this.memolanguageSettings = memolanguageSettings;

        this.services = {
            dataService: this,
            repetitionService: repetitionService,
            auth: this.auth,
            i18n: this.i18n
        };
    }

    get(id) {
        if (typeof id === 'number') {
            id = `${id}`;
        }
        let instance = this._instances.get(id);
        if (instance) {
            return Promise.resolve(instance);
        } else {
            logger.error(`Could not get ${id}`);
            return Promise.resolve(null);
        }
    }

    getSync(id) {
        if (typeof id === 'number') {
            id = `${id}`;
        }
        return this._instances.get(id);
    }

    getCategory(id) {
        return this.get('c-' + id).then(category => {
            if (category && category.model === 'CategoryModel') {
                return category;
            } else {
                return null;
            }
        });
    }

    isList(id) {
        let list = this._instances.get(`c-${id}`);
        return list ? list.model === 'ListModel' : false;
    }

    getList(id) {
        return this.get('c-' + id).then(list => {
            if (list && (list.model === 'ListModel' || list.model === 'TempListModel')) {
                return list;
            } else {
                return null;
            }
        });
    }

    _fetchPronunciationItemsContent(items) {
        // IDEA: If getting more items than asked for, they could be cached?

        let lists = Array.from(new Set(items.map(x => x.lists[0])));
        return store.dispatch('moduleMemolanguage/fetchPronunciationItemsContent', {
            sourceLanguage: this.activeSourceLanguage,
            targetLanguage: this.activeTargetLanguage,
            listIds: lists.map(list => list.id),
            itemIds: items.map(item => item.id)
        });
    }

    _fetchOtherItemsContent(items) {
        let itemIds = items.map(item => item.id);
        let queryItemIds = itemIds;
        if (this._makeCacheFriendlyRequests) {
            let countMap = new Map();
            items.forEach(item => {
                item.lists.forEach(list => {
                    if (countMap.has(list)) {
                        countMap.set(list, countMap.get(list) + 1);
                    } else {
                        countMap.set(list, 1);
                    }
                });
            });
            let supersetList = null;
            countMap.forEach((count, list) => {
                if (count === items.length) {
                    supersetList = list;
                }
            });
            if (supersetList) {
                queryItemIds = supersetList.items.map(item => item.id);
            }
        }
        queryItemIds.sort();

        return store.dispatch('moduleMemolanguage/fetchOtherItemsContent', {
            sourceLanguage: this.activeSourceLanguage,
            targetLanguage: this.activeTargetLanguage,
            queryItemIds,
            itemIds
        });
    }

    loadItemContent(item) {
        this._itemsLoadQueue.add(item);

        clearTimeout(this._loadItemContentTimeoutId);
        this._loadItemContentTimeoutId = setTimeout(() => {
            let items = Array.from(this._itemsLoadQueue);
            let pronunciationItems = items.filter(x => x.type === 'pronunciation');
            let otherItems = items.filter(x => x.type !== 'pronunciation');
            this._itemsLoadQueue.clear();

            if (pronunciationItems.length) {
                this._fetchPronunciationItemsContent(pronunciationItems).then(fixtures => {
                    pronunciationItems.forEach(item_ => {
                        let itemFixture = fixtures.find(fixture => fixture.id === item_.id);
                        item_.setContent(itemFixture);
                    });
                });
            }
            if (otherItems.length) {
                this._fetchOtherItemsContent(otherItems).then(fixtures => {
                    otherItems.forEach(item_ => {
                        let itemFixture = fixtures.find(fixture => fixture.id === item_.id);
                        item_.setContent(itemFixture);
                    });
                });
            }
        }, 1);
    }

    getUnlockedPhrases(limit) {
        let ids = limit ? Array.from(this._unlockedPhraseIds).slice(0, limit) : Array.from(this._unlockedPhraseIds);
        return Promise.all(ids.map(itemId => this.getSync(itemId).ensureLoaded()));
    }

    getNewlyUnlockedPhrases(limit, clear = true) {
        let ids = limit
            ? Array.from(this._newlyUnlockedPhraseIds).slice(0, limit)
            : Array.from(this._newlyUnlockedPhraseIds);
        if (clear) {
            this._unlockedPhraseIdsSnapshot = new Set(this._unlockedPhraseIds);
            this._newlyUnlockedPhraseIds = new Set();
        }
        return Promise.all(ids.map(itemId => this.getSync(itemId).ensureLoaded()));
    }

    reportLearnedItems(itemIds) {
        if (Storage.getItem('memolanguage-dbgDisableUnlockedPhrases')) {
            return;
        }
        this._newLearnedItemIds = this._newLearnedItemIds.concat(itemIds);
        clearTimeout(this._reportLearnedItemsTimeoutId);

        this._reportLearnedItemsTimeoutId = setTimeout(() => {
            let ids = Array.from(new Set(this._newLearnedItemIds));
            this._newLearnedItemIds = [];

            store.dispatch('moduleMemolanguage/reportLearnedItems', ids).then(() => {
                this._pollUnlockedPhrases();
            });
        }, 100);
    }

    _pollUnlockedPhrases() {
        return store
            .dispatch('moduleMemolanguage/getUnlockedPhrases', { targetLanguage: this.activeTargetLanguage })
            .then(phrases => {
                let baseIds = this._unlockedPhraseIdsSnapshot; //new Set(this._unlockedPhraseIdsSnapshot);
                let currentIds = new Set(phrases.map(ph => ph.id));
                let newIds = new Set(Array.from(currentIds).filter(id => !baseIds.has(id)));
                let previousIds = new Set(Array.from(currentIds).filter(id => !newIds.has(id)));

                this._newlyUnlockedPhraseIds = newIds;
                this._unlockedPhraseIds = new Set(Array.from(newIds).concat(Array.from(previousIds)));
                this.unlockedPhraseCount = this._unlockedPhraseIds.size;
            });
    }

    createTempList(tempId, options) {
        let fixture = {
            id: tempId,
            model: 'TempListModel',
            sourceLanguage: this.activeSourceLanguage,
            targetLanguage: this.activeTargetLanguage,
            image: options.image,
            name: options.name,
            itemIds: options.items.map(item => item.id),
            navigationAfterMemorize: {
                name: 'quiz',
                params: { listId: tempId }
            },
            navigationAfterQuiz: options.source,
            navigationOnExit: options.source,
            extraIdentifier: options.extraIdentifier,
            requestUpgradeOnCompletion: options.requestUpgradeOnCompletion
        };

        Storage.setItem('tempList', JSON.stringify(fixture));
        let inst = parseTempList(fixture, this.services, this._instances);
        return Promise.resolve(inst);
    }

    activateLanguage({ sourceLanguage, targetLanguage }) {
        this.activeSourceLanguage = sourceLanguage;
        this.activeTargetLanguage = targetLanguage;
        return this.languageInstall(targetLanguage);
    }

    ensureLanguageLoaded() {
        return this.readyPromise;
    }

    languageInstall(targetLanguage) {
        if (this.readyPromise) {
            return this.readyPromise;
        }
        let sourceLanguage = this.activeSourceLanguage;

        let bundle = {
            installMethod: 'languageInstall',
            appPackage: {
                id: `appPackage/language-${targetLanguage}`,
                model: 'AppPackage',
                settings: {
                    fromLanguage: sourceLanguage,
                    toLanguage: targetLanguage
                }
            }
        };
        this.activeSourceLanguage = bundle.appPackage.settings.fromLanguage;
        this.activeTargetLanguage = bundle.appPackage.settings.toLanguage;
        this.activePackageName = `language-${this.activeTargetLanguage}`;

        this.readyPromise = store
            .dispatch('moduleMemolanguage/loadLanguage', {
                sourceLanguage: this.activeSourceLanguage,
                targetLanguage: this.activeTargetLanguage
            })
            .then(categories => {
                let fixtures = [];
                categories.forEach(category =>
                    parseCategory(
                        category,
                        null,
                        fixtures,
                        0,
                        this.activeSourceLanguage,
                        this.activeTargetLanguage,
                        this.services,
                        this._instances
                    )
                );

                this.unwatchItemsUserstate = store.watch(
                    state => state.moduleMemolanguage.languages[this.activeTargetLanguage].itemsUserState,
                    userData => {
                        // NOTE: Not watching deep. So will only react to MEMOLANGUAGE_SET_ITEMS_FANTASIES
                        if (userData) {
                            Object.keys(userData).forEach(key => {
                                let inst = this.getSync(key);
                                if (inst) {
                                    let data = userData[key];
                                    if (data.learnStatus) {
                                        inst.markAsLearnedNoPersist();
                                    }
                                }
                            });
                        }
                    },
                    { immediate: true }
                );

                // NOTE: We might have cases where a list has a repetition, while not all items are marked as learned. In that case we mark them as learned here. But without persisting.
                // NOTE: We only update once (on initial load), because later events will almost certenly be in sync with individual learn-item learnStatus (from pouch).
                this.services.repetitionService.ensureInitialLoad().then(() => {
                    this.services.repetitionService
                        .getRepetitions(this.activePackageName, listId => this.isList(listId))
                        .forEach(rep => {
                            if (rep.level !== -1) {
                                let list = this.getSync('c-' + rep.list_id);
                                if (list) {
                                    list.items.forEach(item => {
                                        item.markAsLearnedNoPersist(true);
                                    });
                                }
                            }
                        });
                });

                bundle.appPackage.collections = categories.map(c => c.id);
                if (Storage.getItem('tempList')) {
                    let templistFixture = JSON.parse(Storage.getItem('tempList'));
                    if (
                        templistFixture.model === 'TempListModel' &&
                        templistFixture.sourceLanguage === this.activeSourceLanguage &&
                        templistFixture.targetLanguage === this.activeTargetLanguage
                    ) {
                        parseTempList(templistFixture, this.services, this._instances);
                    }
                }

                let inst = new AppPackage(this.services, bundle.appPackage);
                this._instances.set(inst.id, inst);
                this._updateAccess();

                let _onTokenChanged = () => {
                    this._updateAccess();
                };
                this._subscriptions = [this.ea.subscribe('auth:profileChanged', payload => _onTokenChanged())];
            })
            .then(() => {
                if (!Storage.getItem('memolanguage-dbgDisableUnlockedPhrases')) {
                    this._pollUnlockedPhrases();
                }
            });

        return this.readyPromise;
    }

    unloadActiveBundle() {
        store.dispatch('moduleMemolanguage/unloadLanguage', {
            sourceLanguage: this.activeSourceLanguage,
            targetLanguage: this.activeTargetLanguage
        });

        this._instances.forEach(instance => {
            if (instance.unload) {
                instance.unload();
            }
        });

        if (this._subscriptions) {
            this._subscriptions.forEach(s => s.dispose());
        }
        if (this.unwatchItemsUserstate) {
            this.unwatchItemsUserstate();
        }

        this.activeSourceLanguage = null;
        this.activeTargetLanguage = null;
        this.activePackageName = null;
        this._instances.clear();
        this.readyPromise = null;

        this._unlockedPhraseIds = [];
        this.unlockedPhraseCount = 0;

        Storage.removeItem('tempList');
    }

    _updateAccess() {
        // accessLevel // 1 2 3
        let accessLevel = 0;
        if (this.auth.isAuthenticated()) {
            let roles = this.auth.profile.roles;
            if (roles.indexOf('flytoget') !== -1 && roles.indexOf('tempuser') !== -1) {
                accessLevel = 1;
            } else if (this.auth.isSubscribed()) {
                accessLevel = 3;
            } else if (roles.indexOf('tempuser') === -1) {
                accessLevel = 2;
            }
        }

        let appPackage = this.getSync(`appPackage/language-${this.activeTargetLanguage}`);

        let _processCategory = category => {
            category.children.forEach(child => {
                if (child.children && child.children.length) {
                    _processCategory(child);
                } else {
                    _processList(child);
                }
            });
            category.locked = !category.children.some(ch => !ch.locked);
        };

        let _processList = list => {
            list.locked = !(list.access <= accessLevel);
            // if tempUser
            // if signupUser
            // if checkoutUser
        };

        let categories = appPackage.collections.map(categoryId => this.getSync('c-' + categoryId));
        categories.forEach(category => {
            _processCategory(category);
        });
    }
}
