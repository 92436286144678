import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DataService } from '../services/data-service';
import { TimelineMax, Power1 } from 'gsap';

@inject(DataService, Router)
export class ActivityLoadingView {
    list = null;

    constructor(dataService, router) {
        this.dataService = dataService;
        this.router = router;
    }

    activate(params, routeConfig) {
        this.next = routeConfig.settings.next;
        return this.dataService.ensureLanguageLoaded().then(() => {
            return this.dataService.getList(params.listId).then(list => {
                this.list = list;
            });
        });
    }

    attached() {
        let tl = (this.tl = new TimelineMax({
            onComplete: () => {
                this.router.navigateToRoute(this.next, { listId: this.list.id });
            }
        }));

        let elementsToStagger = Array.from(this.element.querySelectorAll('.stagger'));
        elementsToStagger.reverse();
        let distance = elementsToStagger[0].offsetTop + elementsToStagger[0].clientHeight;

        // tl.staggerFrom(elementsToStagger, 0.5, {y: `-=${distance}`, ease: Power1.easeOut}, 0.1);
        tl.from(elementsToStagger, 0.5, { y: `-=${distance}`, ease: Power1.easeOut }, 0);

        // elementsToStagger.reverse();
        // tl.staggerTo(elementsToStagger, 0.3, {y: `-=${distance}`, ease: Power1.easeIn}, 0.1, '+=2');
        // tl.set(elementsToStagger, {opacity: 0});

        // elementsToStagger.reverse();
        tl.staggerTo(elementsToStagger, 0.3, { y: '+=100', opacity: 0, ease: Power1.easeIn }, 0.1, '+=1');
        // tl.set(elementsToStagger, {opacity: 0});
    }

    detached() {
        this.tl.kill();
    }
}
