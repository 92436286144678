export class ThemeController {
    themeStack = [];
    theme = '';

    constructor() {}

    set(theme) {
        // document.body.classList.remove('memolanguage-theme-' + this.theme);
        this.theme = theme;
        // document.body.classList.add('memolanguage-theme-' + this.theme);
    }

    push() {
        this.themeStack.push(this.theme);
    }

    pop() {
        this.setTheme(this.themeStack.pop());
    }
}
